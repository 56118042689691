import { useCallback, useContext, useState } from "react";
import { getAssignmentIds, getChannel, SendbirdContext } from "../../vendor/sendbird";
import Confirmation, { ConfirmationContext } from "../shared/Confirmation";
import "./MessageBroadcastModal.css";
import { Athlete, useCoachData } from "../../data/coach";
import { athleteName } from "../sessions/CoachSessions";
import { GroupChannel } from "@sendbird/chat/groupChannel";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import sendbirdSelectors from "@sendbird/uikit-react/sendbirdSelectors";
import { useSendbirdStateContext } from "@sendbird/uikit-react";

type AssignmentChannels = {
    athlete: Athlete;
    athleteChannel?: GroupChannel;
    parentChannels: GroupChannel[];
    groupChannels: GroupChannel[];
};

function getAllChannels(channels: AssignmentChannels): GroupChannel[] {
    return (channels.athleteChannel ? [channels.athleteChannel] : [])
        .concat(channels.parentChannels)
        .concat(channels.groupChannels);
}

function getAssignmentChannels(
    allAthletes: Athlete[],
    allChannels: GroupChannel[],
): AssignmentChannels[] {
    return allAthletes.map(athlete => {
        const channels = allChannels.filter(channel =>
            getAssignmentIds(channel).includes(athlete.assignmentId),
        );
        return {
            athlete,
            athleteChannel: channels.find(
                channel =>
                    channel.members.length === 2 &&
                    channel.members.map(member => member.userId).includes(athlete.user?.id ?? ""),
            ),
            parentChannels: channels.filter(
                channel =>
                    !channel.members.map(member => member.userId).includes(athlete.user?.id ?? ""),
            ),
            groupChannels: channels.filter(
                channel =>
                    channel.members.length > 2 &&
                    channel.members.map(member => member.userId).includes(athlete.user?.id ?? ""),
            ),
        };
    });
}

function ChannelSelector({
    userId,
    channel,
    selectedChannelUrls,
    selectChannel,
}: {
    userId: string;
    channel: GroupChannel;
    selectedChannelUrls: string[];
    selectChannel: (url: string, selected: boolean) => void;
}) {
    return (
        <li>
            <input
                type="checkbox"
                checked={selectedChannelUrls.includes(channel.url)}
                onChange={e => selectChannel(channel.url, e.target.checked)}
            />
            <label>
                {channel.members
                    .filter(member => member.userId !== userId)
                    .map(member => member.nickname)
                    .join(", ")}
            </label>
        </li>
    );
}

function AssignmentChannelsRow({
    userId,
    selectedChannelUrls,
    assignmentChannels,
    selectChannelUrl,
}: {
    userId: string;
    selectedChannelUrls: string[];
    assignmentChannels: AssignmentChannels;
    selectChannelUrl: (url: string, selected: boolean) => void;
}) {
    const name = athleteName(assignmentChannels.athlete);
    return (
        <li>
            <div className="broadcast-assignment-header">
                <label>
                    {name.firstName} {name.lastName}
                </label>
            </div>
            <div className="broadcast-channels-list-container">
                <div className="broadcast-channels-list">
                    <h4>Individual Chats</h4>
                    <ul>
                        {assignmentChannels.athleteChannel && (
                            <ChannelSelector
                                key={assignmentChannels.athleteChannel.url}
                                userId={userId}
                                channel={assignmentChannels.athleteChannel}
                                selectedChannelUrls={selectedChannelUrls}
                                selectChannel={selectChannelUrl}
                            />
                        )}
                        {assignmentChannels.parentChannels.map(parentChannel => (
                            <ChannelSelector
                                key={parentChannel.url}
                                userId={userId}
                                channel={parentChannel}
                                selectedChannelUrls={selectedChannelUrls}
                                selectChannel={selectChannelUrl}
                            />
                        ))}
                    </ul>
                </div>
                <div className="broadcast-channels-list">
                    <h4>Group Chats</h4>
                    <ul>
                        {assignmentChannels.groupChannels.map(groupChannel => (
                            <ChannelSelector
                                key={groupChannel.url}
                                userId={userId}
                                channel={groupChannel}
                                selectedChannelUrls={selectedChannelUrls}
                                selectChannel={selectChannelUrl}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </li>
    );
}

function BroadcastInner({
    userId,
    allChannels,
    onSuccess,
}: {
    userId: string;
    allChannels: AssignmentChannels[];
    onSuccess: () => void;
}) {
    const [text, setText] = useState("");
    const [selectedChannelUrls, setSelectedChannelUrls] = useState<string[]>([]);
    const [, setIsVisible] = useContext(ConfirmationContext);
    const [isSending, setIsSending] = useState(false);
    const [sentCount, setSentCount] = useState(0);
    const sendbirdStateContext = useSendbirdStateContext();
    function setSelected(channelUrls: string[], selected: boolean) {
        const set = new Set(selectedChannelUrls);
        channelUrls.forEach(channelUrl => {
            if (selected) {
                set.add(channelUrl);
            } else {
                set.delete(channelUrl);
            }
        });
        setSelectedChannelUrls(Array.from(set));
    }
    function selectAll() {
        setSelectedChannelUrls(
            allChannels.flatMap(channels => getAllChannels(channels)).map(channel => channel.url),
        );
    }
    function selectAthletes() {
        setSelected(
            allChannels
                .map(channel => channel.athleteChannel?.url)
                .filter(url => !!url) as string[],
            true,
        );
    }
    function selectParents() {
        setSelected(
            allChannels.flatMap(channels => channels.parentChannels).map(channel => channel.url),
            true,
        );
    }
    const submit = useCallback(async () => {
        setSentCount(0);
        setIsSending(true);
        const send = sendbirdSelectors.getSendUserMessage(sendbirdStateContext);
        for (const channelUrl of selectedChannelUrls) {
            const channel = await getChannel(channelUrl);
            await send(channel, { message: text });
            setSentCount(count => count + 1);
            await new Promise(r => setTimeout(r, 500));
        }
        setIsSending(false);
        onSuccess();
    }, [selectedChannelUrls, sendbirdStateContext, onSuccess, text, setSentCount]);
    const sendButtonText = isSending
        ? `Sending... ${sentCount} / ${selectedChannelUrls.length}`
        : selectedChannelUrls.length
        ? `Send to ${selectedChannelUrls.length} chats`
        : "Send";
    return (
        <Confirmation title="Broadcast Message">
            <Confirmation.Body>
                <textarea className="broadcast-textarea" onChange={e => setText(e.target.value)} />
                <br />
                <button onClick={() => selectAll()}>Select all</button>
                <button onClick={() => selectAthletes()}>Select athletes</button>
                <button onClick={() => selectParents()}>Select parents</button>
                <button onClick={() => setSelectedChannelUrls([])}>Deselect all</button>
                <ul className="broadcast-assignments-list">
                    {allChannels.map(channel => (
                        <AssignmentChannelsRow
                            key={channel.athlete.assignmentId}
                            userId={userId}
                            selectedChannelUrls={selectedChannelUrls}
                            assignmentChannels={channel}
                            selectChannelUrl={(url, selected) => setSelected([url], selected)}
                        />
                    ))}
                </ul>
            </Confirmation.Body>
            <Confirmation.Button label="Cancel" onClick={() => setIsVisible(false)} />
            <Confirmation.Button
                isPrimary
                disabled={!text.length || !selectedChannelUrls.length || isSending}
                label={sendButtonText}
                onClick={() => submit()}
            />
        </Confirmation>
    );
}

function BroadcastSuccess({ onDone }: { onDone: () => void }) {
    return (
        <Confirmation title="Success!" onDismiss={onDone}>
            <Confirmation.Body>
                <div className="share-success">
                    <img src="/checkmark-circle.svg" alt="checkmark" />
                    <p>You successfully sent the broadcast message to your client(s).</p>
                </div>
            </Confirmation.Body>
            <Confirmation.Button label="Done" onClick={() => onDone()} isPrimary />
        </Confirmation>
    );
}

export function MessageBroadcastModal({ coachUserId }: { coachUserId: string }) {
    const coachData = useCoachData(coachUserId);
    const [sendbirdState] = useContext(SendbirdContext);
    const [success, setSuccess] = useState(false);
    const [, setIsVisible] = useContext(ConfirmationContext);
    const channels = getAssignmentChannels(coachData.athletes, sendbirdState.channels);
    if (!sendbirdState.userId) {
        return <></>;
    }
    return (
        <SendbirdProvider
            userId={sendbirdState.userId}
            appId={process.env.REACT_APP_SENDBIRD_APP_ID!}
            colorSet={undefined}
            accessToken={sendbirdState.sessionToken}
        >
            {success ? (
                <BroadcastSuccess
                    onDone={() => {
                        setSuccess(false);
                        setIsVisible(false);
                    }}
                />
            ) : (
                <BroadcastInner
                    userId={sendbirdState.userId}
                    allChannels={channels}
                    onSuccess={() => setSuccess(true)}
                />
            )}
        </SendbirdProvider>
    );
}
