import { AthleteDetails } from "../../../data/user";
import { formatEnumValues } from "../../../util";
import { Avatar } from "../../shared/Avatar";
import { mixpanelProfileUrl } from "../../../vendor/mixpanel";
import React, { useContext } from "react";
import { Spinner } from "../../shared/Spinner";
import "./AdminAthleteDetails.css";
import "../Admin.css";
import { AdminAthleteContext } from "data/admin-athlete";

function AdminAthleteDetailsSummary({ athlete }: { athlete: AthleteDetails }) {
    return (
        <div
            className="admin-athlete-details-summary d-flex flex-row"
            style={{ alignItems: "start" }}
        >
            <div className="admin-athlete-details-summary-content" style={{ width: "50%" }}>
                <div>
                    <h4>Hometown</h4>
                    <p>
                        {athlete.hometown ? `${athlete.hometown}, ` : "N/A"}
                        {athlete.state}
                    </p>
                </div>
                <div>
                    <h4>School</h4>
                    <p>
                        {athlete.school} {athlete.schoolLevel ? `(${athlete.schoolLevel})` : "N/A"}
                    </p>
                </div>
                <div>
                    <h4>Sports</h4>
                    <p>{formatEnumValues(athlete.sports)}</p>
                </div>
                <div>
                    <h4>Date of Birth</h4>
                    <p>{athlete.dateOfBirth.toLocaleDateString()}</p>
                </div>
            </div>
            <div className="admin-athlete-details-summary-content" style={{ width: "50%" }}>
                <div>
                    <h4>Coaching Interests</h4>
                    <p>{formatEnumValues(athlete.coachingInterests)}</p>
                </div>
                <div>
                    <h4>Goals</h4>
                    <p>{formatEnumValues(athlete.goals)}</p>
                </div>
                <div>
                    <h4>IMGA Student</h4>
                    <p>{athlete.imgAcademyStudent ? "Yes" : "No"}</p>
                </div>
                <div>
                    <h4>IMGA Camp</h4>
                    <p>{athlete.imgAcademyCampAttendee ? "Yes" : "No"}</p>
                </div>
            </div>
        </div>
    );
}

export function AdminAthleteDetailsHeader() {
    const [adminAthleteState] = useContext(AdminAthleteContext);

    function athleteName() {
        return (
            <>
                {adminAthleteState.user?.firstName} {adminAthleteState.user?.lastName}{" "}
                {adminAthleteState.user?.invitationCode ? (
                    <span className="admin-invited-text">Invited</span>
                ) : (
                    <></>
                )}
            </>
        );
    }

    return adminAthleteState.user ? (
        <div
            className="row admin-athlete-content-container"
            style={{ gap: "20px", alignItems: "start" }}
        >
            <Avatar url={adminAthleteState.user.avatarUrl} size="large" />
            <div style={{ flexGrow: 1 }}>
                <div style={{ padding: "5px 0 20px 0" }}>
                    <div className="admin-athlete-header-title">
                        <h1>{athleteName()}</h1>
                        <p>{adminAthleteState.user.email}</p>
                        {adminAthleteState.user.id && (
                            <a
                                className="text-link"
                                href={mixpanelProfileUrl(adminAthleteState.user.id)}
                            >
                                Mixpanel Profile
                            </a>
                        )}
                    </div>
                </div>
                <AdminAthleteDetailsSummary athlete={adminAthleteState.user.athleteDetails} />
            </div>
        </div>
    ) : (
        <Spinner />
    );
}

export function AdminAthleteDetailsSectionHeader({
    text,
    onPlusClick,
}: {
    text: string;
    onPlusClick?: () => void;
}) {
    return (
        <div className="row">
            <h2 className="section-header">{text}</h2>
            <div style={{ flexGrow: 1 }} />
            {onPlusClick && (
                <img
                    src="/+.svg"
                    alt="plus"
                    style={{ height: "12px", width: "12px" }}
                    onClick={onPlusClick}
                />
            )}
        </div>
    );
}
