import { Context, createContext, useCallback, useContext, useState } from "react";
import sendbirdSelectors from "@sendbird/uikit-react/sendbirdSelectors";
import { run } from "./api";
import { compareAsc } from "date-fns";
import { trackAction } from "../vendor/mixpanel";
import { User, UserContext } from "./user";
import { Channel, convertChannel, getChannel } from "../vendor/sendbird";
import { useSendbirdStateContext } from "@sendbird/uikit-react";
import { GroupChannel as SendbirdGroupChannel } from "@sendbird/chat/groupChannel";

export type ScheduledMessage = {
    id: string;
    message: string;
    time: Date;
    status: "pending" | "canceled" | "sent";
};

type ChannelState = {
    scheduledMessages: ScheduledMessage[];
    channel?: Channel;
};

type RefreshScheduledMessagesAction = {
    type: "refresh_scheduled_messages";
};

type SendMessageAction = {
    type: "send_message";
    message: string;
};

type SendFileMessageAction = {
    type: "send_file_message";
    file: File;
};

type SendScheduledMessageAction = {
    type: "send_scheduled_message";
    message: string;
    time: Date;
};

type CancelScheduledMessageAction = {
    type: "cancel_scheduled_message";
    scheduledMessageId: string;
};

type ChannelAction =
    | RefreshScheduledMessagesAction
    | SendMessageAction
    | SendFileMessageAction
    | SendScheduledMessageAction
    | CancelScheduledMessageAction;

const initialState: ChannelState = {
    scheduledMessages: [],
};

export function getRecipient(channel: Channel | undefined): User | undefined {
    return channel?.otherMembers.length === 1 ? channel.otherMembers[0] : undefined;
}

export function useChannel(
    channelUrl: string,
    assignmentId: string,
): [ChannelState, (action: ChannelAction) => void] {
    const [userState] = useContext(UserContext);
    const [groupChannel, setGroupChannel] = useState<SendbirdGroupChannel>();
    const [channelState, setChannelState] = useState(initialState);
    const sendbirdUIKitContext = useSendbirdStateContext();

    const refresh = useCallback(async () => {
        const groupChannel = await getChannel(channelUrl);
        setGroupChannel(groupChannel);
        const userId = userState.user?.id;
        if (userId) {
            const channel = await convertChannel(userId, groupChannel);
            setChannelState(state => ({ ...state, channel }));
        }
        const scheduledMessages: ScheduledMessage[] = await run({
            path: `/messaging-channels/${channelUrl}/scheduled-messages`,
            method: "GET",
        });
        setChannelState(state => ({
            ...state,
            scheduledMessages: scheduledMessages
                .filter(message => message.status === "pending")
                .sort((lhs, rhs) => compareAsc(lhs.time, rhs.time)),
        }));
    }, [setChannelState, channelUrl, userState.user?.id]);
    const channelDispatch = useCallback(
        async (action: ChannelAction) => {
            switch (action.type) {
                case "refresh_scheduled_messages":
                    await refresh();
                    break;
                case "send_message":
                    if (!groupChannel) {
                        throw new Error("Channel not loaded");
                    }
                    trackAction("user_send_message", {
                        message_type: "text",
                        item_id: channelUrl,
                        "Coaching Assignment": assignmentId,
                    });
                    const sendUserMessage =
                        sendbirdSelectors.getSendUserMessage(sendbirdUIKitContext);
                    await sendUserMessage(groupChannel, {
                        message: action.message,
                    });
                    break;
                case "send_file_message":
                    if (!groupChannel) {
                        throw new Error("Channel not loaded");
                    }
                    trackAction("user_send_message", {
                        message_type: "file",
                        item_id: channelUrl,
                        "Coaching Assignment": assignmentId,
                    });
                    const sendFileMessage =
                        sendbirdSelectors.getSendFileMessage(sendbirdUIKitContext);
                    await sendFileMessage(groupChannel, {
                        file: action.file,
                    });
                    break;
                case "send_scheduled_message":
                    await run({
                        path: `/messaging-channels/${channelUrl}/scheduled-messages`,
                        method: "POST",
                        body: {
                            message: action.message,
                            time: action.time,
                        },
                    });
                    await refresh();
                    break;
                case "cancel_scheduled_message":
                    await run({
                        path: `/messaging-channels/${channelUrl}/scheduled-messages/${action.scheduledMessageId}`,
                        method: "PATCH",
                        body: { status: "canceled" },
                    });
                    await refresh();
                    break;
            }
        },
        [channelUrl, assignmentId, refresh, sendbirdUIKitContext, groupChannel],
    );
    return [channelState, channelDispatch];
}

export const ChannelContext: Context<[ChannelState, (action: ChannelAction) => void]> =
    createContext([initialState, (_: ChannelAction) => {}]);
