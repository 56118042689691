import { run } from "./api";
import { Context, createContext, useCallback, useState } from "react";

export type GroupCourse = {
    id: string | undefined;
    title: string;
    price: number;
    description: string;
    start: string;
    cadence: string;
    sessionCount: number;
    seatCount: number;
    enrollBy: string;
    url: string;
};

async function getAllGroupCourses(): Promise<GroupCourse[]> {
    return run({ path: "/group-courses", method: "GET" });
}

async function createGroupCourse(course: GroupCourse): Promise<GroupCourse> {
    return await run({
        path: "/admin/group-courses",
        method: "POST",
        body: course,
    });
}

async function deleteGroupCourse(courseId: string): Promise<void> {
    await run({
        path: `/admin/group-courses/${courseId}`,
        method: "DELETE",
    });
}

type RefreshGroupCourses = {
    type: "refresh";
};

type CreateGroupCourse = {
    type: "create_group_course";
    course: GroupCourse;
};

type DeleteGroupCourse = {
    type: "delete_group_course";
    courseId: string;
};

type GroupCourseAction = RefreshGroupCourses | CreateGroupCourse | DeleteGroupCourse;

type GroupCourseState = GroupCourse[];

const initialState: GroupCourseState = [];

export function useGroupCourses(): [GroupCourseState, (action: GroupCourseAction) => void] {
    const [state, setState] = useState(initialState);
    const dispatch = useCallback(async (action: GroupCourseAction) => {
        switch (action.type) {
            case "refresh":
                setState(await getAllGroupCourses());
                break;
            case "create_group_course":
                await createGroupCourse(action.course);
                await dispatch({ type: "refresh" });
                break;
            case "delete_group_course":
                await deleteGroupCourse(action.courseId);
                await dispatch({ type: "refresh" });
                break;
        }
    }, []);
    return [state, dispatch];
}

export const GroupCourseContext: Context<[GroupCourseState, (action: GroupCourseAction) => void]> =
    createContext([initialState, _ => {}]);
