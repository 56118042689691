import React, { useContext } from "react";
import { AdminAthleteContext, InvitationWithInviter } from "data/admin-athlete";
import { AdminAthleteDetailsSectionHeader } from "./AdminAthleteDetailsHeader";

function InvitationCell({ invitation }: { invitation: InvitationWithInviter }) {
    return (
        <div>
            <p>
                {invitation.inviter.firstName} {invitation.inviter.lastName} invited{" "}
                {invitation.firstName} {invitation.lastName}
            </p>
            <p>{invitation.createdAt.toLocaleString()}</p>
            {invitation.accepterId ? (
                <div>
                    <p>Accepted on {invitation.updatedAt.toLocaleString()}</p>
                </div>
            ) : invitation.status === "deleted" ? (
                <div>
                    <p>Deleted on {invitation.updatedAt.toLocaleString()}</p>
                </div>
            ) : (
                <p>Pending</p>
            )}
        </div>
    );
}

export function AdminAthleteDetailsInvitations() {
    const [adminAthleteState, adminAthleteDispatch] = useContext(AdminAthleteContext);
    return adminAthleteState.invitations.length ? (
        <div className="admin-athlete-content-container">
            <AdminAthleteDetailsSectionHeader text="Invitations" />
            {adminAthleteState.invitations.map(invitation => (
                <div>
                    <hr />
                    <InvitationCell invitation={invitation} />
                    {!invitation.accepterId && invitation.status !== "deleted" ? (
                        <button
                            onClick={() =>
                                adminAthleteDispatch({
                                    type: "delete_invitation",
                                    code: invitation.code,
                                })
                            }
                        >
                            Delete invitation {invitation.code}
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            ))}
        </div>
    ) : (
        <></>
    );
}
