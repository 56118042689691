import { useContext, useRef, useState, useCallback, useEffect } from "react";
import { MessageTemplateData, messageTemplates } from "./templates";
import Confirmation, { ConfirmationContext } from "../../shared/Confirmation";
import { useCoachData, useSessionsByAssignment, useUser } from "../../../data/coach";
import { useParams, useSearchParams } from "react-router-dom";
import { useMe } from "../../../data/user";
import { isSeriesInstance } from "../../../data/session";
import { ChannelsContext } from "vendor/sendbird";
import { compareAsc, compareDesc } from "date-fns";
import { Spinner } from "components/shared/Spinner";

function TemplateRow({ name, onSelect }: { name: string; onSelect: () => void }) {
    const ref = useRef<HTMLInputElement>(null);
    return (
        <div
            onClick={() => ref.current?.click()}
            style={{
                margin: "10px",
                border: "1px solid grey",
                padding: "20px 10px",
            }}
        >
            <input ref={ref} type="radio" name="template" onChange={() => onSelect()} />
            <label>{name}</label>
        </div>
    );
}

function MessageTemplatesModal({
    setText,
    coachUserId,
}: {
    setText: (text: string) => void;
    coachUserId: string;
}) {
    const params = useParams();
    const assignmentId = params.assignmentId!;
    const { data: sessions, isLoading } = useSessionsByAssignment(assignmentId);
    const [templateData, dispatch] = useMessageTemplates(assignmentId, coachUserId);
    const [, setShowModal] = useContext(ConfirmationContext);
    const [template, setTemplate] = useState("");
    function submit() {
        setText(messageTemplates[template]?.call(null, templateData) ?? "");
    }
    useEffect(() => {
        dispatch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessions]);
    if (isLoading) return <Spinner />;
    return (
        <Confirmation title="Message Templates">
            <Confirmation.Body>
                {Object.keys(messageTemplates).map(templateName => (
                    <TemplateRow
                        key={templateName}
                        name={templateName}
                        onSelect={() => setTemplate(templateName)}
                    />
                ))}
            </Confirmation.Body>
            <Confirmation.Button
                label="Cancel"
                onClick={() => setShowModal(false)}
                isPrimary={false}
                isDestructive={false}
                disabled={false}
            />
            <Confirmation.Button
                label="Insert"
                onClick={submit}
                isPrimary={true}
                isDestructive={false}
                disabled={!template.length}
            />
        </Confirmation>
    );
}

function useMessageTemplates(
    assignmentId: string,
    coachUserId: string,
): [MessageTemplateData, () => void] {
    const coachState = useCoachData(coachUserId);
    const { data: allSessions = [] } = useSessionsByAssignment(assignmentId);
    const { data: coach } = useUser(coachUserId);
    const [params] = useSearchParams();
    const channels = useContext(ChannelsContext);
    const recepientNames = useCallback(() => {
        const channel = channels.find(c => c.url === params.get("channelUrl"));
        return channel?.otherMembers.map(u => u.firstName)?.join(" and ");
    }, [params, channels]);
    const athlete = coachState.athletes.find(athlete => athlete.assignmentId === assignmentId);
    const [state, setState] = useState<MessageTemplateData>({
        athleteName: athlete?.user?.firstName ?? "____",
        coachName: coach?.firstName ?? "____",
        discipline: athlete?.discipline === "mindset" ? "Mental Performance" : "Nutrition",
        parentName: athlete?.parents[0]?.firstName ?? "____",
        recepientNames: recepientNames() ?? "____",
        lastSession: null,
        nextSession: null,
    });
    useEffect(() => {
        setState(state => ({
            ...state,
            recepientNames: recepientNames() ?? state.recepientNames,
        }));
    }, [recepientNames]);
    const dispatch = useCallback(() => {
        const now = new Date();
        const pastSessions = allSessions.filter(session => session.date < now);
        const futureSessions = allSessions.filter(session => session.date > now);
        setState(state => {
            const lastSession = pastSessions.sort((lhs, rhs) => compareDesc(lhs.date, rhs.date))[0];
            const nextSession = futureSessions.sort((lhs, rhs) =>
                compareAsc(lhs.date, rhs.date),
            )[0];
            return {
                ...state,
                ...(lastSession
                    ? {
                          lastSession: isSeriesInstance(lastSession)
                              ? lastSession.start
                              : lastSession.date,
                      }
                    : {}),
                ...(nextSession
                    ? {
                          nextSession: isSeriesInstance(nextSession)
                              ? nextSession.start
                              : nextSession.date,
                      }
                    : {}),
            };
        });
    }, [allSessions]);
    return [state, dispatch];
}

export function MessageTemplatesButton({ setText }: { setText: (text: string) => void }) {
    const modalContext = useState(false);
    const [, setShowModal] = modalContext;
    const { data: coach } = useMe();
    return (
        <>
            <button className="attachment-button" onClick={() => setShowModal(true)}>
                <div className="attachment-button--inner">
                    <div className="attachment-button--icon">
                        <img
                            style={{ width: "20px" }}
                            src="/template.svg"
                            alt="templates"
                            title="Messaging Templates"
                        />
                    </div>
                </div>
            </button>
            {coach && (
                <ConfirmationContext.Provider value={modalContext}>
                    <MessageTemplatesModal setText={setText} coachUserId={coach.id} />
                </ConfirmationContext.Provider>
            )}
        </>
    );
}
