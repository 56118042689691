import { Context, createContext, useCallback, useState } from "react";
import { run, TeapotError } from "./api";
import { identify } from "../vendor/mixpanel";
import { CoachingType } from "./assignment";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "./query";

export type AthleteDetails = {
    id: string;
    userId?: string;
    gender: string;
    dateOfBirth: Date;
    school: string;
    state: string;
    hometown: string;
    schoolLevel: string;
    graduationYear?: number;
    sports: string[];
    coachingInterests: CoachingType[];
    imgAcademyStudent?: boolean;
    imgAcademyCampAttendee?: boolean;
    goals: string[];
};

export type CoachDetails = {
    id: string;
    bioLink?: string;
};

export type User = {
    id: string;
    firstName: string;
    lastName: string;
    type: "admin" | "coach" | "parent" | "athlete";
    email: string;
    timezone?: string;
    athleteDetails?: AthleteDetails;
    coachDetails?: CoachDetails;
    status: "active" | "suspended" | "underage";
    adminTestUser: boolean;
    createdAt: Date;
    parents?: User[];
};

export type LoggedInState = {
    user?: User;
    isMaintenance: boolean;
};

const initialState: LoggedInState = {
    user: undefined,
    isMaintenance: false,
};

type LoggedInAction = "refresh";

async function getMe(): Promise<User> {
    return run({ path: "/me", method: "GET" });
}

export function useMe() {
    return useQuery({
        queryKey: queryKeys.users.me,
        queryFn: getMe,
        staleTime: 30000,
    });
}

export async function markTestUser(userId: string, isTestUser: boolean): Promise<User> {
    return run({ path: `/users/${userId}`, method: "PATCH", body: { adminTestUser: isTestUser } });
}

export async function convertAthleteToParent(
    id: string,
    data: Pick<Partial<User>, "firstName" | "lastName">,
): Promise<User> {
    return run({ path: `/users/${id}`, method: "PATCH", body: { ...data, type: "parent" } });
}

const userCache: { [id: string]: User } = {};

export async function getUser(id: string): Promise<User> {
    const cached = userCache[id];
    if (cached) {
        return cached;
    } else {
        const user = await run<User>({ path: `/users/${id}`, method: "GET" });
        userCache[id] = user;
        return user;
    }
}

export async function getAdminUsers(
    athleteIds: string[] = [],
    userIds: string[] = [],
): Promise<User[]> {
    if (athleteIds.length === 0 && userIds.length === 0) return [];
    const users = await run<User[]>({
        path: `/admin/users`,
        method: "POST",
        body: {
            athleteIds: Array.from(new Set(athleteIds)),
            userIds: Array.from(new Set(userIds)),
        },
    });
    for (const user of users) {
        userCache[user.id] = user;
    }
    return users;
}

export function useLoggedInState(): [LoggedInState, (action: LoggedInAction) => void] {
    const [state, setState] = useState<LoggedInState>(initialState);
    const dispatch = useCallback(async (action: LoggedInAction) => {
        switch (action) {
            case "refresh":
                try {
                    const user = await getMe();
                    identify(user.id);
                    setState({ user, isMaintenance: false });
                    return;
                } catch (error) {
                    if (error instanceof TeapotError) {
                        setState({ user: undefined, isMaintenance: true });
                    }
                    return;
                }
        }
    }, []);
    return [state, dispatch];
}

export const UserContext: Context<[LoggedInState, (action: LoggedInAction) => void]> =
    createContext([initialState, _ => {}]);
