import { format, isToday, isTomorrow } from "date-fns";
import { ChannelContext, getRecipient, ScheduledMessage } from "data/channel";
import "./ScheduledMessagesModal.css";
import { useContext } from "react";
import { currentTimeZoneName, recipientTime } from "../../../util";
import { User } from "../../../data/user";

function timeString(date: Date, recipient: User | undefined): string {
    const time = format(date, "h:mm a");
    let suffix = "";
    const recipientTimeString = recipientTime(date, recipient);
    if (recipientTimeString) {
        suffix = `(${recipientTimeString})`;
    }
    if (isToday(date)) {
        return `Send today at ${time} ${suffix}`;
    } else if (isTomorrow(date)) {
        return `Send tomorrow at ${time} ${suffix}`;
    } else {
        const dateString = format(date, "M/d/yyyy");
        return `Send ${dateString} at ${time} ${suffix}`;
    }
}

function ScheduledMessageRow({
    message,
    recipient,
    cancel,
}: {
    message: ScheduledMessage;
    recipient: User | undefined;
    cancel: () => void;
}) {
    return (
        <div className="scheduled-messages-modal-row">
            <div className="row">
                <div className="column scheduled-messages-modal-row-text">
                    <span className="scheduled-messages-modal-row-message">{message.message}</span>
                    <span className="scheduled-messages-modal-row-time">
                        {timeString(message.time, recipient)}
                    </span>
                </div>
                <button className="scheduled-messages-modal-row-delete" onClick={cancel}>
                    Delete
                </button>
            </div>
            <hr />
        </div>
    );
}

export function ScheduledMessagesModal() {
    const [channelState, channelDispatch] = useContext(ChannelContext);
    const messages = channelState.scheduledMessages;
    const recipient = getRecipient(channelState.channel);
    const cancelScheduledMessage = (scheduledMessageId: string) => {
        channelDispatch({
            type: "cancel_scheduled_message",
            scheduledMessageId,
        });
    };
    return (
        <div className="scheduled-messages-modal">
            <span className="modal-title">Scheduled Messages</span>
            <div className="row" style={{ gap: "4px", marginLeft: "22px" }}>
                <img src="/globe.svg" alt="globe" />
                <span className="modal-subtitle">{currentTimeZoneName()}</span>
            </div>
            <ul className="no-li-style scheduled-messages-modal-list">
                {messages.map(message => (
                    <li
                        className="scheduled-messages-modal-list-item"
                        key={message.time.toISOString()}
                    >
                        <ScheduledMessageRow
                            message={message}
                            recipient={recipient}
                            cancel={() => cancelScheduledMessage(message.id)}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}
