import React, { useContext } from "react";
import { UserContext } from "data/user";
import { CoachHome } from "components/coach/CoachHome";
import { Navigate, useLocation } from "react-router-dom";
import { Spinner } from "./shared/Spinner";
import { AuthContext } from "../data/auth";

export function UserHome() {
    const [userState] = useContext(UserContext);
    const [authState, authDispatch] = useContext(AuthContext);
    const location = useLocation();
    switch (userState.user?.type) {
        case undefined:
            return <Spinner />;
        case "coach":
            return <CoachHome id={userState.user!.id} />;
        case "athlete":
        case "parent":
            return (
                <div className="content">
                    <p>Sorry, this content is not available at this time.</p>
                </div>
            );
        case "admin":
            if (authState.malkovichUserId) {
                return (
                    <div className="d-flex flex-col align-items-center">
                        <div className="p-4">Malkovich Initializing...</div>
                        <button className="pill" onClick={_ => authDispatch({ type: "sign_out" })}>
                            Log Out
                        </button>
                    </div>
                );
            } else {
                return <Navigate to="/admin" state={{ from: location }} replace />;
            }
    }
}
