import mixpanel from "mixpanel-browser";

export function identify(userId: string) {
    mixpanel.identify(`plus-user-${userId}`);
}

export function trackPageView(name: string, params: { [key: string]: any } = {}) {
    mixpanel.track(`view_page_${name}`, params);
}

export function trackAction(name: string, params: { [key: string]: any } = {}) {
    mixpanel.track(name, params);
}

export function initializeMixpanel() {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN!);
}

export function mixpanelProfileUrl(userId: string): string {
    switch (process.env.REACT_APP_ENV) {
        case "development":
            return `https://mixpanel.com/project/2562480/view/3102180/app/profile#distinct_id=plus-user-${userId}`;
        case "staging":
            return `https://mixpanel.com/project/2562163/view/3101863/app/profile#distinct_id=plus-user-${userId}`;
        case "production":
            return `https://mixpanel.com/project/2565061/view/3104741/app/profile#distinct_id=plus-user-${userId}`;
        default:
            throw new Error("Missing REACT_APP_ENV");
    }
}
