import { CoachingAssignment, CoachingType } from "./assignment";
import { useState, createContext, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { run } from "data/api";
import { AthleteDetails, CoachDetails, User } from "./user";
import _ from "lodash";
import { SortDirection } from "@tanstack/react-table";
import { RelationshipInvitation } from "./invitation";

export type PartialUser = Pick<User, "id" | "type" | "firstName" | "lastName"> & {
    createdInvitations: RelationshipInvitation[];
};

export interface SearchUser extends PartialUser, Pick<User, "status" | "createdAt"> {
    athleteDetails?: Pick<AthleteDetails, "id" | "sports" | "gender" | "dateOfBirth"> & {
        coachingAssignments: (Pick<CoachingAssignment, "id"> & {
            coach: Pick<CoachDetails, "id"> & { user: PartialUser };
            coachingType: CoachingType;
        })[];
    };
    coachDetails?: Pick<CoachDetails, "id"> & {
        coachingAssignments: (Pick<CoachingAssignment, "id"> & {
            athlete: Pick<AthleteDetails, "id"> & {
                relationshipInvitations: RelationshipInvitation[];
                user?: PartialUser;
            };
        })[];
    };
    children: {
        child: PartialUser & { athleteDetails?: Pick<AthleteDetails, "id"> };
    }[];
    parents: {
        parent: PartialUser;
    }[];
    _count: {
        skillCompletions: number;
        courseCompletions: number;
    };
}

interface SearchConfig {
    pageIndex: number;
    pageSize: number;
    query: string;
    sortKey?: SortKey;
    sortOrder?: SortDirection;
    disciplines?: CoachingType[];
    types?: User["type"][];
    coachingStatus?: AthleteCoachingStatus[];
    hasOnDemandCompletions?: boolean;
    test?: boolean;
}

export enum AthleteCoachingStatus {
    active = "active",
    churned = "churned",
    suspended = "suspended",
}

interface SearchResponse {
    results: SearchUser[];
    totalCount: number;
}

async function search(config: SearchConfig): Promise<SearchResponse> {
    return run({
        path: "/admin/search",
        method: "GET",
        params: _({
            pageIndex: config.pageIndex.toString(),
            pageSize: config.pageSize.toString(),
            query: config.query,
            sortKey: config.sortKey,
            sortOrder: config.sortOrder,
            disciplines: config.disciplines,
            types: config.types,
            coachingStatus: config.coachingStatus,
            hasOnDemandCompletions: config.hasOnDemandCompletions?.toString(),
            test: config.test,
        })
            .omitBy(_.isNull)
            .omitBy(_.isUndefined)
            .value(),
    });
}

type SearchState = SearchConfig & SearchResponse;

const initial: SearchState = {
    pageIndex: 0,
    pageSize: 10,
    results: [],
    totalCount: 0,
    query: "",
};

type SearchAction =
    | { type: "init" }
    | { type: "setPageIndex"; index: number }
    | { type: "incrementPage" }
    | { type: "decrementPage" }
    | { type: "setPageSize"; size: number }
    | { type: "sort"; key?: SortKey; order?: SortDirection }
    | { type: "config"; config: Partial<SearchConfig> }
    | { type: "query"; query: string };

export enum SortKey {
    firstName = "firstName",
    lastName = "lastName",
    email = "email",
    dateOfBirth = "dateOfBirth",
    gender = "gender",
    createdAt = "createdAt",
    type = "type",
}

export function useSearchState(
    config: Partial<SearchConfig> = {},
): [SearchState, (action: SearchAction) => void] {
    const [, setParams] = useSearchParams();
    const [state, setState] = useState({ ...initial, ...config });
    const dispatch = useCallback(
        async (action: SearchAction) => {
            let config = { ...state };
            switch (action.type) {
                case "setPageIndex":
                    config.pageIndex = action.index;
                    break;
                case "setPageSize":
                    config.pageSize = action.size;
                    config.pageIndex = 0;
                    break;
                case "query":
                    config.query = action.query;
                    config.pageIndex = 0;
                    break;
                case "decrementPage": {
                    const index = config.pageIndex - 1;
                    config.pageIndex = index >= 0 ? index : 0;
                    break;
                }
                case "incrementPage": {
                    const index = config.pageIndex + 1;
                    config.pageIndex = index;
                    break;
                }
                case "sort": {
                    config.sortKey = action.key;
                    config.sortOrder = action.order;
                    config.pageIndex = 0;
                    break;
                }
                case "config": {
                    config = {
                        ...config,
                        ...action.config,
                    };
                    config.pageIndex = 0;
                    break;
                }
                case "init":
                    break;
            }
            setState(state => ({
                ...state,
                ...config,
            }));
            setParams(
                _({
                    query: config.query,
                    sortKey: config.sortKey,
                    sortOrder: config.sortOrder,
                    pageSize: config.pageSize.toString(),
                    pageIndex: config.pageIndex.toString(),
                    disciplines: config.disciplines,
                    types: config.types,
                    coachingStatus: config.coachingStatus,
                    hasOnDemandCompletions: config.hasOnDemandCompletions?.toString(),
                    test: config.test?.toString(),
                })
                    .omitBy(_.isNull)
                    .omitBy(_.isUndefined)
                    .value(),
            );
            const response =
                config.query.length === 0
                    ? { results: [], totalCount: 0 }
                    : await search({ ...config, query: config.query });
            setState(state => ({
                ...state,
                ...response,
            }));
        },
        [state, setParams],
    );
    return [state, dispatch];
}

export const SearchContext = createContext<[SearchState, (action: SearchAction) => void]>([
    initial,
    () => {},
]);
