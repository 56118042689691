import { AthleteDetails, CoachDetails, User } from "./user";
import { CalendarAvailabilitySettings, setBioLink, CoachingMatchPreferences } from "./coach";
import {
    CoachingSession,
    CoachingSessionSeries,
    getCoachSessions,
    SessionsByDate,
} from "./session";
import { run } from "./api";
import { Context, createContext, useCallback, useState } from "react";
import { RelationshipInvitation } from "./invitation";
import { CoachingAssignment, CoachingAssignmentStatus } from "./assignment";
import { accessMedia, Avatar, uploadAvatar } from "./avatar";
import { addDays, subMonths } from "date-fns";
import { upsertMatchPreferences } from "./match-preferences";

type AdminCoachAssignmentResponse = CoachingAssignment & {
    athlete: AthleteDetails & {
        user?: User & {
            avatar?: Avatar;
        };
        relationshipInvitations: RelationshipInvitation[];
        coachingAssignments: CoachingAssignment[];
    };
    sessions: CoachingSession[];
    series: CoachingSessionSeries[];
};

type AdminCoachResponse = CoachDetails & {
    user: User & {
        avatar?: Avatar;
        calendarAvailabilitySettings: CalendarAvailabilitySettings;
    };
    coachingMatchPreferences: CoachingMatchPreferences;
};

export type AdminCoachAthlete = {
    userId?: string;
    firstName: string;
    lastName: string;
    avatarUrl?: string;
    email?: string;
    athleteDetailsId: string;
    invited: boolean;
    coachId: CoachDetails["id"];
    assignments: CoachingAssignment[];
};

export type AdminCoachState = {
    user?: User;
    activeAthletes: AdminCoachAthlete[];
    churnedAthletes: AdminCoachAthlete[];
    matchPreferences?: CoachingMatchPreferences;
    sessions?: SessionsByDate;
};

async function parseAssignment(response: AdminCoachAssignmentResponse): Promise<AdminCoachAthlete> {
    if (response.athlete.user) {
        return {
            ...response.athlete.user,
            userId: response.athlete.userId,
            avatarUrl: response.athlete.user.avatar
                ? await accessMedia(response.athlete.user.avatar.mediaId)
                : undefined,
            athleteDetailsId: response.athlete.id,
            invited: false,
            coachId: response.coachId,
            assignments: response.athlete.coachingAssignments,
        };
    } else {
        const invitation = response.athlete.relationshipInvitations[0];
        return {
            firstName: invitation?.firstName ?? "",
            lastName: invitation?.lastName ?? "",
            email: invitation?.inviteeEmail,
            athleteDetailsId: response.athleteId,
            invited: true,
            coachId: response.coachId,
            assignments: response.athlete.coachingAssignments,
        };
    }
}

async function parseResponse(
    adminCoach: AdminCoachResponse,
    activeAssignments: AdminCoachAssignmentResponse[],
    suspendedAssignments: AdminCoachAssignmentResponse[],
): Promise<AdminCoachState> {
    const { user } = adminCoach;
    return {
        user: {
            ...user,
            coachDetails: adminCoach,
        },
        activeAthletes: await Promise.all(activeAssignments.map(parseAssignment)),
        churnedAthletes: await Promise.all(suspendedAssignments.map(parseAssignment)),
        matchPreferences: adminCoach.coachingMatchPreferences,
    };
}

async function getAdminCoach(coachId: string): Promise<AdminCoachResponse> {
    return run({ path: `/admin/coaches/${coachId}`, method: "GET" });
}

async function getAdminCoachCoachingAssignments(
    coachId: string,
    status: CoachingAssignmentStatus,
): Promise<AdminCoachAssignmentResponse[]> {
    return run({
        path: `/admin/coaches/${coachId}/coaching-assignments`,
        method: "GET",
        params: { status },
    });
}

type AdminCoachRefreshAction = {
    type: "refresh";
};

type AdminCoachSetBioLinkAction = {
    type: "set_bio_link";
    bioLink: string;
};

type AdminCoachSetAvatarAction = {
    type: "set_avatar";
    userId: string;
    file: File;
};

type AdminCoachSetMatchPreferences = {
    type: "set_match_preferences";
    matchPreferences: CoachingMatchPreferences;
};

type AdminCoachAction =
    | AdminCoachRefreshAction
    | AdminCoachSetBioLinkAction
    | AdminCoachSetAvatarAction
    | AdminCoachSetMatchPreferences;

const initialState: AdminCoachState = {
    user: undefined,
    activeAthletes: [],
    churnedAthletes: [],
};

export function useAdminCoach(
    coachId: string,
): [AdminCoachState, (action: AdminCoachAction) => void] {
    const [state, setState] = useState<AdminCoachState>(initialState);
    const dispatch = useCallback(
        async action => {
            switch (action.type) {
                case "refresh":
                    const response = await getAdminCoach(coachId);
                    const activeAssignments = await getAdminCoachCoachingAssignments(
                        coachId,
                        "active",
                    );
                    const suspendedAssignments = await getAdminCoachCoachingAssignments(
                        coachId,
                        "suspended",
                    );
                    const endDate = addDays(new Date(), 30);
                    const sessions = await getCoachSessions(
                        coachId,
                        subMonths(new Date(), 2),
                        endDate,
                    );
                    setState({
                        ...(await parseResponse(response, activeAssignments, suspendedAssignments)),
                        sessions,
                    });
                    break;
                case "set_bio_link":
                    await setBioLink(coachId, action.bioLink);
                    await dispatch({ type: "refresh" });
                    break;
                case "set_avatar":
                    await uploadAvatar(action.userId, action.file);
                    await dispatch({ type: "refresh" });
                    break;
                case "set_match_preferences":
                    await upsertMatchPreferences(action.matchPreferences);
                    await dispatch({ type: "refresh" });
                    break;
            }
        },
        [coachId],
    );
    return [state, dispatch];
}

export const AdminCoachContext: Context<[AdminCoachState, (action: AdminCoachAction) => void]> =
    createContext([initialState, _ => {}]);
