import { ChannelContext } from "data/channel";
import { Modal } from "components/shared/Modal";
import { useContext, useState } from "react";
import { ScheduledMessagesModal } from "./ScheduledMessagesModal";
import "./ScheduledMessagesBanner.css";
import { format, isToday, isTomorrow } from "date-fns";

function timeString(date: Date): string {
    const time = format(date, "h:mm a");
    if (isToday(date)) {
        return `today at ${time}`;
    } else if (isTomorrow(date)) {
        return `tomorrow at ${time}`;
    } else {
        const dateString = format(date, "M/d/yyyy");
        return `on ${dateString} at ${time}`;
    }
}

export function ScheduledMessagesBanner() {
    const [channelState] = useContext(ChannelContext);
    const scheduledMessages = channelState.scheduledMessages;
    const [showModal, setShowModal] = useState(false);
    return (
        <div className="scheduled-messages-banner">
            <Modal show={showModal} hide={() => setShowModal(false)}>
                <ScheduledMessagesModal />
            </Modal>
            <img alt="clock" src="/clock.svg" style={{ width: "16px", height: "16px" }} />
            <span className="scheduled-messages-banner-text">
                {scheduledMessages.length > 1
                    ? `${scheduledMessages.length} messages scheduled to be sent.`
                    : `Your message will be sent ${timeString(scheduledMessages[0]!.time)}.`}
            </span>
            <span className="scheduled-messages-banner-button" onClick={() => setShowModal(true)}>
                See scheduled messages
            </span>
        </div>
    );
}
