import "./Toolbar.css";
import { useContext, useRef, useState } from "react";
import { AuthContext } from "../data/auth";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { NotificationContext, notificationsSupported } from "../util/notifications";
import { useClickOutside } from "./shared/Modal";
import _ from "lodash";

function PopupMenu() {
    const [notificationState, notificationDispatch] = useContext(NotificationContext);
    const [, authDispatch] = useContext(AuthContext);
    const navigate = useNavigate();
    return (
        <div className="popup-menu column">
            {notificationsSupported() ? (
                <div className="popup-menu-item">
                    <p>Browser Notifications</p>
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={notificationState.enabled}
                            onChange={() => {
                                notificationDispatch({
                                    type: "set_enabled",
                                    enabled: !notificationState.enabled,
                                });
                            }}
                        />
                        <span className="slider round" />
                    </label>
                </div>
            ) : (
                <></>
            )}
            <div style={{ height: "1px", backgroundColor: "#eee" }} />
            <button
                className="popup-menu-item"
                onClick={() => {
                    authDispatch({ type: "sign_out" });
                    navigate("/");
                }}
            >
                <p>Logout</p>
            </button>
        </div>
    );
}

const backButtonPathMatches = ["messaging", "assignments", "previous-sessions", "/sessions"];

export function BackButton() {
    const location = useLocation();
    const navigate = useNavigate();
    if (_.some(backButtonPathMatches, match => location.pathname.includes(match))) {
        return (
            <button onClick={() => navigate("/")} style={{ width: "100px" }}>
                <img alt="back" src="/back-chevron.svg" style={{ transform: "rotate(180deg)" }} />
            </button>
        );
    } else {
        return <></>;
    }
}

export function Toolbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [authState] = useContext(AuthContext);
    const menuRef = useRef(null);
    useClickOutside(menuRef, () => setMenuOpen(false));
    return (
        <div className="toolbar">
            <div style={{ width: "100px", paddingLeft: "20px" }}>
                <BackButton />
            </div>
            <NavLink className={"logo"} to={"/"}>
                <img alt={"IMGA+ Logo"} src={"/logo.svg"} />
            </NavLink>
            <div
                className={"actions"}
                style={{ width: "100px", textAlign: "right", paddingRight: "20px" }}
            >
                {menuOpen ? (
                    <div ref={menuRef}>
                        <PopupMenu />
                    </div>
                ) : (
                    <></>
                )}
                {authState.status === "signed_in" ? (
                    <button
                        onClick={() => setMenuOpen(true)}
                        style={{
                            width: "100px",
                            pointerEvents: menuOpen ? "none" : "inherit",
                        }}
                    >
                        <img alt="menu" src="/menu.svg" />
                    </button>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
