import { FirebaseOptions, initializeApp } from "firebase/app";

function firebaseConfig(): FirebaseOptions {
    switch (process.env.REACT_APP_ENV) {
        case "development":
            return {
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: "imga-plus-dev.firebaseapp.com",
                projectId: "imga-plus-dev",
                storageBucket: "imga-plus-dev.appspot.com",
                messagingSenderId: "462282395247",
                appId: "1:462282395247:web:53e311dab985c1393fc79a",
                measurementId: "G-SP2XKBEN80",
            };
        case "production":
            return {
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: "imga-plus-prod.firebaseapp.com",
                projectId: "imga-plus-prod",
                storageBucket: "imga-plus-prod.appspot.com",
                messagingSenderId: "823666658392",
                appId: "1:823666658392:web:6f283d0146cef59d3f4e2d",
                measurementId: "G-6S0T8EZWE9",
            };
        case "staging":
            return {
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: "imga-plus.firebaseapp.com",
                databaseURL: "https://imga-plus-default-rtdb.firebaseio.com",
                projectId: "imga-plus",
                storageBucket: "imga-plus.appspot.com",
                messagingSenderId: "418105228311",
                appId: "1:418105228311:web:612dc7feb948c6a9387052",
                measurementId: "G-FW2FL0F1VK",
            };
        default:
            throw Error("Missing REACT_APP_ENV");
    }
}

export function initializeFirebase() {
    initializeApp(firebaseConfig());
}

export function secondaryFirebaseApp() {
    return initializeApp(firebaseConfig(), "secondary");
}
