import { Navigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { getUser, User } from "../../data/user";
import { Spinner } from "../shared/Spinner";

type State = {
    user?: User;
};

type Action = {
    type: "refresh";
};

function useRedirector(userId: string): [State, (action: Action) => void] {
    const [state, setState] = useState<State>({});
    const dispatch = useCallback(
        async (action: Action) => {
            switch (action.type) {
                case "refresh":
                    const user = await getUser(userId);
                    setState({ user });
                    break;
            }
        },
        [userId],
    );
    return [state, dispatch];
}

export function MixpanelRedirector() {
    const location = useLocation();
    const params = useParams();
    const mixpanelId = params.mixpanelId!;
    const userId = mixpanelId.replace("plus-user-", "");
    const [state, dispatch] = useRedirector(userId);
    useEffect(() => {
        dispatch({ type: "refresh" });
    }, [dispatch]);
    if (state.user) {
        if (state.user.type === "parent") {
            return (
                <Navigate
                    to={`/admin/parents/${state.user.id}`}
                    state={{ from: location }}
                    replace
                />
            );
        } else if (state.user.type === "athlete" && state.user.athleteDetails) {
            return (
                <Navigate
                    to={`/admin/athletes/${state.user.athleteDetails.id}`}
                    state={{ from: location }}
                    replace
                />
            );
        } else if (state.user.type === "coach" && state.user.coachDetails) {
            return (
                <Navigate
                    to={`/admin/coaches/${state.user.coachDetails.id}`}
                    state={{ from: location }}
                    replace
                />
            );
        } else {
            return (
                <div>
                    Can't show user {state.user.firstName}... can't show admins or athletes/coaches
                    without details
                </div>
            );
        }
    }
    return <Spinner />;
}
