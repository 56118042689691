import "@sendbird/uikit-react/dist/index.css";
import { Channel, useChannels, SendbirdContext, ChannelsContext } from "vendor/sendbird";
import { useCallback, useContext, useEffect, useState } from "react";
import { ChannelsList } from "components/messaging/ChannelsList";
import { useParams, useSearchParams } from "react-router-dom";
import "./Messaging.css";
import { Spinner } from "../shared/Spinner";
import { trackPageView } from "../../vendor/mixpanel";
import { MessageInput } from "./input/MessageInput";
import { useChannel, ChannelContext } from "../../data/channel";
import SendbirdChannel from "@sendbird/uikit-react/Channel";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { LoggedInState, UserContext } from "../../data/user";
import { AuthContext, AuthState } from "../../data/auth";

const colorSet = {
    "--sendbird-light-primary-500": "#00487c",
    "--sendbird-light-primary-400": "#4bb3fd",
    "--sendbird-light-primary-300": "#3e6680",
    "--sendbird-light-primary-200": "#0496ff",
    "--sendbird-light-primary-100": "#027bce",
};

function ChannelContextProvider({
    channelUrl,
    assignmentId,
    children,
}: {
    channelUrl: string;
    assignmentId: string;
    children: JSX.Element;
}) {
    const context = useChannel(channelUrl, assignmentId);
    return <ChannelContext.Provider value={context}>{children}</ChannelContext.Provider>;
}

function disableMarkAsRead(userState: LoggedInState, authState: AuthState): boolean {
    return userState.user?.type === "admin" || !!authState.malkovichUserId;
}

export default function Messaging() {
    const params = useParams();
    const assignmentId = params.assignmentId;
    const channels = useChannels(assignmentId ?? "").sort((lhs, rhs) =>
        lhs.name > rhs.name ? 1 : -1,
    );
    if (!assignmentId) return null;
    return (
        <ChannelsContext.Provider value={channels}>
            <MessagingContent assignmentId={assignmentId} />
        </ChannelsContext.Provider>
    );
}

function MessagingContent({ assignmentId }: { assignmentId: string }) {
    const [queryParams, setQueryParams] = useSearchParams();
    const [sendbirdState] = useContext(SendbirdContext);
    const channels = useContext(ChannelsContext);
    const [channel, setChannel] = useState<Channel>();
    const [userState] = useContext(UserContext);
    const [authState] = useContext(AuthContext);
    const selectChannel = useCallback(
        (channel: Channel) => {
            trackPageView("messaging_channel", { channel_url: channel.url });
            setQueryParams({ channelUrl: channel.url });
            setChannel(channel);
        },
        [setChannel, setQueryParams],
    );
    useEffect(() => {
        trackPageView("messaging", { assignment_id: assignmentId });
    }, [assignmentId]);
    useEffect(() => {
        const queryChannelUrl = queryParams.get("channelUrl");
        const queryMismatch = queryChannelUrl && queryChannelUrl !== channel?.url;
        if (channels.length > 0 && (!channel || queryMismatch)) {
            const queryChannel = channels.find(channel => channel.url === queryChannelUrl);
            selectChannel(queryChannel ?? channels[0]!);
        }
    }, [channels, channel, selectChannel, queryParams]);
    return sendbirdState.userId && channel?.url && channels.length ? (
        <div className="messaging-container">
            <ChannelsList
                channels={channels}
                selectedChannel={channel}
                setChannel={selectChannel}
            />
            <SendbirdProvider
                userId={sendbirdState.userId}
                appId={process.env.REACT_APP_SENDBIRD_APP_ID!}
                colorSet={colorSet}
                accessToken={sendbirdState.sessionToken}
            >
                <ChannelContextProvider channelUrl={channel.url} assignmentId={assignmentId}>
                    <>
                        <SendbirdChannel
                            channelUrl={channel.url}
                            disableMarkAsRead={disableMarkAsRead(userState, authState)}
                            renderMessageInput={() => <MessageInput />}
                        />
                    </>
                </ChannelContextProvider>
            </SendbirdProvider>
        </div>
    ) : (
        <Spinner />
    );
}
