import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./AdminAthleteDetails.css";
import { AdminAthleteDetailsHeader } from "./AdminAthleteDetailsHeader";
import { AdminAthleteDetailsParents } from "./AdminAthleteDetailsParents";
import { AdminAthleteDetailsAssessments } from "./AdminAthleteDetailsAssessments";
import { useAdminAthlete, AdminAthleteContext } from "data/admin-athlete";
import { AdminAthleteDetailsCoaches } from "./AdminAthleteDetailsCoaches";
import { AdminAthleteDetailsInvitations } from "./AdminAthleteDetailsInvitations";
import { AdminMarkAsTestUser } from "../AdminMarkAsTestUser";
import { AdminNotesList } from "../AdminNotesList";
import { AdminAthleteFeedback } from "./AdminAthleteFeedback";
import { AdminOnboardingHistory } from "../AdminOnboardingHistory";
import { AdminDeleteUser } from "../AdminDeleteUser";
import { ActivationCode, AdminContext } from "data/admin";
import { ActivationCodeList } from "../activation_code/list/AdminActivationCodeList";
import { AdminAthleteIAP } from "./AdminAthleteIAP";
import { AdminUseActivationCode } from "../activation_code/AdminUseActivationCode";
import { AdminConvertToParent } from "./AdminConvertToParent";
import { useActivationCodeUsages } from "data/activation-code";
export function AdminAthleteDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const adminAthleteContext = useAdminAthlete(params.athleteId!);
    const [, adminDispatch] = useContext(AdminContext);
    const [state, adminAthleteDispatch] = adminAthleteContext;

    const userId = state.user?.id;
    const { data: usages = [] } = useActivationCodeUsages(userId ?? "", {
        enabled: !!userId,
    });
    const usedActivationCodes = usages
        .map(u => u.activationCode)
        .filter(c => !!c) as ActivationCode[];

    useEffect(() => {
        adminAthleteDispatch({ type: "refresh" });
    }, [adminAthleteDispatch, adminDispatch]);
    let DeleteUser: JSX.Element | null;
    if (state.user?.id && state.user?.email) {
        const user = { ...state.user, id: state.user.id, email: state.user.email };
        DeleteUser = <AdminDeleteUser user={user} />;
    } else {
        DeleteUser = null;
    }
    return (
        <AdminAthleteContext.Provider value={adminAthleteContext}>
            <div className="content admin-athlete-view-container">
                <AdminAthleteDetailsHeader />
                <AdminAthleteDetailsInvitations />
                {state.user?.id && <AdminOnboardingHistory userId={state.user.id} />}
                <AdminAthleteDetailsCoaches />
                <AdminNotesList notes={state.notes} />
                <AdminAthleteDetailsParents />
                <AdminAthleteDetailsAssessments />
                <AdminAthleteFeedback />
                <AdminAthleteIAP />
                <div>
                    <h4>Activation Codes Used</h4>
                    <ActivationCodeList activationCodes={usedActivationCodes} />
                </div>
                <AdminUseActivationCode athleteId={params.athleteId!} />
                {state.user && state.user?.id && (
                    <AdminConvertToParent
                        userId={state.user.id}
                        name={state.user}
                        onComplete={() => navigate(`/admin/parents/${state.user?.id}`)}
                    />
                )}
                {state.user?.id && (
                    <AdminMarkAsTestUser
                        userId={state.user.id}
                        onComplete={() => adminAthleteDispatch({ type: "refresh" })}
                        isTestUser={state.user.adminTestUser}
                    />
                )}
                {DeleteUser}
            </div>
        </AdminAthleteContext.Provider>
    );
}
