import { Modal } from "components/shared/Modal";
import { convertAthleteToParent } from "data/user";
import { useState } from "react";
import { Name } from "../../../util";
import "./AdminConvertToParent.css";

export function AdminConvertToParent({
    userId,
    name,
    onComplete,
}: {
    userId: string;
    name: Name;
    onComplete: () => void;
}) {
    const [modal, showModal] = useState(false);
    const [firstName, setFirstName] = useState(name.firstName);
    const [lastName, setLastName] = useState(name.lastName);
    const hasChanged = () => !(firstName === name.firstName && lastName === name.lastName);
    async function onClick() {
        await convertAthleteToParent(userId, { firstName, lastName });
        onComplete();
    }
    return (
        <div className="d-flex admin-convert-parent">
            <button className="pill-outline" onClick={() => showModal(true)}>
                Convert to parent
            </button>
            <Modal show={modal} hide={() => showModal(false)}>
                <div className="d-flex flex-col">
                    <h1>{`Are you sure you want to convert ${name.firstName} to a parent?`}</h1>
                    <p>
                        <b>WARNING: this action cannot be undone.</b>
                        <br />
                        Please confirm or update {name.firstName}'s details:
                    </p>
                    <div className="d-flex align-items-center">
                        <label>First:&nbsp;</label>
                        <input
                            type="text"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <label>Last:&nbsp;</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </div>
                    <button type="button" className="pill" onClick={() => onClick()}>
                        {hasChanged() ? "Update & Convert" : "Convert"}
                    </button>
                </div>
            </Modal>
        </div>
    );
}
