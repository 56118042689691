import { ActivationCode } from "data/admin";
import { useEffect, useState } from "react";
import { BrowserView, IOSView, isIOS } from "react-device-detect";
import "./DeepLink.css";

const DL_FALLBACK = encodeURIComponent("https://apps.apple.com/us/app/img-academy/id1581607815");
const DL_HOST = process.env.REACT_APP_FIREBASE_DL_HOST;
const DL_TAIL = `&isi=1581607815&ibi=${process.env.REACT_APP_IOS_BUNDLE_ID}&efr=1&ofl=${DL_FALLBACK}`;

export function constructActivationCodeDeepLink(code: ActivationCode): string {
    const host = window.location.protocol + "//" + window.location.host;
    let innerLink = encodeURIComponent(`${host}/deeplink?page=activationCode&code=${code.code}`);
    return `${DL_HOST}/?link=${innerLink}${DL_TAIL}`;
}

export function DeepLink() {
    const [showRedirect, setShowRedirect] = useState(false);

    useEffect(() => {
        if (isIOS) {
            redirect();
            setTimeout(() => {
                setShowRedirect(true);
            }, 2000);
        }
    }, []);

    const redirect = () => {
        const deepLink = encodeURIComponent(window.location.href);
        const longLink = `${DL_HOST}/?link=${deepLink}${DL_TAIL}`;
        window.location.assign(longLink);
    };

    return (
        <div className="deep-link">
            <img className="logo" alt={"IMGA+ Logo"} />
            <IOSView>
                <h2 className="text">Sending you to IMGA+ ...</h2>
                {showRedirect && (
                    <>
                        <p className="text">Not redirected? Click the button below.</p>
                        <input
                            className="pill"
                            type="button"
                            value="Take me to IMGA+"
                            onClick={redirect}
                        />{" "}
                    </>
                )}
            </IOSView>
            <BrowserView>
                <p className="text m-auto" style={{ margin: "auto" }}>
                    Viewing this on desktop? Try clicking the link again on your iOS device!
                </p>
            </BrowserView>
        </div>
    );
}
