import { AdminContext } from "data/admin";
import { FormEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./AdminCreateCoachForm.css";

export type CreateCoachDetails = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    bioLink: string;
};

const initialState: CreateCoachDetails = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    bioLink: "",
};

export function AdminCreateCoachForm() {
    const [, dispatch] = useContext(AdminContext);
    const [form, setForm] = useState<CreateCoachDetails>(initialState);
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);

    async function onSubmit(e: FormEvent) {
        e.preventDefault();
        try {
            await dispatch({ type: "create_coach", details: form });
            setSuccess(true);
        } catch (e) {
            setError(`${e}`);
        }
    }

    function isValid() {
        return (
            form.firstName.length > 0 &&
            form.lastName.length > 0 &&
            form.email.length > 0 &&
            form.password.length > 5 &&
            (!form.phone || form.phone.length === 10) &&
            (!form.bioLink || form.bioLink.length > 0)
        );
    }

    function onChange(e: any) {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    function successView(): JSX.Element {
        return (
            <div>
                <h2>
                    Successfully added {form.firstName} {form.lastName} as a coach.
                </h2>
                <Link to="/admin" className="admin-add-coach-button">
                    Back to Dashboard
                </Link>
            </div>
        );
    }

    function formView(): JSX.Element {
        return (
            <form className="admin-create-coach-form">
                {error && <div>{error}</div>}
                <h1>Create Coach Account</h1>
                <br />
                <label>First Name</label>
                <input type="text" name="firstName" value={form.firstName} onChange={onChange} />
                <label>Last Name</label>
                <input type="text" name="lastName" value={form.lastName} onChange={onChange} />
                <label>Email</label>
                <input type="email" name="email" value={form.email} onChange={onChange} />
                <label>Password</label>
                <input type="password" name="password" value={form.password} onChange={onChange} />
                <label>Phone (10-digits)</label>
                <input type="number" name="phone" value={form.phone} onChange={onChange} />
                <label>Bio Link</label>
                <input type="text" name="bioLink" value={form.bioLink} onChange={onChange} />
                <br />
                <button disabled={!isValid()} onClick={onSubmit}>
                    Create Coach
                </button>
            </form>
        );
    }

    return (
        <div className="admin-create-coach-form-container">
            {success ? successView() : formView()}
        </div>
    );
}
