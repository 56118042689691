import React, { useContext, useEffect, useState } from "react";
import { AdminContext } from "data/admin";
import { byFirstName } from "util/index";
import { CoachingType } from "data/assignment";
import { AdminAthleteContext } from "../../../data/admin-athlete";

export function AssignCoach({
    coachingType,
    hide,
}: {
    coachingType: CoachingType;
    hide: () => void;
}) {
    const [coachId, setCoachId] = useState<string>("");
    const [adminState, adminDispatch] = useContext(AdminContext);
    const [adminAthleteState, adminAthleteDispatch] = useContext(AdminAthleteContext);
    const activeCoaches = adminState.coaches.filter(c => c.status === "active");
    const submit = () => {
        adminAthleteDispatch({
            type: "assign_coach",
            athleteId: adminAthleteState.user!.athleteDetails.id,
            coachId,
            coachingType: coachingType,
        });
        hide();
    };

    useEffect(() => {
        adminDispatch({ type: "refresh", objects: "coaches" });
    }, [adminDispatch]);

    return (
        <div>
            <select onChange={e => setCoachId(e.target.value)}>
                <option value="">Select a coach</option>
                {activeCoaches.sort(byFirstName).map(coach => (
                    <option key={coach.id} value={coach.coachDetails?.id}>
                        {coach.firstName} {coach.lastName}
                    </option>
                ))}
            </select>
            {coachId ? (
                <button className="btn" style={{ marginLeft: "0.5rem" }} onClick={() => submit()}>
                    Assign Coach
                </button>
            ) : (
                <></>
            )}
        </div>
    );
}
