import { getCreatedAssignmentsReport } from "data/reporting";
import { subDays } from "date-fns";
import { useState } from "react";

function format(date: Date) {
    return date.toISOString().slice(0, 10);
}

export function ReportingPage() {
    const [start, setStart] = useState<string>(format(subDays(new Date(), 7)));
    const [end, setEnd] = useState<string>(format(new Date()));
    const [reports, setReports] = useState<{ [filename: string]: string }>({});
    return (
        <div style={{ flexDirection: "column" }}>
            <h2 className="section-header">Created Coaching Assignments</h2>
            <div style={{ flexDirection: "row" }}>
                <label style={{ padding: "0 8px" }}>
                    Start:
                    <input type="date" value={start} onChange={e => setStart(e.target.value)} />
                </label>
                <label style={{ padding: "0 8px" }}>
                    End:
                    <input type="date" value={end} onChange={e => setEnd(e.target.value)} />
                </label>
                <button
                    style={{ padding: "0 8px" }}
                    onClick={() => {
                        (async () => {
                            const csv = await getCreatedAssignmentsReport(
                                new Date(start),
                                new Date(end),
                            );
                            setReports({
                                ...reports,
                                [`coaching_assignments_created_${start}_${end}.csv`]:
                                    window.URL.createObjectURL(new Blob([csv])),
                            });
                        })();
                    }}
                >
                    Generate CSV
                </button>
            </div>
            <ol>
                {Object.entries(reports).map(([filename, url]) => (
                    <li key={filename}>
                        <a href={url} download={filename}>
                            {filename}
                        </a>
                    </li>
                ))}
            </ol>
        </div>
    );
}
