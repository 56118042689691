import { ChangeEvent, useContext, useState } from "react";
import { AdminCoachContext } from "data/admin-coach";

export function AdminCoachAvatarForm() {
    const [state, dispatch] = useContext(AdminCoachContext);
    const [file, setFile] = useState<File>();
    function changeHandler(event: ChangeEvent<HTMLInputElement>) {
        const file = event.currentTarget.files?.item(0);
        if (file) {
            setFile(file);
        }
    }
    function submit() {
        if (file && state.user?.id) {
            dispatch({
                type: "set_avatar",
                file: file,
                userId: state.user.id,
            });
        }
    }
    return (
        <div>
            <input type="file" onChange={changeHandler} />
            <button onClick={submit}>Submit</button>
        </div>
    );
}
