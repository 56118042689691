import React, { useContext, useEffect, useState } from "react";
import { AdminContext } from "data/admin";
import { byFirstName } from "util/index";
import { AdminAthleteContext } from "data/admin-athlete";

export function AssignParent() {
    const [adminAthleteState, adminAthleteDispatch] = useContext(AdminAthleteContext);
    const [parentId, setParentId] = useState<string>("");
    const [adminState, adminDispatch] = useContext(AdminContext);
    useEffect(() => {
        adminDispatch({ type: "refresh", objects: "parents" });
    }, [adminDispatch]);
    const submit = () => {
        if (adminAthleteState.user?.id) {
            adminAthleteDispatch({
                type: "assign_parent",
                childId: adminAthleteState.user.id,
                parentId,
            });
        }
    };
    return (
        <div>
            <select onChange={e => setParentId(e.target.value)}>
                <option value="">Select a parent</option>
                {adminState.parents.sort(byFirstName).map(parent => (
                    <option key={parent.id} value={parent.id}>
                        {parent.firstName} {parent.lastName}
                    </option>
                ))}
            </select>
            {parentId ? <button onClick={() => submit()}>Assign Parent</button> : <></>}
        </div>
    );
}
