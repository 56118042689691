import * as _ from "lodash";
import { User } from "../data/user";
import { formatToTimeZone } from "date-fns-timezone";
import { CoachingType } from "data/assignment";

export const byFirstName = (lhs: { firstName?: string }, rhs: { firstName?: string }) =>
    (lhs.firstName ?? "").localeCompare(rhs.firstName ?? "");
export const byFullName = (lhs: Name | undefined, rhs: Name | undefined) =>
    fullName(lhs).localeCompare(fullName(rhs));
export const byDate = (lhs: { date: Date }, rhs: { date: Date }) => (lhs.date > rhs.date ? 1 : -1);
export const byCreatedAt = (lhs: { createdAt: Date }, rhs: { createdAt: Date }) =>
    lhs.createdAt > rhs.createdAt ? 1 : -1;

export function formatEnumValues(values: string[]): string {
    return values.map(_.startCase).join(", ");
}

export function currentTimeZoneName(): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return formatToTimeZone(new Date(), "z", { timeZone });
}

export function recipientTime(date: Date, recipient: User | undefined): string | undefined {
    if (
        recipient?.timezone &&
        recipient.timezone !== Intl.DateTimeFormat().resolvedOptions().timeZone
    ) {
        const time = formatToTimeZone(date, "h:mm A", { timeZone: recipient.timezone });
        return `${time} for ${recipient.firstName}`;
    }
}

export function coachingTypeText(coachingType: CoachingType, capitalize: boolean = true) {
    switch (coachingType) {
        case "mindset":
            return capitalize ? "Mental Performance" : "mental performance";
        case "nutrition":
            return capitalize ? "Nutrition" : "nutrition";
    }
}

export function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
}

export type Name = {
    firstName?: string;
    lastName?: string;
};

export function fullName(name: Name | undefined): string {
    if (!name) return "";
    return `${name.firstName ?? ""} ${name.lastName ?? ""}`.trim();
}
