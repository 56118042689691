import Confirmation, { ConfirmationContext } from "../shared/Confirmation";
import { useState } from "react";

export function AddLinkModal({
    show,
    initialText,
    cancel,
    submit,
}: {
    show: boolean;
    initialText: string;
    cancel: () => void;
    submit: (text: string, link: string) => void;
}) {
    const [text, setText] = useState(initialText ?? "");
    const [link, setLink] = useState("");
    return (
        <ConfirmationContext.Provider value={[show, () => cancel()]}>
            <Confirmation title={"Add Link"} onDismiss={cancel}>
                <Confirmation.Body>
                    <form
                        className="add-link-form"
                        onSubmit={e => {
                            if (text && link) {
                                submit(text, link);
                            }
                            e.preventDefault();
                            return false;
                        }}
                    >
                        <label>Text</label>
                        <input
                            autoFocus={!initialText.length}
                            type="text"
                            value={text}
                            onChange={e => setText(e.target.value)}
                        />
                        <br />
                        <label>Link</label>
                        <input
                            autoFocus={!!initialText.length}
                            type="text"
                            onChange={e => setLink(e.target.value)}
                        />
                        <input type="submit" className="submit" />
                    </form>
                </Confirmation.Body>
                <Confirmation.Button onClick={cancel} label={"Cancel"} />
                <Confirmation.Button
                    onClick={() => submit(text, link)}
                    label={"Save"}
                    isPrimary
                    disabled={!text || !link}
                />
            </Confirmation>
        </ConfirmationContext.Provider>
    );
}
