import { Feedback } from "data/feedback";
import { format } from "date-fns";

export function AdminFeedbackRow({ feedback }: { feedback: Feedback }) {
    const title: string = (() => {
        switch (feedback.type) {
            case "session":
            case "series":
                return "Post-session feedback";
            case "app":
                return "App feedback (post-session)";
            case "nps":
                return "NPS rating";
            case "experience":
                return "Overall experience satisfaction";
        }
    })();
    return (
        <li className="feedback-row">
            <div className="primary">{format(feedback.createdAt, "EEE MMM do, h:mm bb")}</div>
            <span className="secondary">{title}</span>
            <div className="secondary">
                Rating: {feedback.rating} / {feedback.maxRating}
            </div>
            <span className="secondary">{feedback.description}</span>
        </li>
    );
}
