import {
    AthleteCoachingStatus,
    PartialUser,
    SearchContext,
    SearchUser,
    SortKey,
    useSearchState,
} from "data/search";
import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    HeaderGroup,
    Row,
    SortDirection,
    useReactTable,
} from "@tanstack/react-table";
import { formatISO } from "date-fns";
import { coachingTypeText, fullName } from "../../util";
import { useSearchParams } from "react-router-dom";
import "./AdminSearch.css";
import { Link } from "react-router-dom";
import { CoachingType } from "data/assignment";
import { User } from "data/user";
import { RelationshipInvitation } from "data/invitation";

function HeaderGroupView({ headerGroup }: { headerGroup: HeaderGroup<SearchUser> }) {
    const [search, dispatch] = useContext(SearchContext);
    return (
        <tr key={headerGroup.id}>
            <th></th>
            {headerGroup.headers.map(header => {
                return (
                    <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                            <div
                                {...{
                                    className: "cursor-pointer select-none column-header",
                                    onClick: () => {
                                        const oldKey = search.sortKey;
                                        const newKey = (header.column.columnDef as any)["sortKey"];
                                        const order: SortDirection | undefined = (() => {
                                            if (oldKey !== newKey) return "asc";
                                            switch (search.sortOrder) {
                                                case "asc":
                                                    return "desc";
                                                case "desc":
                                                    return undefined;
                                                case undefined:
                                                    return "asc";
                                            }
                                        })();
                                        dispatch({ type: "sort", key: newKey, order });
                                    },
                                }}
                            >
                                {search.sortKey === (header.column.columnDef as any)["sortKey"] &&
                                    search.sortOrder &&
                                    {
                                        asc: "↑ ",
                                        desc: "↓ ",
                                    }[search.sortOrder]}
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </div>
                        )}
                    </th>
                );
            })}
        </tr>
    );
}

function path(user: SearchUser): string {
    switch (user.type) {
        case "admin":
            return `admins/${user.id}`;
        case "athlete":
            return `athletes/${user.athleteDetails?.id}`;
        case "coach":
            return `coaches/${user.coachDetails?.id}`;
        case "parent":
            return `parents/${user.id}`;
    }
}

function athleteName(athlete: {
    user?: PartialUser;
    relationshipInvitations: RelationshipInvitation[];
}): string {
    const invite = athlete.relationshipInvitations
        .filter(i => i.inviteeType === "athlete")
        .filter(i => !!i.firstName || !!i.lastName)[0];
    if (athlete.user) {
        return fullName(athlete.user);
    } else if (invite) {
        return `[${fullName(invite) ?? "Invited"}]`;
    } else {
        return "[Invited]";
    }
}

function RowView({ row, order }: { row: Row<SearchUser>; order: number }) {
    return (
        <>
            <tr>
                <td colSpan={100}>
                    <hr />
                </td>
            </tr>
            <tr key={row.id}>
                <td>{order}.</td>
                {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                        <Link to={`/admin/${path(row.original)}`} target="_blank">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Link>
                    </td>
                ))}
            </tr>
            <tr className="result-attr">
                <td></td>
                <td colSpan={100}>
                    {row.original.coachDetails?.coachingAssignments.map((a, i) => (
                        <>
                            {i === 0 ? "Athletes: " : ", "}
                            <Link to={`/admin/athletes/${a.athlete.id}`} target="_blank">
                                {athleteName(a.athlete)}
                            </Link>
                        </>
                    ))}
                </td>
            </tr>
            {row.original.athleteDetails?.coachingAssignments.map(a => (
                <tr key={a.id} className="result-attr">
                    <td></td>
                    <td colSpan={100}>
                        <Link to={`/admin/coaches/${a.coach.id}`} target="_blank">
                            {coachingTypeText(a.coachingType)} Coach: {fullName(a.coach.user)}
                        </Link>
                    </td>
                </tr>
            ))}
            {row.original.parents.map(p => (
                <tr key={p.parent.id} className="result-attr">
                    <td></td>
                    <td colSpan={100}>
                        <Link to={`/admin/parents/${p.parent.id}`} target="_blank">
                            Parent: {fullName(p.parent)}
                        </Link>
                    </td>
                </tr>
            ))}
            {row.original.children.map(c => (
                <tr key={c.child.id} className="result-attr">
                    <td></td>
                    <td colSpan={100}>
                        <Link to={`/admin/athletes/${c.child.athleteDetails?.id}`} target="_blank">
                            Child: {fullName(c.child)}
                        </Link>
                    </td>
                </tr>
            ))}
            {["parent", "athlete"].includes(row.original.type) &&
                row.original.createdInvitations
                    .filter(i => !i.accepterId)
                    .map(i => (
                        <tr key={i.code} className="result-attr">
                            <td></td>
                            <td colSpan={100}>
                                {(() => {
                                    switch (i.inviteeType) {
                                        case "athlete":
                                            return (
                                                <Link to={`/admin/athletes/${i.athleteId}`}>
                                                    {`Invited Child: [${fullName(i) || "No Name"}]`}
                                                </Link>
                                            );
                                        case "parent":
                                            return (
                                                <>{`Invited Parent: [${
                                                    fullName(i) || "No Name"
                                                }]`}</>
                                            );
                                    }
                                })()}
                            </td>
                        </tr>
                    ))}
            {row.original._count.skillCompletions > 0 && (
                <tr key={`${row.original.id}-skills`} className="result-attr">
                    <td></td>
                    <td colSpan={100}>Skill Completions: {row.original._count.skillCompletions}</td>
                </tr>
            )}
            {row.original._count.courseCompletions > 0 && (
                <tr key={`${row.original.id}-courses`} className="result-attr">
                    <td></td>
                    <td colSpan={100}>
                        Course Step Completions: {row.original._count.courseCompletions}
                    </td>
                </tr>
            )}
        </>
    );
}

function PageIndex() {
    const [search, dispatch] = useContext(SearchContext);
    const pageCount = Math.ceil(search.totalCount / search.pageSize);
    return (
        <div>
            <button
                onClick={() => dispatch({ type: "setPageIndex", index: 0 })}
                disabled={search.pageIndex === 0}
            >
                {"<<"}
            </button>
            <button
                onClick={() => dispatch({ type: "decrementPage" })}
                disabled={search.pageIndex === 0}
            >
                {"<"}
            </button>
            <strong className="page-progress">
                {search.pageIndex + 1} of {pageCount}
            </strong>
            <button
                onClick={() => dispatch({ type: "incrementPage" })}
                disabled={search.pageIndex + 1 >= pageCount}
            >
                {">"}
            </button>
            <button
                onClick={() => dispatch({ type: "setPageIndex", index: pageCount - 1 })}
                disabled={search.pageIndex + 1 >= pageCount}
            >
                {">>"}
            </button>
        </div>
    );
}

function PageSize() {
    const [search, dispatch] = useContext(SearchContext);
    return (
        <div>
            {"Showing "}
            <select
                value={search.pageSize}
                onChange={e => {
                    dispatch({ type: "setPageSize", size: Number(e.target.value) });
                }}
            >
                {Array.from(new Set([search.pageSize, 10, 25, 50].map(_.toInteger)))
                    .sort()
                    .map(size => (
                        <option key={size} value={size}>
                            {size}
                        </option>
                    ))}
            </select>
            {` `}
            users of {search.totalCount} total
        </div>
    );
}

function Table() {
    const [search] = useContext(SearchContext);
    const data = useMemo(() => search.results, [search.results]);
    const columns: ColumnDef<SearchUser>[] = useMemo(
        () => [
            { header: "First Name", accessorKey: "firstName", sortKey: SortKey.firstName },
            { header: "Last Name", accessorKey: "lastName", sortKey: SortKey.lastName },
            { header: "Type", accessorKey: "type", sortKey: SortKey.type },
            { header: "Email", accessorKey: "email", sortKey: SortKey.email },
            {
                header: "Created",
                accessorFn: user => formatISO(user.createdAt),
                sortKey: SortKey.createdAt,
            },
        ],
        [],
    );
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    return (
        <table className="m-4">
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <HeaderGroupView key={headerGroup.id} headerGroup={headerGroup} />
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row, index) => (
                    <RowView key={row.id} row={row} order={index + 1 + search.pageIndex * 10} />
                ))}
            </tbody>
        </table>
    );
}

function UserTypeFilter() {
    const [{ types = [] }, dispatch] = useContext(SearchContext);
    const onChange = useCallback(
        event => {
            const _types = event.target.checked
                ? Array.from(new Set(types.concat(event.target.value)))
                : types.filter(d => d !== event.target.value);
            dispatch({ type: "config", config: { types: _types } });
        },
        [types, dispatch],
    );
    return (
        <fieldset>
            <strong>User Type</strong>
            {["admin", "coach", "parent", "athlete"].map(d => (
                <label key={d}>
                    <input
                        type="checkbox"
                        value={d}
                        name="userType"
                        onChange={onChange}
                        checked={types.includes(d as User["type"])}
                    />
                    {` ${d}`}
                </label>
            ))}
        </fieldset>
    );
}

function CoachingStatusFilter() {
    const [{ coachingStatus = [] }, dispatch] = useContext(SearchContext);

    const onChange = useCallback(
        event => {
            const status = event.target.checked
                ? Array.from(new Set(coachingStatus.concat(event.target.value)))
                : coachingStatus.filter(d => d !== event.target.value);
            dispatch({ type: "config", config: { coachingStatus: status } });
        },
        [coachingStatus, dispatch],
    );
    return (
        <fieldset>
            <strong>Coaching Status</strong>
            {["active", "churned", "suspended"].map(d => (
                <label key={d}>
                    <input
                        type="checkbox"
                        value={d}
                        name="coachingStatus"
                        onChange={onChange}
                        checked={coachingStatus.includes(d as AthleteCoachingStatus)}
                    />
                    {` ${d}`}
                </label>
            ))}
        </fieldset>
    );
}

function DisciplineFilter() {
    const [{ disciplines = [] }, dispatch] = useContext(SearchContext);
    const isChecked = useCallback(
        (discipline: string): boolean => {
            if (disciplines.length === 0) {
                return false;
            } else if (disciplines.length === 1) {
                return disciplines[0] === discipline;
            } else {
                return discipline === "all";
            }
        },
        [disciplines],
    );
    const onChange = useCallback(
        event => {
            const _disciplines: CoachingType[] | undefined = (() => {
                switch (event.target.value) {
                    case "all":
                        return ["mindset", "nutrition"];
                    default:
                        return [event.target.value];
                }
            })();
            dispatch({ type: "config", config: { disciplines: _disciplines } });
        },
        [dispatch],
    );
    const onDisable = useCallback(
        () => dispatch({ type: "config", config: { disciplines: [] } }),
        [dispatch],
    );
    return (
        <fieldset>
            <strong>
                Disciplines{" "}
                {disciplines.length > 0 && <input type="checkbox" checked onChange={onDisable} />}
            </strong>
            {["mindset", "nutrition", "all"].map(d => (
                <label key={d}>
                    <input
                        type="radio"
                        value={d}
                        name="discipline"
                        onChange={onChange}
                        checked={isChecked(d)}
                    />
                    {coachingTypeText(d as CoachingType) ?? d}
                </label>
            ))}
        </fieldset>
    );
}

function OnDemandFilter() {
    const [{ hasOnDemandCompletions }, dispatch] = useContext(SearchContext);
    const onDisable = useCallback(
        () => dispatch({ type: "config", config: { hasOnDemandCompletions: undefined } }),
        [dispatch],
    );
    const onChange = useCallback(
        event => {
            dispatch({
                type: "config",
                config: { hasOnDemandCompletions: event.target.value === "true" },
            });
        },
        [dispatch],
    );
    return (
        <fieldset>
            <strong>
                On Demand{" "}
                {hasOnDemandCompletions !== undefined && (
                    <input type="checkbox" checked onChange={onDisable} />
                )}
            </strong>
            <label>
                <input
                    type="radio"
                    value="true"
                    name="onDemand"
                    onChange={onChange}
                    checked={!!hasOnDemandCompletions}
                />
                Active
            </label>
            <label>
                <input
                    type="radio"
                    value="false"
                    name="onDemand"
                    onChange={onChange}
                    checked={hasOnDemandCompletions !== undefined && !hasOnDemandCompletions}
                />
                Inactive
            </label>
            <small>
                <small>
                    <i>*based on app usage</i>
                </small>
            </small>
        </fieldset>
    );
}

function TestUserFilter() {
    const [{ test }, dispatch] = useContext(SearchContext);
    const onChange = useCallback(
        event => {
            if (event.target.checked) {
                dispatch({ type: "config", config: { test: true } });
            } else {
                dispatch({ type: "config", config: { test: undefined } });
            }
        },
        [dispatch],
    );
    return (
        <label>
            <input type="checkbox" checked={!!test} onChange={onChange} /> including test users
        </label>
    );
}

function Content() {
    const [{ query, results }] = useContext(SearchContext);
    if (results.length > 0) {
        return (
            <div className="d-flex flex-col align-items-center">
                <Table />
                <div className="h-2" />
                <PageIndex />
            </div>
        );
    } else if (query.length > 0) {
        return <div className="">No results for "{query}"</div>;
    } else {
        return <div className="">Enter a query to get started</div>;
    }
}

export function AdminSearch() {
    const [params] = useSearchParams();
    const pageIndex = params.get("pageIndex");
    const pageSize = params.get("pageSize");
    const config = _({
        query: params.get("query"),
        sortKey: params.get("sortKey"),
        sortOrder: params.get("sortOrder"),
        pageSize: pageSize ? parseInt(pageSize, 10) : null,
        pageIndex: pageIndex ? parseInt(pageIndex, 10) : null,
        disciplines: params.getAll("disciplines"),
        types: params.getAll("types"),
        hasInterests: params.get("hasInterests")
            ? params.get("hasInterests") === "true"
            : undefined,
        hasAssignment: params.get("hasAssignment")
            ? params.get("hasAssignment") === "true"
            : undefined,
        hasCompletions: params.get("hasCompletions")
            ? params.get("hasCompletions") === "true"
            : undefined,
        test: params.get("test") ? params.get("test") === "true" : undefined,
    })
        .pickBy(_.identity)
        .value();
    const context = useSearchState(config);
    const [, dispatch] = context;
    useEffect(() => {
        dispatch({ type: "init" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const debouncedSearch = _.debounce(
        (query: string) => dispatch({ type: "query", query: query }),
        500,
    );

    return (
        <div className="d-flex flex-col align-items-center admin-search">
            <SearchContext.Provider value={context}>
                <div className="d-flex flex-col align-items-left">
                    <div className="d-flex flex-row align-items-start">
                        <div className="d-flex flex-col align-items-start search-bar">
                            <input
                                autoFocus
                                className="p-4"
                                type="text"
                                onChange={e => debouncedSearch(e.target.value)}
                                placeholder="Search"
                            ></input>
                            <PageSize />
                            <TestUserFilter />
                        </div>
                        <div>
                            <UserTypeFilter />
                            <CoachingStatusFilter />
                            <DisciplineFilter />
                            <OnDemandFilter />
                        </div>
                    </div>
                </div>
                <Content />
            </SearchContext.Provider>
        </div>
    );
}
