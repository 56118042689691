import { constructActivationCodeDeepLink } from "components/DeepLink";
import { Modal } from "components/shared/Modal";
import { ActivationCode, ActivationCodeUsage, AdminContext } from "data/admin";
import { User } from "data/user";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { copyToClipboard, fullName } from "util/index";
import { Plan, planTitle } from "../form/AdminActivationCodeForm";
import styles from "./AdminActivationCodeList.module.css";

type ActivationCodeListProps = { activationCodes: ActivationCode[] };

export function ActivationCodeList({ activationCodes }: ActivationCodeListProps) {
    const [, dispatch] = useContext(AdminContext);
    const [editCode, setEditCode] = useState<ActivationCode | undefined>(undefined);
    const [deleteCode, setDeleteCode] = useState<ActivationCode | undefined>(undefined);
    const [copiedCode, setCopiedCode] = useState<string>();
    const copyLink = (code: ActivationCode) => {
        const link = constructActivationCodeDeepLink(code);
        copyToClipboard(link);
        setCopiedCode(code.code);
    };

    const editModal = (
        <Modal show={!!editCode} hide={() => setEditCode(undefined)}>
            {editCode ? (
                <UpdateActivationCodeForm
                    code={editCode}
                    onComplete={() => setEditCode(undefined)}
                />
            ) : (
                <></>
            )}
        </Modal>
    );

    const deleteModal = (
        <Modal show={!!deleteCode} hide={() => setDeleteCode(undefined)}>
            <div className={styles["container"]}>
                <h1>{`Are you sure you want to delete this code?`}</h1>
                <p>
                    <b>WARNING: this action cannot be undone.</b>
                </p>
                <button
                    type="button"
                    className="pill pill-destructive"
                    onClick={() => {
                        try {
                            dispatch({
                                type: "delete_activation_code",
                                code: deleteCode!.code,
                            });
                        } catch (e) {
                            alert(`Error when deleting code: ${e}`);
                        }
                        setDeleteCode(undefined);
                    }}
                >
                    DELETE
                </button>
            </div>
        </Modal>
    );

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <td>Created </td>
                        <td>Code</td>
                        <td>Products</td>
                        <td>Max Athletes</td>
                        <td>Source</td>
                        <td>Description</td>
                        <td>Usages</td>
                        <td>Pending Athletes</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {activationCodes
                        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                        .map(code => (
                            <tr key={code.code}>
                                <td>{code.createdAt.toLocaleDateString()}</td>
                                <td>{code.code}</td>
                                <td>
                                    {code.plansForCode
                                        ?.map(c => planTitle(c.planId as Plan))
                                        .join(", ") ?? "N/A"}
                                </td>
                                <td>{code.maxAthletes}</td>
                                <td>{code.source}</td>
                                <td>{code.description ?? "N/A"}</td>
                                <td>
                                    <UserUsages usages={code.usages ?? []} />
                                </td>
                                <td>
                                    <PendingUsers users={code.pendingUsers ?? []} />
                                </td>
                                <td>
                                    <div className="d-flex flex-col" style={{ gap: "0.5rem" }}>
                                        <button className="pill" onClick={() => setEditCode(code)}>
                                            Edit
                                        </button>
                                        <button className="pill" onClick={() => copyLink(code)}>
                                            {code.code === copiedCode ? `Copied!` : `Copy Link`}
                                        </button>
                                        {code.usages?.length === 0 && (
                                            <button
                                                className="pill pill-destructive"
                                                onClick={() => setDeleteCode(code)}
                                            >
                                                Delete
                                            </button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {editModal}
            {deleteModal}
        </div>
    );
}

function PendingUsers({ users }: { users: User[] }) {
    return (
        <>
            {users.map(u => (
                <div key={u.id}>
                    <Link to={`/admin/athletes/${u.athleteDetails?.id}`}>{fullName(u)}</Link>
                </div>
            ))}
        </>
    );
}
function UserUsages({ usages }: { usages: ActivationCodeUsage[] }) {
    function adminLink(user: User): string {
        switch (user.type) {
            case "athlete":
                return `/admin/athletes/${user.athleteDetails?.id}`;
            case "parent":
                return `/admin/parents/${user.id}`;
            default:
                return "";
        }
    }

    return (
        <>
            {usages.map(u => {
                if (u.user === undefined) return <></>;
                return (
                    <div key={`${u.code}${u.user.id}`}>
                        <Link className={styles[`usage-link`]} to={adminLink(u.user)}>
                            {fullName(u.user)} ({u.user.type}) - used{" "}
                            {u.createdAt.toLocaleDateString()}
                        </Link>
                    </div>
                );
            })}
        </>
    );
}

type FormState = {
    description: string;
    maxAthletes: number;
};

function UpdateActivationCodeForm({
    code,
    onComplete,
}: {
    code: ActivationCode;
    onComplete: () => void;
}) {
    const [, dispatch] = useContext(AdminContext);
    const [state, setState] = useState<FormState>({
        description: code.description ?? "",
        maxAthletes: code.maxAthletes,
    });

    const onSubmit = async () => {
        await dispatch({
            type: "update_activation_code",
            code: code.code,
            maxAthletes: state.maxAthletes,
            description: state.description,
        });
        onComplete();
    };

    return (
        <div className={styles["container"]}>
            <h3>Edit Activation Code</h3>
            <div className={styles["form-group"]}>
                <label htmlFor="maxAthletes">Max Athletes</label>
                <input
                    type="number"
                    min={0}
                    name="maxAthletes"
                    value={state.maxAthletes}
                    onChange={e => {
                        setState(state => ({
                            ...state,
                            maxAthletes: parseInt(e.target.value),
                        }));
                    }}
                />
            </div>
            <div className={styles["form-group"]}>
                <label htmlFor="description">Description</label>
                <textarea
                    name="description"
                    rows={5}
                    cols={50}
                    value={state.description}
                    onChange={e => {
                        setState(state => ({ ...state, description: e.target.value }));
                    }}
                />
            </div>
            <button className="pill" onClick={onSubmit}>
                Submit
            </button>
        </div>
    );
}
