import "./Footer.css";

export function Footer() {
    return (
        <div className="footer">
            <a href="https://www.imgacademy.com/terms-service">Terms</a>|
            <a href="https://www.imgacademy.com/privacy-policy">Privacy</a>
        </div>
    );
}
