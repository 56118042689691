import { EditorContent, useEditor } from "@tiptap/react";
import {
    extensions,
    focusEditor,
    humanReadable,
    noteIsPrivate,
    Note,
    NotesContext,
    useAthleteNotes,
} from "data/note";
import { useContext, useEffect, useState } from "react";
import "./NoteList.css";
import "./NoteComposer.css";
import * as hash from "object-hash";
import format from "date-fns/format";
import Popover, { PopoverContext } from "components/shared/Popover";
import Confirmation, { ConfirmationContext } from "components/shared/Confirmation";
import { presentation } from "./NoteTopicsInput";
import { NotePreview } from "./NotePreview";
import { NoteComposer } from "./NoteComposer";
import { AttachmentsRow } from "./NoteAttachment";
import { useCoachData } from "data/coach";
import { fullName } from "../../util";

function DeleteConfirmation({ note }: { note: Note }) {
    const [, dispatch] = useContext(NotesContext);
    const [, setIsVisible] = useContext(ConfirmationContext);
    return (
        <Confirmation title={`Are you sure you want to delete ${note.title}?`}>
            <Confirmation.Body>
                <p>
                    {noteIsPrivate(note)
                        ? "Once deleted, this note will be permanently removed from your notes."
                        : "Once deleted, this shared note will be removed for everyone you originally shared it with."}
                </p>
            </Confirmation.Body>
            <Confirmation.Button label="Cancel" onClick={() => setIsVisible(false)} />
            <Confirmation.Button
                label="Delete"
                onClick={() => dispatch({ type: "delete_note", id: note.id })}
                isDestructive
            />
        </Confirmation>
    );
}

export function NoteEntry({ note, allowActions }: { note: Note; allowActions?: boolean }) {
    const [{ editPrivateNoteDrafts }] = useContext(NotesContext);
    const editor = useEditor({
        extensions,
        content: note.body,
        editable: false,
    });
    const noteActions = useState(false);
    const draft = editPrivateNoteDrafts[note.id];
    const showActionMenu = allowActions ?? true;
    if (draft) {
        return <NoteComposer sharees={[]} editingNoteId={note.id} />;
    }
    return (
        <div className="note-entry">
            <div className="note-entry-header">
                <div className="note-entry-info">
                    <h3>{note.title}</h3>
                    {note.assignment?.coach.user && (
                        <h5>By: {fullName(note.assignment?.coach.user)}</h5>
                    )}
                    {!noteIsPrivate(note) && (
                        <h5>
                            Shared with{" "}
                            {note.access
                                .map(a => {
                                    if (a.createdAt) {
                                        return `${fullName(a.user)} on ${format(
                                            a.createdAt,
                                            "M/dd/yy",
                                        )}`;
                                    }
                                    return fullName(a.user);
                                })
                                .join(", ")}
                        </h5>
                    )}
                    {note.topics.size > 0 && (
                        <div className="note-topics">
                            <h4>Topics Covered</h4>
                            <ul className="no-li-style">
                                {[...note.topics].map(topic => (
                                    <li key={topic} className="note-topic">
                                        {presentation(topic)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                {showActionMenu && (
                    <PopoverContext.Provider value={noteActions}>
                        <NoteActions note={note} />
                    </PopoverContext.Provider>
                )}
            </div>
            <div className="note-body-container">
                {note.attachments.length > 0 && <AttachmentsRow attachments={note.attachments} />}
                <EditorContent editor={editor} />
            </div>
            {noteIsPrivate(note) && (
                <span className="note-timestamp">{`Last saved ${humanReadable(
                    note.updatedAt,
                )}`}</span>
            )}
        </div>
    );
}

function NoteActions({ note }: { note: Note }) {
    const [, dispatch] = useContext(NotesContext);
    const [, setOpen] = useContext(PopoverContext);
    const deleteConfirmation = useState(false);
    const [, setDeleteConfirmationVisible] = deleteConfirmation;
    return (
        <>
            <ConfirmationContext.Provider value={deleteConfirmation}>
                <DeleteConfirmation note={note} />
            </ConfirmationContext.Provider>
            <Popover>
                <Popover.Toggle>
                    <img
                        style={{ transform: "rotate(90deg)" }}
                        alt="session options"
                        src="/menu.svg"
                        className="svg-tint-0F2B5B"
                    />
                </Popover.Toggle>
                <Popover.Content>
                    <div className="popover-basic-menu">
                        {noteIsPrivate(note) && (
                            <button
                                onClick={() => {
                                    dispatch({ type: "edit_note", id: note.id });
                                    setOpen(false);
                                    focusEditor(note.id);
                                }}
                            >
                                Edit Note
                            </button>
                        )}
                        <button
                            className="delete-note"
                            onClick={() => {
                                setOpen(false);
                                setDeleteConfirmationVisible(true);
                            }}
                        >
                            Delete Note
                        </button>
                    </div>
                </Popover.Content>
            </Popover>
        </>
    );
}

function Entries({ notes, allowActions }: { notes: Note[]; allowActions?: boolean }) {
    return (
        <div className="notes-list">
            {notes
                .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                .map(d => (
                    <NoteEntry
                        note={d}
                        key={`${d.id}-${hash.MD5(d)}`}
                        allowActions={allowActions}
                    />
                ))}
        </div>
    );
}

export function PrivateNotesList({
    assignmentId,
    coachUserId,
}: {
    assignmentId: string;
    coachUserId: string;
}) {
    const [state, dispatch] = useContext(NotesContext);
    const notes = Object.values(state.notes).filter(noteIsPrivate);
    const coachState = useCoachData(coachUserId);
    const athleteState = coachState.athletes.find(a => a.assignmentId === assignmentId);
    const athleteId = athleteState?.assignment.athleteId;
    const discipline = athleteState?.discipline;
    const { data: athleteNotes = [] } = useAthleteNotes(athleteId!, discipline!, {
        enabled: !!athleteId && !!discipline,
    });
    const previousPrivateNotes = athleteNotes
        .filter(n => n.assignmentId !== assignmentId)
        .filter(noteIsPrivate);

    useEffect(() => {
        dispatch({ type: "refresh" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentNotes =
        notes.length === 0 && state.newPrivateNoteDraft === undefined ? (
            <p className="empty-notes">
                This is a space to create and review your own notes.
                <br />
                <br />
                You don't have any private notes yet. Select "Create Private Note" above to get
                started.
            </p>
        ) : (
            <Entries notes={notes} />
        );
    return (
        <>
            {currentNotes}
            <PreviousAssignmentNotes notes={previousPrivateNotes} />
        </>
    );
}

export function SharedNotesList({
    assignmentId,
    coachUserId,
}: {
    assignmentId: string;
    coachUserId: string;
}) {
    const coachState = useCoachData(coachUserId);
    // const { data: notes } = useQueryNotes(assignmentId);
    const [state, dispatch] = useContext(NotesContext);
    const preview = useState(false);
    const [, setPreviewIsVisible] = preview;
    const sharedNotes = Object.values(state.notes ?? []).filter(n => !noteIsPrivate(n));

    const athleteState = coachState.athletes.find(a => a.assignmentId === assignmentId);
    const athleteId = athleteState?.assignment.athleteId;
    const discipline = athleteState?.discipline;
    const { data: athleteNotes = [] } = useAthleteNotes(athleteId!, discipline!, {
        enabled: !!athleteId && !!discipline,
    });
    const previousSharedNotes = athleteNotes
        .filter(n => n.assignmentId !== assignmentId)
        .filter(n => !noteIsPrivate(n));

    useEffect(() => {
        dispatch({ type: "refresh" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentNotes =
        sharedNotes.length === 0 && state.newSharedNoteDraft === undefined ? (
            <>
                <ConfirmationContext.Provider value={preview}>
                    <NotePreview />
                </ConfirmationContext.Provider>
                <p className="empty-notes">
                    This is a space to create and share notes with your athlete and their parent(s).
                    <br />
                    <br />
                    You haven't shared anything yet. Select "Create Shared Note" above to get
                    started.
                    <br />
                    <br />
                    <br />
                    Want to know what a shared note looks like for your athlete and their parent(s)?
                    <button className="shared-preview" onClick={() => setPreviewIsVisible(true)}>
                        Preview it here
                    </button>
                </p>
            </>
        ) : (
            <Entries notes={sharedNotes} />
        );

    return (
        <>
            {currentNotes}
            <PreviousAssignmentNotes notes={previousSharedNotes} />
        </>
    );
}

export function PreviousAssignmentNotes({ notes }: { notes: Note[] }) {
    return notes.length > 0 ? (
        <div className="previous-notes-container">
            <hr />
            <div className="title">Previous Notes</div>
            <Entries notes={notes} allowActions={false} />
        </div>
    ) : (
        <></>
    );
}
