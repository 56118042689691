import { Context, createContext, useCallback, useEffect, useState } from "react";

export type NotificationState = {
    permission: NotificationPermission;
    enabled: boolean;
};

type SetEnabledAction = {
    type: "set_enabled";
    enabled: boolean;
};

type ShowNotificationAction = {
    type: "show_notification";
    text: string;
    onclick?: () => void;
};

export type NotificationAction = SetEnabledAction | ShowNotificationAction;

const initialState: NotificationState = {
    permission: "default",
    enabled: false,
};

export function notificationsSupported(): boolean {
    return !!window.Notification;
}

export function useNotifications(): [NotificationState, (action: NotificationAction) => void] {
    const [state, setState] = useState(initialState);
    useEffect(() => {
        setState({
            permission: notificationsSupported() ? Notification.permission : "denied",
            enabled: localStorage.getItem("notifications-enabled") === "true",
        });
    }, []);
    const dispatch = useCallback(
        async (action: NotificationAction) => {
            switch (action.type) {
                case "set_enabled":
                    if (action.enabled && state.permission !== "granted") {
                        try {
                            const permission = await Notification.requestPermission();
                            localStorage.setItem("notifications-enabled", "true");
                            setState({ permission, enabled: true });
                        } catch {
                            // Safari still uses the callback API
                            Notification.requestPermission(permission => {
                                localStorage.setItem("notifications-enabled", "true");
                                setState({ permission, enabled: true });
                            });
                        }
                    } else {
                        localStorage.setItem(
                            "notifications-enabled",
                            action.enabled ? "true" : "false",
                        );
                        setState(state => ({ ...state, enabled: action.enabled }));
                    }
                    break;
                case "show_notification":
                    if (state.permission === "granted" && state.enabled) {
                        const audio = new Audio("/notification.mp3");
                        audio.play().then();
                        if (document.visibilityState === "hidden") {
                            const notification = new Notification(action.text, { silent: true });
                            if (action.onclick) {
                                notification.onclick = action.onclick;
                            }
                            document.addEventListener("visibilitychange", function () {
                                if (document.visibilityState === "visible") {
                                    notification.close();
                                }
                            });
                        }
                    }
            }
        },
        [state],
    );
    return [state, dispatch];
}

export const NotificationContext: Context<
    [NotificationState, (action: NotificationAction) => void]
> = createContext([initialState, _ => {}]);
