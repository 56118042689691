import Confirmation, { ConfirmationContext } from "components/shared/Confirmation";
import { accessMedia } from "data/avatar";
import { NotesContext } from "data/note";
import { NoteAttachment } from "data/note_attachment";
import { useContext, useState } from "react";

export function AttachmentsRow({ attachments }: { attachments: NoteAttachment[] }) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [, dispatch] = useContext(NotesContext);
    const [attachmentToDelete, setAttachmentToDelete] = useState<NoteAttachment | undefined>(
        undefined,
    );
    async function onOpen(attachment: NoteAttachment) {
        const url = await accessMedia(attachment.mediaId);
        window.open(url);
    }

    async function onDelete() {
        if (!attachmentToDelete) return;
        await dispatch({ type: "delete_note_attachment", attachment: attachmentToDelete });
        onDismiss();
    }

    const onDismiss = () => {
        setShowModal(false);
        setAttachmentToDelete(undefined);
    };

    const modal = (
        <ConfirmationContext.Provider value={[showModal, setShowModal]}>
            <Confirmation
                title={`Are you sure you want to remove ${attachmentToDelete?.name}`}
                onDismiss={onDismiss}
            >
                <Confirmation.Body>
                    <p>
                        All recipients of this shared note will no longer have access to the
                        document.
                    </p>
                </Confirmation.Body>
                <Confirmation.Button label="Cancel" onClick={onDismiss} />
                <Confirmation.Button label="Remove" onClick={onDelete} isDestructive />
            </Confirmation>
        </ConfirmationContext.Provider>
    );

    return (
        <div className="uploaded-note-attachments">
            {attachments.map(attachment => (
                <AttachmentButton
                    key={attachment.id}
                    fileName={attachment.name}
                    onOpen={() => onOpen(attachment)}
                    onRemove={() => {
                        setAttachmentToDelete(attachment);
                        setShowModal(true);
                    }}
                />
            ))}
            {modal}
        </div>
    );
}

export function UploadAttachmentsRow({
    attachments,
    onRemove,
}: {
    attachments: File[];
    onRemove: (file: File) => void;
}) {
    return (
        <div className="note-attachments">
            {attachments.map(attachment => (
                <AttachmentButton
                    key={attachment.name}
                    fileName={attachment.name}
                    onRemove={() => onRemove(attachment)}
                />
            ))}
        </div>
    );
}

function AttachmentButton({
    fileName,
    onRemove,
    onOpen,
}: {
    fileName: string;
    onRemove?: () => void;
    onOpen?: () => void;
}) {
    return (
        <div className="note-attachment-cell">
            <img src="/file-document.svg" alt="attachment" />
            {onOpen !== undefined ? (
                <span style={{ cursor: "pointer" }} onClick={onOpen}>
                    {fileName}
                </span>
            ) : (
                <span>{fileName}</span>
            )}
            {onRemove !== undefined && (
                <img src="/x.svg" alt="remove attachment" onClick={onRemove} />
            )}
        </div>
    );
}
