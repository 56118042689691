import { useCallback, useEffect, useState } from "react";
import { getAdminUnmatchedAthletes, UserOnDemandStats } from "../../../data/admin";
import { Link } from "react-router-dom";
import { UserAvatarRow } from "../../shared/AvatarRow";
import { coachingTypeText, fullName } from "../../../util";
import { User } from "data/user";
import { CoachingAssignment } from "data/assignment";
import "./AdminUnmatchedAthleteList.css";
import { AthleteSubscriptionEvent } from "data/subscription-event";
import { RelationshipInvitation } from "../../../data/invitation";
import _ from "lodash";

type AssignmentWithCoach = { assignment: CoachingAssignment; coach: User | undefined };

export type AthleteRowProps = {
    athlete?: User;
    invitation?: RelationshipInvitation;
    parents?: User[];
    assignmentsWithCoach?: AssignmentWithCoach[];
    churnedEvents?: AthleteSubscriptionEvent[];
    onDemandStats?: UserOnDemandStats;
};

export type AthleteRowFilter = (a: AthleteRowProps) => boolean;

export function includeTestUser(showTestUser: boolean): AthleteRowFilter {
    if (showTestUser) {
        return () => true;
    }
    return row => {
        if (row.athlete) {
            return !row.athlete.adminTestUser;
        }
        return !(_.first(row.parents)?.adminTestUser ?? false);
    };
}

export function OnDemandStats(onDemandStats: UserOnDemandStats) {
    return (
        <>
            <br />
            <li>Course step completions: {onDemandStats.courseActions}</li>
            <li>Skill completions: {onDemandStats.skillCompletions}</li>
        </>
    );
}

export function PrivateCoachingAthleteRow({
    athlete,
    invitation,
    parents,
    assignmentsWithCoach,
    churnedEvents,
    onDemandStats,
}: AthleteRowProps) {
    function contentText(): string | JSX.Element {
        return (
            <div>
                <ul>
                    {assignmentsWithCoach?.map(assignmentWithCoach => (
                        <li key={assignmentWithCoach.assignment.id}>
                            {coachingTypeText(assignmentWithCoach.assignment.coachingType)} Coach:{" "}
                            {fullName(assignmentWithCoach.coach)}
                        </li>
                    ))}
                    {parents?.map(parent => (
                        <li key={parent.id}>Parent: {fullName(parent)}</li>
                    ))}
                    {churnedEvents && churnedEvents.length > 0 ? (
                        <>
                            <br />
                            {churnedEvents?.map(event => (
                                <li key={event.id}>
                                    Churned: {coachingTypeText(event.discipline)}
                                </li>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                    {onDemandStats && OnDemandStats(onDemandStats)}
                </ul>
            </div>
        );
    }

    const title: string | JSX.Element = (() => {
        if (athlete) return fullName(athlete);
        else if (invitation)
            return (
                <>
                    {fullName(invitation)} (<span className="admin-invited-text">Invited</span>)
                </>
            );
        else return "<No Name>";
    })();

    if (!athlete) return <></>;
    return (
        <li>
            <Link to={`/admin/athletes/${athlete?.athleteDetails?.id ?? invitation?.athleteId}`}>
                <UserAvatarRow
                    userId={athlete.id}
                    title={title}
                    subtitle={contentText()}
                    showChevron
                    hideAvatar
                    noParent={parents?.length === 0}
                    underageAthlete={athlete.status === "underage"}
                />
            </Link>
        </li>
    );
}

export function AdminToggle<T>({
    options,
    selected,
    setSelected,
}: {
    options: { text: string; value: T }[];
    selected: T;
    setSelected: (value: T) => void;
}) {
    return (
        <div className="d-flex flex-row tab-container">
            {options.map(option => (
                <div className="d-flex" key={`${option.value}`}>
                    <button
                        className={`admin-toggle-button ${
                            selected === option.value ? "selected" : ""
                        }`}
                        onClick={() => setSelected(option.value)}
                    >
                        {option.text}
                    </button>
                    {option.value !== _.last(options)?.value && <p style={{ opacity: 0.4 }}> | </p>}
                </div>
            ))}
        </div>
    );
}

export function AdminUnmatchedAthleteList() {
    const [athletes, setAthletes] = useState<AthleteRowProps[]>([]);
    const refresh = useCallback(async () => {
        setAthletes(await getAdminUnmatchedAthletes());
    }, []);
    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <div className="admin-list-container">
            <h2 className="section-header">Unmatched Athletes</h2>
            <ul className="no-li-style">
                {athletes.map(props => (
                    <PrivateCoachingAthleteRow
                        {...props}
                        key={props.athlete?.id ?? props.invitation?.code}
                    />
                ))}
            </ul>
        </div>
    );
}
