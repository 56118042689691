import { CoachingAssignment, CoachingType } from "data/assignment";
import { AthleteDetails, getUser, useMe, User } from "data/user";
import { QueryConfig, run } from "./api";
import {
    CoachingSession,
    getAllSessionsForAssignment,
    getCoachSessions,
    getUpcomingAssignmentSessions,
    SessionOrSeriesInstance,
    SessionsByDate,
} from "./session";
import { addDays, subDays } from "date-fns";
import { RelationshipInvitation } from "./invitation";
import { Name } from "../util";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./query";

export type Athlete = {
    assignmentId: string;
    assignment: CoachingAssignment;
    user?: User;
    details: AthleteDetails;
    sessions: CoachingSession[];
    parents: User[];
    discipline: CoachingType;
};

export function athleteName(athlete: Athlete): Name {
    if (athlete.user) {
        return athlete.user;
    }
    return {};
}

export type CalendarWeekdayAvailability = {
    id: string;
    day: string;
    startHour: number;
    startMin: number;
    endHour: number;
    endMin: number;
};

export type CalendarAvailabilitySettings = {
    id: string;
    weekdayAvailability: CalendarWeekdayAvailability[];
};

export type CoachState = {
    userId: string;
    user?: User;
    athletes: Athlete[];
    calendarAvailabilitySettings?: CalendarAvailabilitySettings;
    sessions: SessionsByDate;
    sessionsPageEndDate?: Date;
    sessionsByAssignment: { [assignmentId: string]: SessionsByDate };
};

export type CoachingMatchPreferences = {
    coachId: string;
    disciplines: CoachingType[];
    maxAssignments: number;
    sports: string[];
};

type CoachRefreshAction = {
    type: "refresh";
};

type RefreshSessions = {
    type: "refresh_sessions";
};

type RefreshAthleteSessions = {
    type: "refresh_athlete_sessions";
    assignmentId: string;
};

type RefreshAthletes = {
    type: "refresh_athletes";
};

type SetBioLinkAction = {
    type: "set_bio_link";
    coachId: string;
    bioLink: string;
};

type CancelSession = {
    type: "cancel_session";
    sessionId: string;
};

type CancelSessionSeries = {
    type: "cancel_session_series";
    sessionId: string;
};

type CancelSessionSeriesInstance = {
    type: "cancel_session_series_instance";
    sessionId: string;
    date: Date;
};

type CreateSessionConfirmationPayload = {
    sessionDetails: { sessionId: string } | { seriesId: string; seriesDate: Date };
    confirmation:
        | { occurred: true }
        | { occurred: false; cancellationPolicyViolation: boolean; reason: string };
    assignmentId: string;
};
type CreateSessionConfirmation = {
    type: "create_session_confirmation";
} & CreateSessionConfirmationPayload;

export type CoachAction =
    | CoachRefreshAction
    | SetBioLinkAction
    | RefreshSessions
    | RefreshAthleteSessions
    | RefreshAthletes
    | CancelSession
    | CancelSessionSeries
    | CancelSessionSeriesInstance
    | CreateSessionConfirmation;

async function getExpandedAssignments(coachId: string): Promise<Athlete[]> {
    return run<Athlete[]>({
        path: `/coaches/${coachId}/coaching-assignments/expanded`,
        method: "GET",
    });
}

export async function getAthletes(coachId: string): Promise<Athlete[]> {
    const assignments: CoachingAssignment[] = await run({
        path: `/coaches/${coachId}/coaching-assignments`,
        method: "GET",
    });
    return await Promise.all(
        assignments.map(async assignment => {
            // FIXME: This should be an aggregate endpoint to avoid penalizing coaches with lots of clients
            const athlete: AthleteDetails = await run({
                path: `/athletes/${assignment.athleteId}`,
                method: "GET",
            });
            const userId = athlete.userId;
            let user: User | undefined;
            let parents: User[] = [];
            const invitations = await getAthleteInvitations(athlete.id);
            if (userId) {
                user = await getUser(userId);
                const relationships: { parentId: string }[] = await run({
                    path: `/users/${userId}/parents`,
                    method: "GET",
                });
                parents = await Promise.all(relationships.map(({ parentId }) => getUser(parentId)));
            } else {
                const invitedParentIds = invitations
                    .filter(i => i.inviteeType === "parent")
                    .map(i => i.accepterId)
                    .filter((userId): userId is string => !!userId);
                const invitingParentIds = invitations
                    .filter(i => i.inviteeType === "athlete")
                    .map(i => i.inviterId)
                    .filter((userId): userId is string => !!userId);
                parents = await Promise.all(
                    invitedParentIds.concat(invitingParentIds).map(parentId => getUser(parentId)),
                );
            }
            const upcomingSessions = await getUpcomingAssignmentSessions(assignment.id);
            return {
                assignmentId: assignment.id,
                assignment,
                user,
                parents,
                sessions: upcomingSessions,
                details: athlete,
                discipline: assignment.coachingType,
            };
        }),
    );
}

export function useAthleteDetails(athleteId: string) {
    return useQuery({
        queryKey: queryKeys.athletes.detail(athleteId),
        queryFn: () =>
            run<AthleteDetails>({
                path: `/athletes/${athleteId}`,
                method: "GET",
            }),
        staleTime: 10000,
    });
}

async function getAthleteInvitations(athleteId: string): Promise<RelationshipInvitation[]> {
    return run({
        path: `/invitations/athlete/${athleteId}`,
        method: "GET",
    });
}

async function getCalendarAvailabilitySettings(
    userId: string,
): Promise<CalendarAvailabilitySettings | undefined> {
    try {
        return await run({
            path: `/users/${userId}/calendar-availability-settings`,
            method: "GET",
        });
    } catch {
        return undefined;
    }
}

export async function setBioLink(coachId: string, bioLink: string) {
    await run({
        path: `/coaches/${coachId}`,
        method: "PATCH",
        body: { bioLink },
    });
}

const initialState: CoachState = {
    userId: "",
    athletes: [],
    sessions: {},
    sessionsByAssignment: {},
};

async function getUpcomingSessions(
    coachId: string,
): Promise<Pick<CoachState, "sessions" | "sessionsPageEndDate">> {
    const endDate = addDays(new Date(), 30);
    return {
        sessions: await getCoachSessions(coachId, subDays(new Date(), 30), endDate),
        sessionsPageEndDate: endDate,
    };
}

export function useAssignmentsForCoach(
    coachId: string,
    config: QueryConfig<CoachingAssignment[]> = {},
) {
    async function getAssignmentsForCoach(): Promise<CoachingAssignment[]> {
        return run({
            path: `/coaches/${coachId}/coaching-assignments`,
            method: "GET",
        });
    }
    const query = {
        ...config,
        queryKey: queryKeys.assignments.coach(coachId),
        queryFn: getAssignmentsForCoach,
        staleTime: 60000,
    };
    return useQuery(query);
}
export function useUser(userId: string, config: QueryConfig<User> = {}) {
    return useQuery({
        ...config,
        queryKey: queryKeys.users.detail(userId),
        queryFn: () => getUser(userId),
        staleTime: 30 * 1000,
    });
}

export function useCalendarAvailabilitySettings(userId: string) {
    return useQuery({
        queryKey: queryKeys.users.calendarAvailabilitySettings(userId),
        queryFn: () => getCalendarAvailabilitySettings(userId),
        staleTime: 30 * 1000,
    });
}

export function useCoachAthletes(coachId: string, config: QueryConfig<Athlete[]> = {}) {
    return useQuery({
        ...config,
        queryKey: queryKeys.coach.athletes(coachId),
        queryFn: () => getExpandedAssignments(coachId),
        staleTime: 20000,
    });
}

export function useSessionsByDate(
    { coachId, start, end }: { coachId: string; start: Date; end: Date },
    config: QueryConfig<SessionsByDate>,
) {
    return useQuery({
        ...config,
        queryKey: ["sessions", coachId, start, end],
        queryFn: () => getCoachSessions(coachId, start, end),
        staleTime: 5000,
    });
}

export function useUpcomingSessions(
    coachId: string,
    config: QueryConfig<Pick<CoachState, "sessions" | "sessionsPageEndDate">>,
) {
    // TODO: reuse the above hook.
    return useQuery({
        ...config,
        queryKey: queryKeys.sessions.upcoming(coachId),
        queryFn: () => getUpcomingSessions(coachId),
        staleTime: 5000,
    });
}

export function useSessionsByAssignment(
    assignmentId: string,
    config: QueryConfig<SessionOrSeriesInstance[]> = {},
) {
    return useQuery({
        ...config,
        queryKey: queryKeys.sessions.assignment(assignmentId),
        queryFn: () => getAllSessionsForAssignment(assignmentId),
        staleTime: 5000,
    });
}

export function useCoachData(userId: string) {
    const { data: user } = useUser(userId);
    const coachId = user?.coachDetails?.id;

    const { isLoading: isLoadingAthletes, data: athletes = [] } = useCoachAthletes(coachId!, {
        enabled: !!coachId,
    });
    const { isLoading: isLoadingSessions, data: sessions } = useUpcomingSessions(coachId!, {
        enabled: !!coachId,
    });

    const isLoading = isLoadingAthletes || isLoadingSessions;
    return {
        ...initialState,
        user,
        athletes,
        assignments: athletes.map(a => a.assignment),
        ...sessions,
        isLoading,
    };
}
export function useCoachActions() {
    const queryClient = useQueryClient();
    const { data: me } = useMe();

    const invalidateSessions = async () => {
        if (me && me.type === "coach" && me.coachDetails) {
            await queryClient.invalidateQueries({
                queryKey: queryKeys.sessions.upcoming(me.coachDetails.id),
            });
        }
    };
    const cancelSession = useMutation({
        mutationFn: (sessionId: string) =>
            run({
                path: `/coaching-sessions/${sessionId}`,
                method: "DELETE",
            }),
        onSuccess: invalidateSessions,
        onError: e => console.error(e),
    });
    const cancelSeries = useMutation({
        mutationFn: (seriesId: string) =>
            run({
                path: `/coaching-session-series/${seriesId}`,
                method: "DELETE",
            }),
        onSuccess: invalidateSessions,
        onError: e => console.error(e),
    });
    const cancelSeriesInstance = useMutation({
        mutationFn: ({ seriesId, date }: { seriesId: string; date: Date }) =>
            run({
                path: `/coaching-session-series/${seriesId}/exceptions`,
                method: "POST",
                body: { date: date.toISOString() },
            }),
        onSuccess: invalidateSessions,
        onError: e => console.error(e),
    });
    const createSessionConfirmation = useMutation({
        mutationFn: (payload: CreateSessionConfirmationPayload) =>
            run({
                path: `/post-session-confirmation`,
                method: "POST",
                body: {
                    ...payload.sessionDetails,
                    cancellationPolicyViolation: false,
                    ...payload.confirmation,
                },
            }),
        onSuccess: invalidateSessions,
        onError: e => console.error(e),
    });
    return {
        cancelSession,
        cancelSeries,
        cancelSeriesInstance,
        createSessionConfirmation,
    };
}
