import { run } from "data/api";

export async function getCreatedAssignmentsReport(start: Date, end: Date): Promise<string> {
    return await run({
        path: "/admin/reports/created-assignments",
        method: "POST",
        body: {
            start: start.toISOString(),
            end: end.toISOString(),
        },
    });
}
