import { useCallback, useEffect, useState } from "react";
import { RecentAthlete, getAdminRecentAthletes } from "data/admin";
import { Link } from "react-router-dom";
import { AvatarRow } from "components/shared/AvatarRow";
import { coachingTypeText, fullName } from "../../../util";
import { format, isThisYear, isToday, isYesterday } from "date-fns";

function createdAt(timestamp: Date): string {
    if (isToday(timestamp)) return "Today";
    if (isYesterday(timestamp)) return "Yesterday";
    if (isThisYear(timestamp)) return format(timestamp, "EEE, MMM do");
    return format(timestamp, "M/dd/yyyy");
}

function Athlete({ athlete }: { athlete: RecentAthlete }) {
    return (
        <li>
            <Link to={`/admin/athletes/${athlete.athleteId}`}>
                <AvatarRow
                    title={fullName(athlete)}
                    subtitle={
                        <>
                            {athlete.coaches.map(coach => (
                                <Link to={`/admin/coaches/${coach.coachId}`}>
                                    <em>
                                        {coachingTypeText(coach.type)}: {fullName(coach)}
                                    </em>
                                    <br />
                                </Link>
                            ))}
                        </>
                    }
                    subtitle2={`Created ${createdAt(athlete.createdAt)}`}
                    hideAvatar
                    showChevron
                />
            </Link>
        </li>
    );
}

export function AdminRecentAthleteList() {
    const createdWithinDays = 7;
    const [athletes, setAthletes] = useState<RecentAthlete[]>();
    const refresh = useCallback(async () => {
        const athletes = await getAdminRecentAthletes(createdWithinDays);
        setAthletes(athletes);
    }, []);
    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <div className="admin-list-container">
            <h2 className="section-header">Athletes Created Within {createdWithinDays} Days</h2>
            <ul className="no-li-style">
                {athletes?.map(a => (
                    <Athlete athlete={a} />
                ))}
            </ul>
        </div>
    );
}
