import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { AttachmentButton } from "./AttachmentButton";
import { SendButton } from "./SendButton";
import { getSendTime, MessageSendTime } from "util/scheduled-send";
import { ChannelContext, getRecipient } from "data/channel";
import { ScheduledMessagesBanner } from "./ScheduledMessagesBanner";
import "./MessageInput.css";
import { MessageTemplatesButton } from "./MessageTemplates";
import { recipientTime } from "../../../util";

export function MessageInput() {
    const [channelState, channelDispatch] = useContext(ChannelContext);
    const [text, setText] = useState("");
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    useEffect(() => {
        channelDispatch({ type: "refresh_scheduled_messages" });
    }, [channelDispatch]);
    const setTextAndHeight = (text: string) => {
        setText(text);
        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.value = text;
            textarea.style.height = "1px";
            textarea.style.height = textarea.scrollHeight + "px";
        }
    };
    const sendMessage = (time: MessageSendTime | Date) => {
        if (!text) return;
        if (time === MessageSendTime.Instant) {
            channelDispatch({
                type: "send_message",
                message: text,
            });
        } else {
            channelDispatch({
                type: "send_scheduled_message",
                message: text,
                time: time instanceof Date ? time : getSendTime(time),
            });
        }
        setTextAndHeight("");
    };
    const timeForRecipient = recipientTime(new Date(), getRecipient(channelState.channel));
    return (
        <div className="message-input-wrapper">
            {channelState.scheduledMessages.length ? <ScheduledMessagesBanner /> : <></>}
            {timeForRecipient && (
                <p className="timezone-warning">It is currently {timeForRecipient}.</p>
            )}
            <div className="message-input">
                <textarea
                    ref={textAreaRef}
                    placeholder="Enter message"
                    maxLength={5000}
                    onKeyDown={e => {
                        if (e.code === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            sendMessage(MessageSendTime.Instant);
                            if (e.target instanceof HTMLElement) {
                                e.target.style.height = "0px";
                            }
                        }
                    }}
                    onChange={e => {
                        setTextAndHeight(e.target.value);
                    }}
                />
                {text.length === 0 && (
                    <>
                        <MessageTemplatesButton setText={setTextAndHeight} />
                        <AttachmentButton />
                    </>
                )}
                <SendButton
                    recipient={getRecipient(channelState.channel)}
                    enabled={!!text}
                    send={time => sendMessage(time)}
                />
            </div>
            {!!text.length && (
                <p className="line-break-hint">
                    <b>Shift + Return</b> to add a new line
                </p>
            )}
        </div>
    );
}
