import { Avatar } from "./avatar";
import { AthleteDetails, User } from "./user";
import { RelationshipInvitation } from "./invitation";
import { ActivationCode, ActivationCodeUsage } from "./admin";
import { useCallback, useState } from "react";
import { run } from "./api";
import { Note } from "./note";
import { Feedback } from "./feedback";

type AdminParentResponse = User & {
    avatar?: Avatar;
    children: {
        child: User & { athleteDetails: AthleteDetails };
    }[];
    createdInvitations: RelationshipInvitation[];
    acceptedInvitations: RelationshipInvitation[];
    activationCodeUsages: ActivationCodeUsage[];
    createdActivationCodes: ActivationCode[];
    noteAccess: {
        note: Note;
    }[];
    feedback: Feedback[];
};

type AdminParentDispatch = (_: { type: "refresh" }) => void;

export function useAdminParent(
    userId: string,
): [AdminParentResponse | undefined, AdminParentDispatch] {
    const [state, setState] = useState<AdminParentResponse>();
    const dispatch = useCallback(
        async (_: { type: "refresh" }) => {
            const response = await run<AdminParentResponse>({
                method: "GET",
                path: `/admin/parents/${userId}`,
            });
            setState(response);
        },
        [userId],
    );
    return [state, dispatch];
}
