import { useContext, useState } from "react";
import { NotesContext } from "data/note";
import { User } from "data/user";
import Confirmation, { ConfirmationContext } from "components/shared/Confirmation";
import "./ShareNote.css";

function ShareList({
    sharees,
    state,
}: {
    sharees: User[];
    state: [User[], (users: User[]) => void];
}) {
    const [selected, setSelected] = state;
    return (
        <ul className="no-li-style">
            {sharees.map(u => {
                const name = `${u.firstName} ${u.lastName}`;
                return (
                    <li key={u.id}>
                        <input
                            id={u.id}
                            type="checkbox"
                            name={name}
                            checked={selected.find(s => s.id === u.id) !== undefined}
                            onChange={e => {
                                const selections = e.target.checked
                                    ? [...selected, u]
                                    : selected.filter(s => s.id !== u.id);
                                setSelected(selections);
                            }}
                        />
                        <label htmlFor={u.id}>{name}</label>
                    </li>
                );
            })}
        </ul>
    );
}

type Mode = "form" | "success";

export function ShareNote({ sharees, attachments }: { sharees: User[]; attachments: File[] }) {
    const [mode, setMode] = useState<Mode>("form");
    const [{ newSharedNoteDraft }, dispatch] = useContext(NotesContext);
    const selections = useState<User[]>(sharees.filter(u => u.type === "athlete"));
    const [selected] = selections;
    const [, setIsVisible] = useContext(ConfirmationContext);
    const noteTitle = newSharedNoteDraft?.title;
    const title = `Share ${noteTitle}`;
    const onShare = () => {
        if (selected.length > 0) {
            dispatch({
                type: "save_and_share_note",
                title: newSharedNoteDraft?.title ?? "",
                body: newSharedNoteDraft?.editor?.getJSON() ?? {},
                sharees: selected,
                attachments: attachments,
            });
            setMode("success");
        }
    };
    switch (mode) {
        case "form":
            return (
                <Confirmation title={title}>
                    <Confirmation.Body>
                        <p>Who do you want to share this note with?</p>
                        <ShareList state={selections} sharees={sharees} />
                    </Confirmation.Body>
                    <Confirmation.Button label="Cancel" onClick={() => setIsVisible(false)} />
                    <Confirmation.Button
                        label="Share"
                        onClick={onShare}
                        isPrimary
                        disabled={selected.length === 0}
                    />
                </Confirmation>
            );
        case "success":
            return (
                <Confirmation
                    title="Success!"
                    onDismiss={() => dispatch({ type: "cancel_draft", isPrivate: false })}
                >
                    <Confirmation.Body>
                        <div className="share-success">
                            <img src="/checkmark-circle.svg" alt="checkmark" />
                            <p>{`You successfully shared "${noteTitle}".`}</p>
                            <p>The note will be accessible from the IMG+ app.</p>
                        </div>
                    </Confirmation.Body>
                    <Confirmation.Button
                        label="Done"
                        onClick={() => {
                            setIsVisible(false);
                            dispatch({ type: "cancel_draft", isPrivate: false });
                        }}
                        isPrimary
                    />
                </Confirmation>
            );
    }
}
