import React, { FormEventHandler, useCallback, useContext, useEffect } from "react";
import { AuthContext } from "data/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Toolbar } from "./Toolbar";
import { Footer } from "./Footer";
import "./SignIn.css";
import { trackPageView } from "../vendor/mixpanel";

function SignInForm({ submit, error }: { submit: FormEventHandler; error?: string }) {
    return (
        <form onSubmit={submit}>
            <label>Email</label>
            <input className="text-input" type="email" name="email" />
            <label>Password</label>
            <input className="text-input" type="password" name="password" />
            {error ? <p style={{ color: "red" }}>Error: {error}</p> : <div></div>}
            <br />
            <input className="pill" type="submit" value="Submit" />
        </form>
    );
}

export function SignIn() {
    const [authState, authDispatch] = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    // @ts-ignore
    const from = location.state?.from?.pathname || "/";
    const submit = useCallback(
        async e => {
            e.preventDefault();
            if (e.target.checkValidity() === true) {
                const formData = new FormData(e.target);
                const email = formData.get("email") as string;
                const password = formData.get("password") as string;
                await authDispatch({ type: "sign_in", email, password });
            }
        },
        [authDispatch],
    );
    useEffect(() => {
        trackPageView("sign_in");
    }, []);
    useEffect(() => {
        switch (authState.status) {
            case "signed_in":
                navigate(from, { replace: true });
        }
    }, [authState, from, navigate]);
    return (
        <>
            <Toolbar />
            <div className="content sign-in">
                <h2>Login</h2>
                <div className="card">
                    <SignInForm submit={submit} error={authState.error} />
                </div>
            </div>
            <Footer />
        </>
    );
}
