import { createPendingActivation } from "data/admin";
import { useState } from "react";

export function AdminUseActivationCode({ athleteId }: { athleteId: string }) {
    const [code, setCode] = useState("");
    async function onSubmit() {
        const warning =
            "Are you sure? This will deliver a push notification and force this user to onboard with this activation code, preventing them from using the app until onboarding is complete.";
        if (window.confirm(warning)) {
            await createPendingActivation(athleteId, code);
            window.alert("Successfully delivered activation push notification");
        }
    }
    return (
        <div className="d-flex align-items-center">
            <label>Activation Code/Link:</label>
            &nbsp;
            <input
                className="text-input mx-2"
                type="text"
                value={code}
                onChange={e => setCode(e.target.value)}
            />
            &nbsp;
            <button className="pill" disabled={code.length === 0} onClick={onSubmit}>
                Force activation
            </button>
        </div>
    );
}
