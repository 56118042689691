import React, { useContext, useState } from "react";
import { Modal } from "components/shared/Modal";
import "./AdminCoachDetailsBioLink.css";
import { AdminCoachContext } from "data/admin-coach";

function BioLinkModalContent({ close }: { close: () => void }) {
    const [adminCoachState, adminCoachDispatch] = useContext(AdminCoachContext);
    const [newBioLink, setNewBioLink] = useState("");
    const submit = () => {
        if (adminCoachState?.user?.coachDetails?.id) {
            adminCoachDispatch({
                type: "set_bio_link",
                bioLink: newBioLink,
            });
        }
        close();
    };
    return (
        <div className="admin-coach-details-bio-link-modal">
            <h1>Edit Bio Link</h1>
            <br />
            <input onChange={e => setNewBioLink(e.target.value)} />
            <button onClick={submit}>Submit</button>
        </div>
    );
}

export function AdminCoachDetailsBioLink() {
    const [adminCoachState] = useContext(AdminCoachContext);
    const [showEditModal, setShowEditModal] = useState(false);
    return (
        <>
            <Modal show={showEditModal} hide={() => setShowEditModal(false)}>
                <BioLinkModalContent close={() => setShowEditModal(false)} />
            </Modal>
            <p>
                {adminCoachState?.user?.coachDetails?.bioLink?.length
                    ? adminCoachState.user.coachDetails.bioLink
                    : "Not set"}
            </p>
            <button onClick={() => setShowEditModal(true)}>Edit</button>
        </>
    );
}
