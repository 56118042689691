import { useQuery } from "@tanstack/react-query";
import { ActivationCodeUsage } from "./admin";
import { QueryConfig, run } from "./api";
import { queryKeys } from "./query";

export async function getActivationCodeUsages(userId: string): Promise<ActivationCodeUsage[]> {
    return await run({ path: `/users/${userId}/activation-code-usages`, method: "GET" });
}

export function useActivationCodeUsages(
    userId: string,
    config: QueryConfig<ActivationCodeUsage[]> = {},
) {
    return useQuery({
        queryKey: queryKeys.users.activationCodeUsages(userId),
        queryFn: () => getActivationCodeUsages(userId),
        staleTime: 30000,
        ...config,
    });
}
