import { createContext, useContext } from "react";
import "./Banner.css";
import { useAthleteDetails, useUser } from "data/coach";

interface ShowUpcomingSession {
    type: "show_upcoming_session";
    link?: string;
    athleteId: string;
}

interface Dismiss {
    type: "dismiss";
}

export type BannerAction = Dismiss | ShowUpcomingSession;

export const BannerContext = createContext<[BannerAction, (action: BannerAction) => void]>([
    { type: "dismiss" },
    (_: BannerAction) => {},
]);

function AthleteBannerContent({ athleteId }: { athleteId: string }) {
    const { data: athleteDetails } = useAthleteDetails(athleteId);
    const { data: user } = useUser(athleteDetails?.userId ?? "", { enabled: !!athleteDetails });

    if (!user) return <span>{`Your session is starting soon.`}</span>;
    return <span>{`Your session for ${user?.firstName} is starting soon.`}</span>;
}

function Content() {
    const [action] = useContext(BannerContext);
    switch (action.type) {
        case "dismiss":
            return <></>;
        case "show_upcoming_session": {
            const cta = <AthleteBannerContent athleteId={action.athleteId} />;
            if (action.link) {
                return (
                    <>
                        {cta}{" "}
                        <a
                            className="banner-link"
                            rel="noreferrer"
                            target="_blank"
                            href={action.link}
                        >
                            Join Now
                        </a>
                    </>
                );
            } else {
                return cta;
            }
        }
    }
}

export function Banner() {
    const [action] = useContext(BannerContext);
    if (action.type === "dismiss") return <></>;
    return (
        <div className="banner">
            <Content />
        </div>
    );
}
