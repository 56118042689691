import { Context, createContext, useCallback, useContext, useEffect, useState } from "react";
import { getUser, User, UserContext } from "data/user";
import { format, isThisYear, isToday, isYesterday } from "date-fns";
import { NotificationContext } from "util/notifications";
import { run } from "data/api";
import { useNavigate } from "react-router-dom";
import {
    GroupChannel,
    GroupChannelHandler,
    GroupChannelListOrder,
    GroupChannelModule,
    MyMemberStateFilter,
} from "@sendbird/chat/groupChannel";
import { AdminMessage, FileMessage, UserMessage } from "@sendbird/chat/message";
import SendbirdChat, { BaseChannel } from "@sendbird/chat";

export type Channel = {
    url: string;
    assignmentIds: string[];
    name: string;
    otherMembers: User[];
    lastMessage?: string;
    lastMessageTimestamp?: string;
    unreadCount: number;
};

export type SendbirdState = {
    userId: string;
    channels: GroupChannel[];
    isConnected: boolean;
    sessionToken?: string;
};

export type SendbirdMalkovichAction = {
    type: "malkovich";
    userId: string;
};

export type SendbirdAction = SendbirdMalkovichAction;

const sb = SendbirdChat.init({
    appId: process.env.REACT_APP_SENDBIRD_APP_ID ?? "",
    localCacheEnabled: true,
    modules: [new GroupChannelModule()],
});

export function getAssignmentIds(sendbirdChannel: BaseChannel): string[] {
    const metaData = sendbirdChannel.cachedMetaData as Record<string, string>;
    return Object.keys(metaData).filter(key => metaData[key] === "assignment_id")!;
}

function lastMessage(sendbirdChannel: GroupChannel): string | undefined {
    switch (sendbirdChannel.lastMessage?.messageType) {
        case "user":
            let userMessage = sendbirdChannel.lastMessage as UserMessage;
            return userMessage.message;
        case "file":
            return "Sent an attachment";
        case "admin":
            let adminMessage = sendbirdChannel.lastMessage as AdminMessage;
            return adminMessage.message;
    }
}

function lastMessageTimestamp(sendbirdChannel: GroupChannel): string | undefined {
    if (sendbirdChannel.lastMessage?.createdAt) {
        const date = new Date(sendbirdChannel.lastMessage?.createdAt);
        if (isToday(date)) {
            return format(date, "h:mm a");
        } else if (isYesterday(date)) {
            return "Yesterday";
        } else if (isThisYear(date)) {
            return format(date, "MMM d");
        } else {
            return format(date, "M/d/yy");
        }
    }
}

function channelName(otherMembers: User[]): string {
    if (otherMembers.length === 0) {
        return "(Error - no other members in channel)";
    }
    const otherMember = otherMembers[0]!;
    if (otherMembers.length > 1) {
        return "Group - Athlete and Parent";
    } else if (otherMember.type === "parent") {
        return `${otherMember.firstName} (Parent)`;
    } else {
        return otherMember.firstName ?? "Channel";
    }
}

export async function convertChannel(
    currentUserId: string,
    sendbirdChannel: GroupChannel,
): Promise<Channel> {
    const otherMemberIds = sendbirdChannel.members
        .filter(member => member.userId !== currentUserId)
        .map(member => member.userId);
    const otherMembers = await Promise.all(otherMemberIds.map(id => getUser(id)));
    const assignmentIds = getAssignmentIds(sendbirdChannel);
    return {
        url: sendbirdChannel.url,
        assignmentIds,
        name: channelName(otherMembers),
        otherMembers,
        lastMessage: lastMessage(sendbirdChannel),
        lastMessageTimestamp: lastMessageTimestamp(sendbirdChannel),
        unreadCount: sendbirdChannel.unreadMessageCount,
    };
}

export async function getChannel(channelUrl: string): Promise<GroupChannel> {
    return sb.groupChannel.getChannel(channelUrl);
}

async function channelList(): Promise<GroupChannel[]> {
    const listQuery = sb.groupChannel.createMyGroupChannelListQuery({
        limit: 15,
        includeEmpty: true,
        order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        myMemberStateFilter: MyMemberStateFilter.JOINED,
    });
    let channels: GroupChannel[] = [];
    while (listQuery.hasNext) {
        const foo = await listQuery.next();
        channels = channels.concat(foo);
    }
    return channels;
}

export async function markAllAsRead(): Promise<void> {
    return sb.groupChannel.markAsReadAll();
}

async function connect(userId: string, isMalkovich: boolean): Promise<string> {
    const key = `messaging-session-token-${userId}`;
    const sessionToken = localStorage.getItem(key);
    try {
        if (sessionToken) {
            await sb.connect(userId, sessionToken);
            return sessionToken;
        } else {
            throw Error("Missing Sendbird session token");
        }
    } catch {
        const { token } = await run<{ token: string }>({
            path: isMalkovich
                ? `/admin/users/${userId}/messaging-session-token`
                : "/me/messaging-session-token",
            method: "GET",
        });
        localStorage.setItem(key, token);
        await sb.connect(userId, token);
        return token;
    }
}

const initialState: SendbirdState = {
    userId: "",
    channels: [],
    isConnected: false,
};

export function useChannels(assignmentId: string): Channel[] {
    const [sendbirdState] = useContext(SendbirdContext);
    const [state, setState] = useState([] as Channel[]);
    useEffect(() => {
        if (sendbirdState.userId) {
            const channels = sendbirdState.channels
                .filter(channel => getAssignmentIds(channel).includes(assignmentId))
                .map(channel => convertChannel(sendbirdState.userId, channel));
            Promise.all(channels).then(channels => setState(channels));
        }
    }, [assignmentId, sendbirdState.userId, sendbirdState.channels]);
    return state;
}

export function useSendbird(): [SendbirdState, (action: SendbirdAction) => void] {
    const [, notificationDispatch] = useContext(NotificationContext);
    const [userState] = useContext(UserContext);
    const [malkovichedUserId, setMalkovichedUserId] = useState<string | undefined>(undefined);
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    useEffect(() => {
        const userId =
            malkovichedUserId ??
            (userState.user?.type !== "admin" ? userState.user?.id : undefined);
        if (userId) {
            connect(userId, malkovichedUserId !== undefined).then(sessionToken =>
                setState(state => ({ ...state, userId, sessionToken, isConnected: true })),
            );
        }
    }, [malkovichedUserId, userState.user]);
    const refresh = useCallback(() => {
        if (state.userId && state.isConnected) {
            channelList().then(channels => setState(state => ({ ...state, channels })));
        }
    }, [state.userId, state.isConnected]);
    useEffect(() => {
        const handlerId: string = Date.now().toString();
        const groupChannelHandler = new GroupChannelHandler({
            onChannelChanged: refresh,
            onMessageReceived: (channel, message) => {
                const onclick = () => {
                    window.focus();
                    const assignmentId = getAssignmentIds(channel)[0];
                    navigate(`/messaging/${assignmentId}?channelUrl=${channel.url}`);
                    navigate(0); // refresh page
                };
                switch (message.messageType) {
                    case "user":
                        const userMessage = message as UserMessage;
                        notificationDispatch({
                            type: "show_notification",
                            text: `${userMessage.sender?.nickname}: ${userMessage.message}`,
                            onclick,
                        });
                        break;
                    case "file":
                        const fileMessage = message as FileMessage;
                        notificationDispatch({
                            type: "show_notification",
                            text: `${fileMessage.sender?.nickname} sent a file`,
                            onclick,
                        });
                        break;
                }
            },
        });
        sb.groupChannel.addGroupChannelHandler(handlerId, groupChannelHandler);
        refresh();
        return function cleanup() {
            sb.groupChannel.removeGroupChannelHandler(handlerId);
        };
    }, [refresh, notificationDispatch, navigate]);
    const dispatch = useCallback(async (action: SendbirdAction) => {
        switch (action.type) {
            case "malkovich":
                setMalkovichedUserId(action.userId);
                break;
        }
    }, []);
    return [state, dispatch];
}

export const SendbirdContext: Context<[SendbirdState, (action: SendbirdAction) => void]> =
    createContext([initialState, (_: SendbirdAction) => {}]);

export const ChannelsContext: Context<Channel[]> = createContext([] as Channel[]);
