import { UserAvatar } from "components/shared/Avatar";
import { mixpanelProfileUrl } from "vendor/mixpanel";
import { useContext } from "react";
import { AdminCoachContext } from "data/admin-coach";
import { Badge } from "components/shared/Badge";

export function AdminCoachDetailsHeader() {
    const [coachState] = useContext(AdminCoachContext);
    const user = coachState?.user;
    return user ? (
        <div className="row" style={{ gap: "20px", alignItems: "start" }}>
            <UserAvatar userId={user.id} size="large" />
            <div>
                <h1>
                    {user.firstName} {user.lastName}
                    {user.status === "suspended" && <Badge color="danger" text="disabled" />}
                </h1>
                <p>{user.email}</p>
                <p>{user.id}</p>
                <p>{user.coachDetails?.bioLink}</p>
                <a href={mixpanelProfileUrl(user.id)}>Mixpanel Profile</a>
            </div>
        </div>
    ) : (
        <></>
    );
}
