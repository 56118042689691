import { AdminUnmatchedAthleteList } from "./AdminUnmatchedAthleteList";
import { AdminCoachList } from "./AdminCoachList";
import { AdminRecentAthleteList } from "./AdminRecentAthleteList";

export function AdminOneOnOneTab() {
    return (
        <div className="d-flex flex-col">
            <div className="d-flex flex-row justify-content-end m-4" style={{ gap: "1rem" }}></div>
            <div className="d-flex flex-no-wrap" id="list-container">
                <AdminCoachList />
                <AdminUnmatchedAthleteList />
                <AdminRecentAthleteList />
            </div>
        </div>
    );
}
