import Messaging from "components/messaging/Messaging";
import { useCoachData } from "data/coach";
import { User, useMe } from "data/user";
import { useParams, useSearchParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./Assignment.css";
import "../Tabs.css";
import { PrivateNotes, SharedNotes } from "components/notes/Notes";
import { NotesContext, useNotes } from "data/note";
import { Spinner } from "../shared/Spinner";
import { CoachSessions } from "../sessions/CoachSessions";
import { Activations } from "components/activation-code/Activations";

export function AssignmentPage() {
    const { isLoading, data: me } = useMe();
    const { assignmentId } = useParams();
    if (isLoading) return <Spinner />;
    if (!me) return <div>Error: No user found</div>;
    if (!assignmentId) return <div>Error. Coaching assignment does not exist.</div>;
    return <Assignment userId={me.id} assignmentId={assignmentId} />;
}
export function Assignment({ userId, assignmentId }: { userId: string; assignmentId: string }) {
    const coachData = useCoachData(userId);
    const athlete = coachData.athletes.find(a => a.assignmentId === assignmentId);
    const sharees: User[] = [athlete?.user, ...(athlete?.parents ?? [])].filter(
        (u): u is User => !!u,
    );
    const notes = useNotes(assignmentId);
    const [searchParams, setSearchParams] = useSearchParams();
    const canShare = sharees.length > 0;
    const name = [athlete?.user?.firstName, athlete?.user?.lastName].filter(s => !!s).join(" ");
    const tabIndex = searchParams.get("tabIndex");
    const defaultIndex = tabIndex ? Number.parseInt(tabIndex) : 0;
    if (coachData.athletes.length === 0) {
        return <Spinner />;
    }
    return (
        <div className="coaching-assignment">
            <h1 className="assignment-title">{name}</h1>
            <Tabs
                className="tabs"
                defaultIndex={Number.isNaN(defaultIndex) ? 0 : defaultIndex}
                onSelect={index => setSearchParams({ tabIndex: index.toString() })}
                selectedTabClassName="tab-selected"
                selectedTabPanelClassName="tab-panel-selected"
            >
                <TabList className="no-li-style tab-list">
                    <Tab className="tab">Messages</Tab>
                    <Tab className="tab">Private Notes</Tab>
                    {canShare && <Tab className="tab">Shared Notes</Tab>}
                    <Tab className="tab">Sessions</Tab>
                    {athlete?.user?.id && <Tab className="tab">Purchase History</Tab>}
                </TabList>
                <TabPanel>
                    <Messaging />
                </TabPanel>
                <NotesContext.Provider value={notes}>
                    <TabPanel>
                        <PrivateNotes athlete={athlete} assignmentId={assignmentId} />
                    </TabPanel>
                    {canShare && (
                        <TabPanel>
                            <SharedNotes
                                athlete={athlete}
                                sharees={sharees}
                                assignmentId={assignmentId}
                            />
                        </TabPanel>
                    )}
                </NotesContext.Provider>
                <TabPanel>
                    <CoachSessions assignmentId={assignmentId} coachUserId={userId} />
                </TabPanel>
                {athlete?.user?.id && (
                    <TabPanel>
                        <Activations athleteUserId={athlete.user.id} />
                    </TabPanel>
                )}
            </Tabs>
        </div>
    );
}
