import { Avatar, UserAvatar } from "./Avatar";
import "./AvatarRow.css";
import { Badge } from "./Badge";

type AvatarRowProps = {
    avatarUrl?: string;
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    subtitle2?: string;
    showChevron: boolean;
    badge?: number;
    hideAvatar?: boolean;
    underageAthlete?: boolean;
    noParent?: boolean;
};

export function AvatarRow({
    avatarUrl,
    title,
    subtitle,
    subtitle2,
    showChevron,
    badge,
    hideAvatar,
    underageAthlete,
    noParent,
}: AvatarRowProps) {
    return (
        <div className="row avatar-row">
            {hideAvatar ? <></> : <Avatar url={avatarUrl} size="small" />}
            <div className="column right-column">
                <div className="row">
                    <div className="column title-column">
                        <div className="d-flex flex-row">
                            <h3>{title}</h3>
                            {underageAthlete ? (
                                <Badge color="success" text="12 or younger" />
                            ) : (
                                <></>
                            )}
                            {noParent ? <Badge color="info" text="No Parent" /> : <></>}
                        </div>
                        {subtitle && <div className="secondary">{subtitle}</div>}
                        {subtitle2 ? <em className="secondary">{subtitle2}</em> : <></>}
                    </div>
                    {badge ? <div className="unread-count">{badge}</div> : <></>}
                    {showChevron ? <img src="/chevron.svg" alt="chevron" /> : <></>}
                </div>
            </div>
        </div>
    );
}

type UserAvatarRowProps = {
    userId: string;
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    subtitle2?: string;
    showChevron: boolean;
    badge?: number;
    hideAvatar?: boolean;
    underageAthlete?: boolean;
    noParent?: boolean;
};

export function UserAvatarRow({
    userId,
    title,
    subtitle,
    subtitle2,
    showChevron,
    badge,
    hideAvatar,
    underageAthlete,
    noParent,
}: UserAvatarRowProps) {
    return (
        <div className="row avatar-row">
            {hideAvatar ? <></> : <UserAvatar userId={userId} size="small" />}
            <div className="column right-column">
                <div className="row">
                    <div className="column title-column">
                        <div className="d-flex flex-row">
                            <h3>{title}</h3>
                            {underageAthlete ? (
                                <Badge color="success" text="12 or younger" />
                            ) : (
                                <></>
                            )}
                            {noParent ? <Badge color="info" text="No Parent" /> : <></>}
                        </div>
                        {subtitle && <div className="secondary">{subtitle}</div>}
                        {subtitle2 ? <em className="secondary">{subtitle2}</em> : <></>}
                    </div>
                    {badge ? <div className="unread-count">{badge}</div> : <></>}
                    {showChevron ? <img src="/chevron.svg" alt="chevron" /> : <></>}
                </div>
            </div>
        </div>
    );
}
