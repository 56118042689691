import { FormEvent, useContext } from "react";
import { GroupCourseContext } from "data/group-courses";

export function AdminGroupCourseForm() {
    const [, dispatch] = useContext(GroupCourseContext);
    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        let target = e.target as unknown as { [name: string]: { value: string } };
        await dispatch({
            type: "create_group_course",
            course: {
                id: undefined,
                title: target["title"]!.value,
                price: parseInt(target["price"]!.value),
                description: target["description"]!.value,
                start: target["start"]!.value,
                cadence: target["cadence"]!.value,
                sessionCount: parseInt(target["sessionCount"]!.value),
                seatCount: parseInt(target["seatCount"]!.value),
                enrollBy: target["enrollBy"]!.value,
                url: target["url"]!.value,
            },
        });
        (e.target as HTMLFormElement).reset();
    }
    return (
        <form className="group-course-form" onSubmit={handleSubmit}>
            <label>Title</label>
            <input type="text" name="title" />
            <label>Price (cents)</label>
            <input type="number" name="price" />
            <label>Description</label>
            <input type="text" name="description" />
            <label>Start date (eg 2022-03-04)</label>
            <input type="text" name="start" />
            <label>Cadence (eg "weekly Tuesday nights at 8:00pm EST")</label>
            <input type="text" name="cadence" />
            <label>Session Count</label>
            <input type="number" name="sessionCount" />
            <label>Seat Count</label>
            <input type="number" name="seatCount" />
            <label>Enroll By (eg 2022-09-01)</label>
            <input type="text" name="enrollBy" />
            <label>Course URL</label>
            <input type="text" name="url" />
            <button type="submit">Submit</button>
        </form>
    );
}
