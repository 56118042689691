import Popover, { PopoverContext } from "components/shared/Popover";
import { CoachingType } from "data/assignment";
import { noteCategories, NoteTopic } from "data/note";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { coachingTypeText } from "../../util";
import "./NoteTopicsInput.css";
import { useState } from "react";

export function presentation(topic: NoteTopic): string {
    switch (topic) {
        case "coachability":
            return "Coachability";
        case "confidence":
            return "Confidence";
        case "fearOfFailure":
            return "Fear of Failure";
        case "focus":
            return "Focus";
        case "handlingPressure":
            return "Handling Pressure";
        case "managingEmotions":
            return "Managing Emotions";
        case "managingIntensity":
            return "Managing Intensity";
        case "motivation":
            return "Motivation";
        case "negativeThinking":
            return "Negative Thinking";
        case "optimism":
            return "Optimism";
        case "perfectionism":
            return "Perfectionism";
        case "resilience":
            return "Resilience";
        case "bodyImage":
            return "Body Image";
        case "hydration":
            return "Hydration";
        case "meals":
            return "Meals";
        case "mealTiming":
            return "Meal Timing";
        case "snacks":
            return "Snacks";
        case "supplements":
            return "Supplements";
        case "travel":
            return "Travel";
        case "weightGain":
            return "Weight Gain";
        case "weightLoss":
            return "Weight Loss";
    }
}

interface TopicSelectionEvent {
    isChecked: boolean;
    topic: NoteTopic;
}

function NoteTopicCovered({
    selections,
    onChange,
}: {
    selections: NoteTopic[];
    onChange: (event: TopicSelectionEvent) => void;
}) {
    return (
        <div className="note-topic-container">
            <label>Topics Covered</label>
            <div className="note-topic-toggle">
                {selections.length === 0 && <span className="topic-placeholder">Select here</span>}
                <ul className="no-li-style">
                    {selections.map(topic => (
                        <li key={topic}>
                            <button
                                className="note-topic-selection"
                                onClick={event => {
                                    onChange({ isChecked: false, topic });
                                    event.stopPropagation();
                                }}
                            >
                                <span>{presentation(topic)}</span>
                                <img
                                    className="svg-tint-6B6B6B"
                                    alt={`deselect ${presentation(topic)}`}
                                    src="/x.svg"
                                />
                            </button>
                        </li>
                    ))}
                </ul>
                <img
                    className="svg-tint-9E9E9E"
                    alt="toggle topic checklist"
                    src="/down-chevron.svg"
                />
            </div>
        </div>
    );
}

function NoteTopicsSelector({
    defaultDiscipline,
    selections,
    onChange,
    hide,
}: {
    defaultDiscipline?: CoachingType;
    selections: NoteTopic[];
    onChange: (event: TopicSelectionEvent) => void;
    hide: () => void;
}) {
    const coachingTypes = Object.keys(noteCategories).sort((a, b) => {
        if (a === defaultDiscipline) return -1;
        else if (b === defaultDiscipline) return 1;
        else return 0;
    }) as CoachingType[];
    return (
        <Tabs
            className="tabs centered-tabs"
            selectedTabClassName="tab-selected"
            selectedTabPanelClassName="tab-panel-selected"
        >
            <TabList className="no-li-style tab-list">
                {coachingTypes.map(discipline => (
                    <Tab key={discipline} className="tab justify-content-center">
                        {coachingTypeText(discipline)}
                    </Tab>
                ))}
            </TabList>
            {coachingTypes.map(discipline => {
                return (
                    <TabPanel key={discipline}>
                        <div className="d-flex flex-col align-items-end note-topics-popover">
                            <ul className="no-li-style">
                                {noteCategories[discipline].map(topic => {
                                    const id = presentation(topic);
                                    return (
                                        <li key={topic}>
                                            <input
                                                type="checkbox"
                                                id={id}
                                                checked={selections.includes(topic)}
                                                onChange={event =>
                                                    onChange({
                                                        isChecked: event.target.checked,
                                                        topic,
                                                    })
                                                }
                                                value={topic}
                                            />
                                            <label htmlFor={id}>{id}</label>
                                        </li>
                                    );
                                })}
                            </ul>
                            <button className="done-button" onClick={_ => hide()}>
                                Done
                            </button>
                        </div>
                    </TabPanel>
                );
            })}
        </Tabs>
    );
}

export function NoteTopicsInput({
    defaultDiscipline,
    selections,
    onChange,
}: {
    defaultDiscipline?: CoachingType;
    selections: NoteTopic[];
    onChange: (event: TopicSelectionEvent) => void;
}) {
    const showingInput = useState(false);
    return (
        <PopoverContext.Provider value={showingInput}>
            <Popover placement="bottom-start">
                <Popover.Toggle>
                    <NoteTopicCovered selections={selections} onChange={onChange} />
                </Popover.Toggle>
                <Popover.Content>
                    <NoteTopicsSelector
                        defaultDiscipline={defaultDiscipline}
                        selections={selections}
                        onChange={onChange}
                        hide={() => showingInput[1](false)}
                    />
                </Popover.Content>
            </Popover>
        </PopoverContext.Provider>
    );
}
