import { run } from "./api";

export type CoachingType = "mindset" | "nutrition";
export type CoachingAssignmentStatus = "active" | "suspended";
export type CoachingAssignment = {
    id: string;
    coachId: string;
    athleteId: string;
    coachingType: CoachingType;
    status: CoachingAssignmentStatus;
    createdAt: Date;
};

export async function assignCoach(
    athleteId: string,
    coachId: string,
    coachingType: CoachingType,
): Promise<void> {
    await run({
        path: `/admin/coaching-assignments`,
        method: "PUT",
        body: {
            athleteId,
            coachId,
            coachingType,
        },
    });
}
