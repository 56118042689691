import { Note } from "../../data/note";
import _ from "lodash";
import { format } from "date-fns";
import React, { useState } from "react";
import { AdminAthleteDetailsSectionHeader } from "./athlete/AdminAthleteDetailsHeader";
import { Modal } from "../shared/Modal";
import { NoteEntry } from "../notes/NoteList";

function sharedWithNames(note: Note): string {
    return _.join(
        note.access.map(access => access.user.firstName),
        ", ",
    );
}

function noteDescription(note: Note): string {
    return `${note.title} - ${format(note.createdAt, "MMM d")} - shared with ${sharedWithNames(
        note,
    )}`;
}

export function AdminNotesList({ notes }: { notes: Note[] }) {
    const [selectedNote, setSelectedNote] = useState<Note | undefined>(undefined);
    return (
        <div className="admin-athlete-content-container">
            <AdminAthleteDetailsSectionHeader text="Notes" />
            <h4>Shared Notes</h4>
            <ul className="no-li-style" style={{ marginLeft: "20px" }}>
                {notes.map(note => (
                    <li key={note.id}>
                        <button
                            className="text-link"
                            style={{ fontSize: 16 }}
                            onClick={() => setSelectedNote(note)}
                        >
                            {noteDescription(note)}
                        </button>
                    </li>
                ))}
            </ul>
            {notes.length === 0 && (
                <>
                    <br />
                    <i>No notes shared with this user</i>
                </>
            )}
            <Modal show={selectedNote !== undefined} hide={() => setSelectedNote(undefined)}>
                <div style={{ maxWidth: 900 }}>
                    {selectedNote && <NoteEntry note={selectedNote} allowActions={false} />}
                </div>
            </Modal>
        </div>
    );
}
