import { AdminAthleteContext } from "data/admin-athlete";
import { useContext } from "react";
import { AdminAthleteDetailsSectionHeader } from "./AdminAthleteDetailsHeader";
import "../Admin.css";
import { AppleIAPEvent } from "data/iap";

function AdminIAPEventRow({ event }: { event: AppleIAPEvent }) {
    return (
        <li>
            {event.date.toLocaleString()} -- {event.notificationType}.{event.subtype}
        </li>
    );
}

export function AdminAthleteIAP() {
    const [{ appleIAPEvents }] = useContext(AdminAthleteContext);
    return (
        <div className="admin-athlete-content-container ">
            <AdminAthleteDetailsSectionHeader text="Apple IAP Events" />
            <ul className="no-li-style">
                {appleIAPEvents
                    .sort((a, b) => b.date.getTime() - a.date.getTime())
                    .map(event => (
                        <AdminIAPEventRow event={event} />
                    ))}
            </ul>
        </div>
    );
}
