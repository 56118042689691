import { Modal } from "components/shared/Modal";
import { disableUser } from "data/admin";
import { User } from "data/user";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fullName } from "../../util";
import "./AdminDeleteUser.css";

export function AdminDisableUser({
    user,
}: {
    user: Pick<User, "id" | "firstName" | "lastName" | "email">;
}) {
    const navigate = useNavigate();
    const [modal, showModal] = useState(false);
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const onDisable = useCallback(async () => {
        const isValid = email.toLocaleLowerCase() === user.email.toLocaleLowerCase();
        setIsValid(isValid);
        if (!isValid) return;
        try {
            await disableUser(user.id);
            navigate("/");
        } catch (e: any) {
            alert(`Error disabling ${fullName(user)}'s account: ${e.message}`);
        }
    }, [email, navigate, user]);

    return (
        <div className="d-flex admin-delete-user">
            <button type="button" className="pill pill-destructive" onClick={() => showModal(true)}>
                Disable User
            </button>
            <Modal show={modal} hide={() => showModal(false)}>
                <div className="d-flex flex-col">
                    <h1>{`Are you sure you want to disable ${fullName(user)}?`}</h1>
                    <p>
                        <b>WARNING: you are about to disable this user account.</b>
                        <br />
                        {fullName(user)} will no longer be able to sign in.
                        <br />
                        Enter {user.firstName}'s email to confirm:
                    </p>
                    <input
                        type="text"
                        className={isValid ? undefined : "error"}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    {isValid || <p className="error-message">Email does not match</p>}
                    <button type="button" className="pill pill-destructive" onClick={onDisable}>
                        DISABLE
                    </button>
                </div>
            </Modal>
        </div>
    );
}
