import { useRef } from "react";
import { useClickOutside } from "../../shared/Modal";
import "./ScheduledSendPopup.css";
import { MessageSendTime } from "../../../util/scheduled-send";

export function SendButtonDropdown({
    hide,
    send,
    showCustomTimeModal,
}: {
    hide: () => void;
    send: (time: MessageSendTime) => void;
    showCustomTimeModal: () => void;
}) {
    const ref = useRef(null);
    useClickOutside(ref, hide);
    return (
        <div ref={ref} className="scheduled-send-dropdown">
            <span className="scheduled-send-dropdown-title">Schedule Message</span>
            <button
                className="scheduled-send-dropdown-item"
                onClick={() => send(MessageSendTime.OneHour)}
            >
                In one hour
            </button>
            <button
                className="scheduled-send-dropdown-item"
                onClick={() => send(MessageSendTime.Tomorrow)}
            >
                Tomorrow at 8:00 AM
            </button>
            <button
                className="scheduled-send-dropdown-item"
                onClick={() => send(MessageSendTime.Monday)}
            >
                Monday at 8:00 AM
            </button>
            <button
                className="scheduled-send-dropdown-item"
                onClick={() => send(MessageSendTime.Wednesday)}
            >
                Wednesday at 8:00 AM
            </button>
            <hr />
            <button className="scheduled-send-dropdown-item" onClick={showCustomTimeModal}>
                Custom time
            </button>
        </div>
    );
}
