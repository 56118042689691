import { useContext, useState } from "react";
import { addMinutes, format, subHours } from "date-fns";
import { useNavigate } from "react-router-dom";
import Popover, { PopoverWithProvider } from "../shared/Popover";
import { Athlete, athleteName, useCoachActions } from "../../data/coach";
import {
    CoachingSessionSeriesInstance,
    isSeriesInstance,
    SessionOrSeriesInstance,
    sessionTypeDescription,
} from "../../data/session";
import Confirmation, { ConfirmationContext } from "../shared/Confirmation";
import { byCreatedAt, fullName } from "../../util";
import _ from "lodash";
import { SessionContext } from "../sessions/CoachSessions";
import { Badge } from "components/shared/Badge";

export function SessionRowWrapper({
    session,
    athletes,
    showDivider,
    showConfirmedStatus = false,
}: {
    session: SessionOrSeriesInstance;
    athletes: Athlete[];
    showDivider: boolean;
    showConfirmedStatus?: boolean;
}) {
    const athlete = athletes.find(a => a.assignmentId === session.assignmentId);
    if (athlete) {
        return (
            <SessionContext.Provider value={{ athlete, session }} key={session.id}>
                <SessionRow showConfirmedStatus={showConfirmedStatus} />
                {showDivider && <hr />}
            </SessionContext.Provider>
        );
    } else {
        return <></>;
    }
}

function SessionRow({ showConfirmedStatus }: { showConfirmedStatus: boolean }) {
    const { session } = useContext(SessionContext);
    const showOptions = session.date > subHours(new Date(), 1);
    const link = showOptions ? session.link : undefined;
    const confirmation = _.last(session.confirmations?.sort(byCreatedAt));
    const defaultShowConfirmationButtons = session.date < new Date() && confirmation === undefined;
    const [showConfirmationButtons, setShowConfirmationButtons] = useState(
        defaultShowConfirmationButtons,
    );
    const defaultShowConfirmationStatus = confirmation !== undefined && showConfirmedStatus;
    const [showPreviousConfirmation, setShowPreviousConfirmation] = useState(
        defaultShowConfirmationStatus,
    );
    // Don't provide a link to view notes if we're already on the assignments screen, with other links to notes tabs
    const showViewNotes = !window.location.pathname.includes("/assignments/");
    const confirmationText = confirmation?.occurred ? "Occurred" : "Didn't occur";
    const confirmationIcon = confirmation?.occurred ? "/checkmark.svg" : "/x.svg";
    const navigate = useNavigate();
    return (
        <div className="session-row">
            <SessionIcon session={session} />
            <div className="session-content">
                <div className="d-flex flex-row" style={{ gap: "1rem" }}>
                    <SessionSubtitle />
                    {session.status === "deleted" && <Badge color="danger" text="Cancelled" />}
                </div>
                <SessionTitle />
                <div className="secondary session-time">
                    {format(session.date, "h:mm aa")}
                    {" - "}
                    {format(addMinutes(session.date, session.duration ?? 40), "h:mm aa")}
                </div>
            </div>
            {showViewNotes ? (
                <button
                    className="plain-button blue-link"
                    onClick={() => navigate(`/assignments/${session.assignmentId}?tabIndex=1`)}
                >
                    View Notes
                </button>
            ) : (
                <></>
            )}
            {link ? (
                <a href={link} className="pill-outline">
                    Join
                </a>
            ) : (
                <></>
            )}
            {showConfirmationButtons && (
                <SessionConfirmation
                    onSave={() => {
                        setShowConfirmationButtons(false);
                        setShowPreviousConfirmation(true);
                    }}
                />
            )}
            {showPreviousConfirmation && confirmation !== undefined && (
                <div className="session-confirmation">
                    <div className="flex-row d-flex align-items-center">
                        <img
                            src={confirmationIcon}
                            alt={confirmationText}
                            className="confirmation-icon"
                        />
                        <div className="secondary" style={{ whiteSpace: "nowrap" }}>
                            {confirmationText}
                        </div>
                    </div>
                    <button
                        className="plain-button blue-link"
                        onClick={() => {
                            setShowConfirmationButtons(true);
                            setShowPreviousConfirmation(false);
                        }}
                    >
                        Update
                    </button>
                </div>
            )}
            {showOptions && (
                <PopoverWithProvider>
                    <Popover.Toggle>
                        <img
                            style={{ position: "relative", top: "2px" }}
                            alt="session options"
                            src="/menu.svg"
                            className="svg-tint-0F2B5B"
                        />
                    </Popover.Toggle>
                    <Popover.Content>
                        <div className="popover-basic-menu">
                            <SessionMenuItems />
                        </div>
                    </Popover.Content>
                </PopoverWithProvider>
            )}
        </div>
    );
}

function SessionMenuItems() {
    const { session } = useContext(SessionContext);
    const {
        cancelSession: { mutateAsync: cancelSession },
        cancelSeries: { mutateAsync: cancelSeries },
        cancelSeriesInstance: { mutateAsync: cancelSeriesInstance },
    } = useCoachActions();

    async function onCancelSeries() {
        if (
            window.confirm(
                "Cancelling this session will remove all sessions from this app, your calendars, and all of the family’s calendars as well.",
            )
        ) {
            try {
                const { seriesId } = session as CoachingSessionSeriesInstance;
                await cancelSeries(seriesId);
            } catch (e) {
                console.error(`error occurred when cancelling session series`, e);
            }
        }
    }

    async function onCancelSeriesInstance() {
        if (
            window.confirm(
                "Cancelling this session will remove this session from this app, your calendars, and all of the family’s calendars as well.",
            )
        ) {
            try {
                const { seriesId, date } = session as CoachingSessionSeriesInstance;
                await cancelSeriesInstance({ seriesId, date });
            } catch (e) {
                console.error(`error occurred when cancelling session series instance`, e);
            }
        }
    }

    async function onCancelSession() {
        if (
            window.confirm(
                "Cancelling this session will remove this session from this app, your calendars, and all of the family’s calendars as well.",
            )
        ) {
            try {
                await cancelSession(session.id);
            } catch (e) {
                console.error(`error occurred when cancelling session`, e);
            }
        }
    }

    if (isSeriesInstance(session)) {
        return (
            <>
                <button onClick={onCancelSeries}>Cancel recurring session</button>
                <button onClick={onCancelSeriesInstance}>Cancel this session</button>
            </>
        );
    } else {
        return <button onClick={onCancelSession}>Cancel session</button>;
    }
}

function SessionSubtitle() {
    const { session } = useContext(SessionContext);
    return <div className="secondary">{sessionTypeDescription(session.sessionType)}</div>;
}

function SessionTitle() {
    const { session, athlete } = useContext(SessionContext);
    switch (session.sessionType) {
        case "athleteCoaching":
            return <h3 className="session-title">{fullName(athleteName(athlete))}</h3>;
        case "parentCheckin":
            return (
                <h3 className="session-title">
                    {athlete.parents.map(p => p.firstName).join(" and ")}{" "}
                    <small>
                        ({athleteName(athlete).firstName}'s{" "}
                        {athlete.parents.length > 1 ? "Parents" : "Parent"})
                    </small>
                </h3>
            );
    }
}

export function SessionIcon({ session }: { session: SessionOrSeriesInstance }) {
    if (isSeriesInstance(session)) {
        return (
            <img
                className="session-icon"
                alt="recurring session icon"
                src="/camera-circle-arrows.svg"
            />
        );
    } else {
        return <img className="session-icon" alt="session icon" src="/camera-circle.svg" />;
    }
}

function SessionConfirmation({ onSave }: { onSave: () => void }) {
    const {
        createSessionConfirmation: { mutateAsync: createSessionConfirmation },
    } = useCoachActions();

    const [showYesModal, setShowYesModal] = useState(false);
    const [showNoModal, setShowNoModal] = useState(false);
    const [noReason, setNoReason] = useState("No Show");
    const [isCancellationViolation, setIsCancellationViolation] = useState(true);
    const { session } = useContext(SessionContext);
    const sessionDetails = isSeriesInstance(session)
        ? { seriesId: session.seriesId, seriesDate: session.date }
        : { sessionId: session.id };

    return (
        <div className="session-confirmation">
            <div className="secondary">Did it happen?</div>
            <div className="d-flex flex-row">
                <button type="button" className="pill" onClick={() => setShowYesModal(true)}>
                    Yes
                </button>
                <button type="button" className="pill" onClick={() => setShowNoModal(true)}>
                    No
                </button>
            </div>

            <ConfirmationContext.Provider value={[showYesModal, setShowYesModal]}>
                <Confirmation title={`Session on ${format(session.date, "MMMM dd, hh:mm a")}`}>
                    <Confirmation.Body>
                        <div>Confirm that this session took place?</div>
                        <br />
                    </Confirmation.Body>
                    <Confirmation.Button
                        label={"Confirm"}
                        onClick={async () => {
                            await createSessionConfirmation({
                                confirmation: { occurred: true },
                                sessionDetails,
                                assignmentId: session.assignmentId,
                            });
                            setShowYesModal(false);
                            onSave();
                        }}
                        isPrimary={true}
                    />
                    <Confirmation.Button label="Nevermind" onClick={() => setShowYesModal(false)} />
                </Confirmation>
            </ConfirmationContext.Provider>

            <ConfirmationContext.Provider value={[showNoModal, setShowNoModal]}>
                <Confirmation title={`Session on ${format(session.date, "MMMM dd, hh:mm a")}`}>
                    <Confirmation.Body>
                        <div>Why didn't this session take place?</div>
                        <br />
                        <>
                            {[
                                "No Show",
                                "Canceled by athlete or parent within 24hr",
                                "Canceled outside 24hr",
                                "Other cancellation",
                            ].map(reason => (
                                <div key={reason}>
                                    <button
                                        className="plain-button"
                                        onClick={() => {
                                            setNoReason(reason);
                                            if (reason === "Canceled outside 24hr") {
                                                setIsCancellationViolation(false);
                                            }
                                        }}
                                    >
                                        <div className="d-flex flex-row" style={{ padding: "8px" }}>
                                            <input
                                                id={reason}
                                                type="radio"
                                                name={reason}
                                                checked={noReason === reason}
                                                readOnly={true}
                                            />
                                            {reason}
                                        </div>
                                    </button>
                                    <br />
                                </div>
                            ))}
                        </>
                        <br />
                        <br />
                        {noReason !== "Canceled outside 24hr" ? (
                            <>
                                <div>
                                    Should this session be forfeited due to our cancellation policy?
                                    Cancellations require 24hr+ notice, but there's no penalty for
                                    the first no-show/late cancellation.
                                </div>
                                <br />
                                <button
                                    className="plain-button"
                                    onClick={() => setIsCancellationViolation(true)}
                                    key="yes"
                                >
                                    <div className="d-flex flex-row" style={{ padding: "8px" }}>
                                        <input
                                            id="yes"
                                            type="radio"
                                            name="yes"
                                            checked={isCancellationViolation}
                                            readOnly={true}
                                        />
                                        Yes
                                    </div>
                                </button>
                                <button
                                    className="plain-button"
                                    onClick={() => setIsCancellationViolation(false)}
                                    key="no"
                                >
                                    <div className="d-flex flex-row" style={{ padding: "8px" }}>
                                        <input
                                            id="no"
                                            type="radio"
                                            name="no"
                                            checked={!isCancellationViolation}
                                            readOnly={true}
                                        />
                                        No
                                    </div>
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </Confirmation.Body>
                    <Confirmation.Button
                        label={"Confirm"}
                        onClick={async () => {
                            await createSessionConfirmation({
                                confirmation: {
                                    occurred: false,
                                    cancellationPolicyViolation: isCancellationViolation,
                                    reason: noReason,
                                },
                                sessionDetails,
                                assignmentId: session.assignmentId,
                            });
                            setShowNoModal(false);
                            onSave();
                        }}
                        isPrimary={true}
                        isDestructive={true}
                    />
                    <Confirmation.Button label="Nevermind" onClick={() => setShowNoModal(false)} />
                </Confirmation>
            </ConfirmationContext.Provider>
        </div>
    );
}
