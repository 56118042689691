import { AthleteSubscriptionEvent } from "data/subscription-event";
import { AdminAthleteResponse, InvitationWithInviter } from "data/admin-athlete";
import * as _ from "lodash";
import { run } from "data/api";
import { AthleteDetails, CoachDetails, User } from "data/user";
import { Context, createContext, useCallback, useState } from "react";
import { CoachingAssignment, CoachingType } from "./assignment";
import { RelationshipInvitation } from "./invitation";
import { Feedback, FeedbackInquiry } from "./feedback";
import { Note } from "./note";
import { CreateCoachDetails } from "components/admin/coach/AdminCreateCoachForm";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { secondaryFirebaseApp } from "vendor/firebase";
import { Plan, SaleSource } from "components/admin/activation_code/form/AdminActivationCodeForm";
import { AthleteRowProps } from "components/admin/one_on_one_tab/AdminUnmatchedAthleteList";
import {
    ActivationCodeSearchResponse,
    PlanFilter,
} from "components/admin/activation_code/AdminActivationCodePage";
import { useQueryClient } from "@tanstack/react-query";

export type AdminStats = {
    athleteCount: number;
    coachCount: number;
    parentCount: number;
    athletesByParentCount: { [parentCount: number]: number };
    parentsByAthleteCount: { [athleteCount: number]: number };
    coachesByAthleteCount: { [athleteCount: number]: number };
    mindsetCount: number;
    nutritionCount: number;
    underThirteenCount: number;
    thirteenToEighteenCount: number;
    eighteenPlusCount: number;
};

export type ParentWithDetails = {
    parent: User;
    athletes: User[];
    invitations: RelationshipInvitation[];
};

export type UserOnDemandStats = {
    id: string;
    courseActions: number;
    skillCompletions: number;
};

export type AdminState = {
    coaches: User[];
    parents: User[];
    parentsWithDetails: ParentWithDetails[];
    stats?: AdminStats;
    invitations?: RelationshipInvitation[];
    feedback: Feedback[];
    feedbackInquiries: FeedbackInquiry[];
    privateCoachingInquiries: PrivateCoachingInquiry[];
    onDemandStats: UserOnDemandStats[];
};

export type PrivateCoachingInquiry = {
    userId: string;
    prefersEmail: boolean;
    prefersPhone: boolean;
    name?: string;
    emailAddress?: string;
    phoneNumber?: string;
};

export type SoftDeleteStatus = "active" | "deleted";
export type ActivationCode = {
    source: SaleSource;
    code: string;
    status: SoftDeleteStatus;
    creatorId?: string;
    maxAthletes: number;
    description?: string;
    createdAt: Date;
    usages?: ActivationCodeUsage[];
    plansForCode?: { planId: string }[];
    pendingUsers?: User[];
};

export type ActivationCodeUsage = {
    code: string;
    userId: string;
    user?: User;
    activationCode?: ActivationCode;
    createdAt: Date;
};

export function isOnDemandOnly(user: User) {
    return user.type === "athlete" && user.athleteDetails?.coachingInterests.length === 0;
}

export type AdminRefreshAction = {
    type: "refresh";
    objects:
        | "coaches"
        | "parents"
        | "qualtrics"
        | "stats"
        | "invitations"
        | "sessions"
        | "feedback"
        | "feedback_inquiries"
        | "private_coaching_inquiries"
        | "on_demand_stats"
        | "activation_codes";
};

export type CreateFeedbackInquiry = {
    type: "create_feedback_inquiry";
    userIds: string[];
};

export type CreateCoachAction = {
    type: "create_coach";
    details: CreateCoachDetails;
};

export type CreateActivationCodeAction = {
    type: "create_activation_code";
    plans: Plan[];
    source: SaleSource;
    maxAthletes: number;
    description: string;
    ncsaClientId?: string;
};

export type UpdateActivationCodeAction = {
    type: "update_activation_code";
    code: string;
    maxAthletes: number;
    description: string;
};

export type DeleteActivationCodeAction = {
    type: "delete_activation_code";
    code: string;
};

export type AdminAction =
    | AdminRefreshAction
    | CreateFeedbackInquiry
    | CreateCoachAction
    | CreateActivationCodeAction
    | UpdateActivationCodeAction
    | DeleteActivationCodeAction;

export async function getAdminAthletes(athleteIds: string[]): Promise<AdminAthleteResponse[]> {
    if (athleteIds.length === 0) return [];
    return run({
        path: "/admin/select/athletes",
        method: "POST",
        body: { athleteIds: Array.from(new Set(athleteIds)) },
    });
}

type AssignmentWithCoach = CoachingAssignment & { coach: { user: User } };
type Parent = { parent: User };
interface UmatchedAthlete extends AthleteDetails {
    relationshipInvitations: InvitationWithInviter[];
    coachingAssignments: AssignmentWithCoach[];
    subscriptionEvents: AthleteSubscriptionEvent[];
    user: User & { parents: Parent[] };
}

export async function getAdminUnmatchedAthletes(): Promise<AthleteRowProps[]> {
    const athletes: UmatchedAthlete[] = await run({
        path: "/admin/unmatched-athletes",
        method: "GET",
    });
    return athletes.map(athlete => ({
        athlete: {
            ...athlete.user,
            athleteDetails: athlete,
        },
        invitation: athlete.relationshipInvitations.filter(
            (i: InvitationWithInviter) => i.inviteeType === "athlete",
        )[0],
        parents:
            athlete.user?.parents.map((p: Parent) => p.parent) ??
            athlete.relationshipInvitations
                .map((i: InvitationWithInviter) => i.inviter)
                .filter((u: User) => u?.type === "parent"),
        assignmentsWithCoach: athlete.coachingAssignments.map((a: any) => ({
            assignment: a,
            coach: a.coach.user,
        })),
        churnedEvents: athlete.subscriptionEvents,
    }));
}

export interface RecentAthlete {
    createdAt: Date;
    firstName: string;
    lastName: string;
    athleteId: string;
    coaches: {
        coachId: string;
        firstName: string;
        lastName: string;
        type: CoachingType;
    }[];
}

export async function getAdminRecentAthletes(createdWithinDays: number): Promise<RecentAthlete[]> {
    const result: any = await run({
        path: "/admin/recently-created-athletes",
        method: "POST",
        body: { createdWithinDays },
    });
    return result.map(
        (r: any): RecentAthlete => ({
            createdAt: r.createdAt,
            firstName: r.firstName,
            lastName: r.lastName,
            athleteId: r.athleteDetails.id,
            coaches: r.athleteDetails.coachingAssignments.map((a: any) => ({
                coachId: a.coach.id,
                type: a.coachingType,
                firstName: a.coach.user.firstName,
                lastName: a.coach.user.lastName,
            })),
        }),
    );
}

async function getAllCoaches(): Promise<User[]> {
    return run({ path: "/admin/coaches", method: "GET" });
}

type AdminParent = User & {
    children: {
        child: User;
    }[];
};

async function getAllParents(): Promise<AdminParent[]> {
    return run({ path: "/admin/parents", method: "GET" });
}

async function getAllInvitations(): Promise<RelationshipInvitation[]> {
    return run({ path: "/admin/invitations", method: "GET" });
}

async function getAdminStats(): Promise<AdminStats> {
    return run({ path: "/admin/stats", method: "GET" });
}

async function refreshQualtrics(): Promise<number> {
    const obj: object = await run({ path: "/admin/qualtrics/submissions", method: "PUT" });
    return _.sum(Object.values(obj));
}

async function getAllFeedback(): Promise<Feedback[]> {
    return run({ path: "/admin/feedback", method: "GET" });
}

async function getAllFeedbackInquiries(): Promise<FeedbackInquiry[]> {
    return run({ path: "/admin/feedback-inquiries", method: "GET" });
}

async function getAllPrivateCoachingInquiries(): Promise<PrivateCoachingInquiry[]> {
    return run({ path: "/admin/private-coaching-inquiries", method: "GET" });
}

async function getAdminOnDemandStats(): Promise<UserOnDemandStats[]> {
    return run({ path: "/admin/on-demand-stats", method: "GET" });
}

export async function adminGetNotes(userId: string): Promise<Note[]> {
    return run({ path: `/admin/users/${userId}/notes`, method: "GET" });
}

export async function getUserFeedback(userId: string): Promise<Feedback[]> {
    return run({ path: `/admin/user/${userId}/feedback`, method: "GET" });
}

export async function createPendingActivation(athleteId: string, activationCode: string) {
    return run({
        path: "/admin/activation-upgrade",
        method: "POST",
        body: {
            athleteId,
            activationCode,
        },
    });
}

export async function getActivationCodes({
    hasUsages,
    withinDays,
    plan,
    pageIndex,
    pageSize,
    search,
}: {
    hasUsages?: boolean;
    withinDays?: number;
    plan?: PlanFilter;
    pageIndex?: number;
    pageSize?: number;
    search?: string;
}): Promise<ActivationCodeSearchResponse> {
    const params = new URLSearchParams();
    if (hasUsages !== undefined) {
        params.append("hasUsages", `${hasUsages}`);
    }
    if (withinDays !== undefined) {
        params.append("withinDays", `${withinDays}`);
    }
    if (plan !== undefined) {
        params.append("plan", plan);
    }
    if (search !== undefined) {
        params.append("search", search);
    }
    if (pageIndex !== undefined && pageSize !== undefined) {
        params.append("pageSize", pageSize.toString());
        params.append("pageIndex", pageIndex.toString());
    }
    const queryString = params.toString().length > 0 ? `?${params.toString()}` : "";
    return run({ path: `/admin/activation-codes${queryString}`, method: "GET" });
}

export async function deleteUser(id: string): Promise<User> {
    return run({ path: `/admin/users/${id}`, method: "DELETE" });
}
export async function disableUser(id: string): Promise<User> {
    return run({ path: `/users/${id}`, method: "DELETE" });
}

async function createCoach(authenticatorId: string, details: CreateCoachDetails): Promise<void> {
    const { firstName, lastName, email, phone, bioLink } = details;
    const user = await run<User>({
        path: `/users`,
        method: "POST",
        body: {
            authenticatorId,
            firstName,
            lastName,
            email,
            phone: phone.length === 0 ? undefined : phone,
            type: "coach",
            status: "active",
        },
    });
    await run<CoachDetails>({
        path: "/coaches",
        method: "POST",
        body: { userId: user.id, bioLink: bioLink.length === 0 ? undefined : bioLink },
    });
}

async function createActivationCode(
    plans: Plan[],
    source: SaleSource,
    maxAthletes: number,
    description?: string,
    ncsaClientId?: string,
) {
    await run({
        path: `/admin/activation-codes`,
        method: "POST",
        body: { plans, source, maxAthletes, description, ncsaClientId },
    });
}

async function updateActivationCode(code: string, maxAthletes: number, description?: string) {
    await run({
        path: `/admin/activation-codes/${code}`,
        method: "PATCH",
        body: {
            maxAthletes,
            description,
        },
    });
}

async function deleteActivationCode(code: string) {
    await run({
        path: `/admin/activation-codes/${code}`,
        method: "DELETE",
    });
}

async function createFeedbackInquiry(userIds: string[]): Promise<FeedbackInquiry[]> {
    return run({
        path: "/admin/feedback-inquiries/batch",
        method: "POST",
        body: { targetUserIds: userIds },
    });
}

const initialState: AdminState = {
    coaches: [],
    parents: [],
    parentsWithDetails: [],
    invitations: [],
    feedback: [],
    feedbackInquiries: [],
    privateCoachingInquiries: [],
    onDemandStats: [],
};

export function useAdminState(): [AdminState, (action: AdminAction) => void] {
    const queryClient = useQueryClient();
    const [state, setState] = useState(initialState);

    const refreshActivationCodes = useCallback(async () => {
        await queryClient.invalidateQueries({
            predicate: ({ queryKey }) =>
                queryKey[0] === "users" && queryKey[2] === "activation-code-usages",
        });
    }, [queryClient]);
    const dispatch = useCallback(
        async (action: AdminAction) => {
            switch (action.type) {
                case "refresh":
                    switch (action.objects) {
                        case "coaches":
                            const coaches = await getAllCoaches();
                            setState(state => ({ ...state, coaches }));
                            break;
                        case "parents":
                            const parents = await getAllParents();
                            const parentsInvitations = await getAllInvitations();
                            const parentsWithDetails = parents.map(parent => ({
                                parent,
                                athletes: parent.children.map(child => child.child),
                                invitations: parentsInvitations.filter(
                                    i => i.inviterId === parent.id,
                                ),
                            }));
                            setState(state => ({ ...state, parents, parentsWithDetails }));
                            break;
                        case "stats":
                            const stats = await getAdminStats();
                            setState(state => ({ ...state, stats }));
                            break;
                        case "qualtrics":
                            await refreshQualtrics();
                            break;
                        case "invitations":
                            const invitations = await getAllInvitations();
                            setState(state => ({ ...state, invitations }));
                            break;
                        case "feedback":
                            const feedback = await getAllFeedback();
                            setState(state => ({ ...state, feedback }));
                            break;
                        case "feedback_inquiries":
                            const feedbackInquiries = await getAllFeedbackInquiries();
                            setState(state => ({ ...state, feedbackInquiries }));
                            break;
                        case "private_coaching_inquiries":
                            const privateCoachingInquiries = await getAllPrivateCoachingInquiries();
                            setState(state => ({ ...state, privateCoachingInquiries }));
                            break;
                        case "on_demand_stats":
                            const onDemandStats = await getAdminOnDemandStats();
                            setState(state => ({ ...state, onDemandStats }));
                            break;
                        case "activation_codes":
                            await refreshActivationCodes();
                            setState(state => ({
                                ...state,
                                d: new Date(), // TEMP: trigger state change. remove when search uses react-query
                            }));
                            break;
                    }
                    break;
                case "create_feedback_inquiry":
                    const inquiries = await createFeedbackInquiry(action.userIds);
                    setState(state => ({
                        ...state,
                        feedbackInquiries: state.feedbackInquiries.concat(inquiries),
                    }));
                    break;
                case "create_coach":
                    const { email, password } = action.details;
                    const temp = secondaryFirebaseApp();
                    const creds = await createUserWithEmailAndPassword(
                        getAuth(temp),
                        email,
                        password,
                    );
                    await createCoach(creds.user.uid, action.details);
                    break;
                case "create_activation_code":
                    const { plans, source, maxAthletes, description, ncsaClientId } = action;
                    await createActivationCode(
                        plans,
                        source,
                        maxAthletes,
                        description,
                        ncsaClientId,
                    );
                    dispatch({ type: "refresh", objects: "activation_codes" });
                    break;
                case "update_activation_code":
                    await updateActivationCode(action.code, action.maxAthletes, action.description);
                    dispatch({ type: "refresh", objects: "activation_codes" });
                    break;
                case "delete_activation_code":
                    await deleteActivationCode(action.code);
                    dispatch({ type: "refresh", objects: "activation_codes" });
                    break;
            }
        },
        [refreshActivationCodes],
    );
    return [state, dispatch];
}

export const AdminContext: Context<[AdminState, (action: AdminAction) => void]> = createContext([
    initialState,
    _ => {},
]);
