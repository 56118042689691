import { useContext, useEffect } from "react";
import { AdminContext, AdminStats } from "../../data/admin";
import { BarChart, BarChartData } from "../shared/BarChart";

function AdminChart({ title, data }: { title: string; data: BarChartData }) {
    return (
        <div>
            <h2 className="section-header">{title}</h2>
            <BarChart data={data} />
        </div>
    );
}

function AdminStatsCharts({ stats }: { stats: AdminStats }) {
    return (
        <>
            <AdminChart
                title="Users by Type"
                data={{
                    Athletes: stats.athleteCount,
                    Parents: stats.parentCount,
                    Coaches: stats.coachCount,
                }}
            />
            <AdminChart title="Athletes by Parent Count" data={stats.athletesByParentCount} />
            <AdminChart title="Parents by Child Count" data={stats.parentsByAthleteCount} />
            <AdminChart title="Coaches by Athlete Count" data={stats.coachesByAthleteCount} />
            <AdminChart
                title="Athletes by Discipline"
                data={{
                    "Mental Performance": stats.mindsetCount,
                    Nutrition: stats.nutritionCount,
                }}
            />
            <AdminChart
                title="Athletes by Age Group"
                data={{
                    "12U": stats.underThirteenCount,
                    "13-18": stats.thirteenToEighteenCount,
                    "18+": stats.eighteenPlusCount,
                }}
            />
        </>
    );
}

export function AdminDashboard() {
    const [adminState, adminDispatch] = useContext(AdminContext);
    useEffect(() => {
        adminDispatch({ type: "refresh", objects: "stats" });
    }, [adminDispatch]);
    return (
        <div style={{ padding: "20px 100px" }}>
            <h1>Admin Dashboard</h1>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {adminState.stats ? <AdminStatsCharts stats={adminState.stats} /> : <></>}
            </div>
        </div>
    );
}
