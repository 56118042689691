import { Context, createContext, useCallback, useState } from "react";
import { run } from "./api";

export type FeedbackType = "app" | "session" | "series" | "nps" | "experience";

export type FeedbackCreateInput = {
    type: FeedbackType;
    rating?: number;
    maxRating: number;
    description: string;
};

export type Feedback = {
    id: string;
    type: FeedbackType;
    rating?: number;
    maxRating: number;
    description: string;
    userId: string;
    sessionId?: string;
    seriesId?: string;
    seriesDate?: Date;
    inquiryId?: string;
    createdAt: Date;
};

export type FeedbackInquiry = {
    id: string;
    targetUserId: string;
    createdAt: Date;
};

export type FeedbackFormState = {
    submitted: boolean;
};

export type FeedbackFormAction = {
    type: "submit";
    inquiryId: string;
    feedback: FeedbackCreateInput[];
};

const initialState = {
    submitted: false,
};

export function useFeedbackFormState(): [FeedbackFormState, (action: FeedbackFormAction) => void] {
    const [state, setState] = useState(initialState);
    const dispatch = useCallback(async (action: FeedbackFormAction) => {
        await Promise.all(
            action.feedback.map(f =>
                run({
                    path: `/feedback-inquiries/${action.inquiryId}/feedback`,
                    method: "POST",
                    body: f,
                }),
            ),
        );
        setState(s => {
            return { ...s, submitted: true };
        });
    }, []);
    return [state, dispatch];
}

export const FeedbackFormContext: Context<
    [FeedbackFormState, (action: FeedbackFormAction) => void]
> = createContext([initialState, _ => {}]);
