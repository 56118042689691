import { ActivationCode, AdminContext, getActivationCodes } from "data/admin";
import { useCallback, useContext, useEffect, useState } from "react";
import { ActivationCodeForm } from "./form/AdminActivationCodeForm";
import { ActivationCodeList } from "./list/AdminActivationCodeList";
import "./AdminActivationCodePage.css";

export type ActivationCodeSearchResponse = {
    results: ActivationCode[];
    totalCount: number;
};
export type PlanFilter = "privateCoaching" | "mvp";

interface PageConfig {
    pageIndex: number;
    pageSize: number;
}
type FormState = {
    hasUsages?: boolean;
    withinDays?: number;
    plan?: PlanFilter;
    search?: string;
};

const parseIntOrUndefined = (s: string) => (isNaN(parseInt(s)) ? undefined : parseInt(s));
const parseBooleanOrUndefined = (s: string) => (s === "undefined" ? undefined : s === "true");
const parsePlanOrUndefined = (s: string) => (s === "undefined" ? undefined : (s as PlanFilter));

const initialState: FormState = { hasUsages: undefined, withinDays: 7, plan: "privateCoaching" };
const initialPageConfig: PageConfig = { pageIndex: 0, pageSize: 10 };

export function AdminActivationCodePage() {
    const [adminState] = useContext(AdminContext);
    const [previousSearch, setPreviousSearch] = useState<FormState>(initialState);
    const [formState, setFormState] = useState<FormState>(initialState);
    const pageConfigState = useState<PageConfig>(initialPageConfig);
    const [pageConfig, setPageConfig] = pageConfigState;

    const [results, setResults] = useState<ActivationCodeSearchResponse>({
        results: [],
        totalCount: 0,
    });
    const { results: activationCodes } = results;

    const search = useCallback(async () => {
        const results = await getActivationCodes({ ...formState, ...pageConfig });
        setPreviousSearch(formState);
        setResults(results);
    }, [formState, pageConfig]);

    useEffect(() => {
        search();
    }, [search, adminState]);

    useEffect(() => {
        setPageConfig(config => ({ ...config, pageIndex: 0 }));
    }, [formState, setPageConfig]);

    const title: string = (() => {
        const { withinDays, hasUsages, search } = previousSearch;
        const usagesString = (() => {
            switch (hasUsages) {
                case undefined:
                    return "Activation codes";
                case true:
                    return "Activation codes with usages";
                case false:
                    return "Activation codes without usages";
            }
        })();
        const withinDaysString =
            withinDays === undefined ? "of all time" : `created within ${withinDays} days`;
        const searchString = search ? `containing '${search}'` : "";
        return `${usagesString} ${withinDaysString} ${searchString}`;
    })();

    const searchForm = (
        <div className="d-flex flex-row">
            <form onSubmit={e => e.preventDefault()}>
                <select
                    onChange={e =>
                        setFormState(state => ({
                            ...state,
                            hasUsages: parseBooleanOrUndefined(e.target.value),
                        }))
                    }
                >
                    <option value="undefined">All activation codes</option>
                    <option value="true">All activation codes with usages</option>
                    <option value="false">All activation codes without usages</option>
                </select>
                <select
                    onChange={e =>
                        setFormState(state => ({
                            ...state,
                            withinDays: parseIntOrUndefined(e.target.value),
                        }))
                    }
                >
                    <option value="7">Last 7 days</option>
                    <option value="30">Last 30 days</option>
                    <option value="365">Last 365 days</option>
                    <option value="undefined">All time</option>
                </select>
                <select
                    onChange={e =>
                        setFormState(state => ({
                            ...state,
                            plan: parsePlanOrUndefined(e.target.value),
                        }))
                    }
                >
                    <option value="privateCoaching">1:1 Clients</option>
                    <option value="mvp">MVP Clients</option>
                    <option value="undefined">All codes</option>
                </select>
                <input
                    type="text"
                    placeholder="Search & press enter..."
                    className="search-input"
                    onKeyPress={(e: any) => {
                        if (e.code === "Enter") {
                            setFormState(state => ({ ...state, search: e.target.value }));
                        }
                    }}
                />
            </form>
        </div>
    );

    const paginationBar = <PageIndex state={pageConfigState} totalCount={results.totalCount} />;

    return (
        <div className="d-flex flex-col" style={{ gap: "3rem" }}>
            <ActivationCodeForm />
            <hr style={{ width: "100%" }} />
            {searchForm}
            <h2>{title}</h2>
            {activationCodes.length > 0 ? (
                <>
                    {paginationBar}
                    <ActivationCodeList activationCodes={activationCodes} />
                    {paginationBar}
                </>
            ) : (
                <div>There are 0 records to show.</div>
            )}
        </div>
    );
}

function PageIndex({
    state,
    totalCount,
}: {
    state: [PageConfig, React.Dispatch<React.SetStateAction<PageConfig>>];
    totalCount: number;
}) {
    const [pageConfig, setPageConfig] = state;
    const { pageIndex, pageSize } = pageConfig;
    const pageCount = Math.ceil(totalCount / pageSize);
    return (
        <div className="d-flex flex-row" style={{ gap: "0.5rem" }}>
            <button
                onClick={() => setPageConfig(config => ({ ...config, pageIndex: 0 }))}
                disabled={pageIndex === 0}
            >
                {"<<"}
            </button>
            <button
                onClick={() =>
                    setPageConfig(config => ({ ...config, pageIndex: config.pageIndex - 1 }))
                }
                disabled={pageIndex === 0}
            >
                {"<"}
            </button>
            <strong className="page-progress">
                {pageIndex + 1} of {pageCount}
            </strong>
            <button
                onClick={() =>
                    setPageConfig(config => ({ ...config, pageIndex: config.pageIndex + 1 }))
                }
                disabled={pageIndex + 1 >= pageCount}
            >
                {">"}
            </button>
            <button
                onClick={() => setPageConfig(config => ({ ...config, pageIndex: pageCount - 1 }))}
                disabled={pageIndex + 1 >= pageCount}
            >
                {">>"}
            </button>
            <small>Total records: {totalCount}</small>
        </div>
    );
}
