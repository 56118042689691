import {
    Placement,
    useDismiss,
    useFloating,
    useInteractions,
} from "@floating-ui/react-dom-interactions";
import React, { Context, createContext, useContext, useState } from "react";
import "./Popover.css";

export const PopoverContext = createContext([false, (_: any) => {}]) as Context<
    [boolean, (isVisible: boolean) => void]
>;

const PopoverToggle = ({ children }: { children: JSX.Element }) => children;
PopoverToggle.defaultProps = {
    popoverRole: "PopoverToggle",
};

const PopoverContent = ({ children }: { children: JSX.Element }) => <>{children}</>;
PopoverContent.defaultProps = {
    popoverRole: "PopoverContent",
};

export function PopoverWithProvider({
    placement,
    children,
}: {
    placement?: Placement;
    children: JSX.Element | JSX.Element[];
}) {
    const state = useState(false);
    return (
        <PopoverContext.Provider value={state}>
            <Popover placement={placement}>{children}</Popover>
        </PopoverContext.Provider>
    );
}

function Popover({
    placement,
    children,
}: {
    placement?: Placement;
    children: JSX.Element | JSX.Element[];
}) {
    const toggle = React.Children.toArray(children).find(
        c => React.isValidElement(c) && c.props.popoverRole === "PopoverToggle",
    );
    const content = React.Children.toArray(children).find(
        c => React.isValidElement(c) && c.props.popoverRole === "PopoverContent",
    );
    const [isOpen, setOpen] = useContext(PopoverContext);
    const { x, y, reference, floating, strategy, context } = useFloating({
        open: isOpen,
        onOpenChange: setOpen,
        placement: placement ?? "bottom-end",
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([useDismiss(context)]);
    if (!toggle) throw Error(`Missing Popover.Toggle child`);
    if (!content) throw Error(`Missing Popover.Content child`);
    return (
        <>
            <button
                {...getReferenceProps({ ref: reference })}
                className="popover-toggle"
                onClick={() => setOpen(!isOpen)}
            >
                {toggle}
            </button>
            {isOpen && (
                <div
                    className="popover-content"
                    {...getFloatingProps({
                        ref: floating,
                        style: {
                            position: strategy,
                            left: x ?? "",
                            top: y ?? "",
                        },
                    })}
                >
                    {content}
                </div>
            )}
        </>
    );
}

export default Object.assign(Popover, {
    Toggle: PopoverToggle,
    Content: PopoverContent,
});
