import "./ScheduledSendCustomTimeModal.css";
import { DateInput } from "../../shared/DateInput";
import { TimeInput } from "../../shared/TimeInput";
import { useState } from "react";
import {
    addHours,
    getDate,
    getHours,
    getMinutes,
    getMonth,
    getYear,
    set,
    startOfHour,
} from "date-fns";
import { currentTimeZoneName, recipientTime } from "../../../util";
import { User } from "../../../data/user";

const initialDate = startOfHour(addHours(new Date(), 1));

export function ScheduledSendCustomTimeModal({
    recipient,
    close,
    send,
}: {
    recipient: User | undefined;
    close: () => void;
    send: (time: Date) => void;
}) {
    const [date, setDate] = useState(initialDate);
    const setDay = (day: Date) => {
        setDate(
            set(date, {
                year: getYear(day),
                month: getMonth(day),
                date: getDate(day),
            }),
        );
    };
    const setTime = (time: Date) => {
        setDate(
            set(date, {
                hours: getHours(time),
                minutes: getMinutes(time),
                seconds: 0,
                milliseconds: 0,
            }),
        );
    };
    const submit = () => {
        send(date);
    };
    const recipientTimeString = recipientTime(date, recipient);
    return (
        <div className="scheduled-send-custom-time-modal">
            <span className="modal-title">Custom Time</span>
            <div className="row" style={{ gap: "4px", marginLeft: "22px" }}>
                <img src="/globe.svg" alt="globe" />
                <span className="modal-subtitle">{currentTimeZoneName()}</span>
            </div>
            <div className="scheduled-send-custom-time-modal-input-row">
                <DateInput date={date} setDate={setDay} />
                <span style={{ width: "16px", flexShrink: 0 }} />
                <TimeInput time={date} setTime={setTime} />
            </div>
            {recipientTimeString ? (
                <p className="scheduled-send-custom-time-modal-recipient-time">
                    {recipientTimeString}
                </p>
            ) : (
                <></>
            )}
            <div className="scheduled-send-custom-time-modal-button-row">
                <span style={{ flexGrow: 1 }} />
                <button className="scheduled-send-custom-time-modal-cancel-button" onClick={close}>
                    Cancel
                </button>
                <button
                    className="pill scheduled-send-custom-time-modal-submit-button"
                    onClick={submit}
                >
                    Schedule Message
                </button>
            </div>
        </div>
    );
}
