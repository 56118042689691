import { useState } from "react";
import "./SendButton.css";
import { SendButtonDropdown } from "./ScheduledSendPopup";
import { MessageSendTime } from "../../../util/scheduled-send";
import { Modal } from "../../shared/Modal";
import { ScheduledSendCustomTimeModal } from "./ScheduledSendCustomTimeModal";
import classNames from "classnames";
import { User } from "../../../data/user";

export function SendButton({
    recipient,
    enabled,
    send,
}: {
    recipient: User | undefined;
    enabled: boolean;
    send: (time: MessageSendTime | Date) => void;
}) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showCustomTimeModal, setShowCustomTimeModal] = useState(false);
    const className = classNames({
        "send-button": true,
        "send-button--disabled": !enabled,
    });
    return (
        <div className={className}>
            <button
                className="send-button--segment send-button--send"
                onClick={() => send(MessageSendTime.Instant)}
            >
                Send
            </button>
            <div className="send-button--separator-container">
                <div className="send-button--separator" />
            </div>
            <button
                className="send-button--segment send-button--dropdown"
                onClick={() => setShowDropdown(true)}
                style={{
                    pointerEvents: showDropdown ? "none" : "inherit",
                }}
            >
                <img alt="send options" src="/down-chevron.svg" />
            </button>
            {showDropdown ? (
                <SendButtonDropdown
                    hide={() => setShowDropdown(false)}
                    send={time => {
                        send(time);
                        setShowDropdown(false);
                    }}
                    showCustomTimeModal={() => {
                        setShowDropdown(false);
                        setShowCustomTimeModal(true);
                    }}
                />
            ) : (
                <></>
            )}
            <Modal show={showCustomTimeModal} hide={() => setShowCustomTimeModal(false)}>
                <ScheduledSendCustomTimeModal
                    recipient={recipient}
                    close={() => setShowCustomTimeModal(false)}
                    send={(time: Date) => {
                        send(time);
                        setShowCustomTimeModal(false);
                    }}
                />
            </Modal>
        </div>
    );
}
