import { Context, createContext, useCallback, useContext, useEffect, useState } from "react";
import { AdminContext, AdminState, getAdminAthletes, ParentWithDetails } from "../../data/admin";
import { Feedback, FeedbackInquiry } from "../../data/feedback";
import { getAdminUsers, User } from "../../data/user";
import { byFirstName, fullName } from "../../util";
import { format, subDays } from "date-fns";
import { Modal } from "../shared/Modal";
import * as dt from "date-fns";
import _ from "lodash";
import { AdminAthleteResponse } from "data/admin-athlete";

interface ReviewsState {
    users: User[];
    athletes: AdminAthleteResponse[];
}

function useReviews(): [ReviewsState, () => void] {
    const [{ feedback, feedbackInquiries, parentsWithDetails }] = useContext(AdminContext);
    const [state, setState] = useState<ReviewsState>({ users: [], athletes: [] });
    const dispatch = useCallback(async () => {
        const userIds = feedback
            .map(f => f.userId)
            .concat(feedbackInquiries.map(i => i.targetUserId));
        const athleteIds = parentsWithDetails
            .map(p => p.athletes)
            .flat()
            .map(a => a.athleteDetails?.id)
            .filter((id): id is string => !!id);
        const users = await getAdminUsers([], userIds);
        const athletes = await getAdminAthletes(athleteIds);
        setState(state => ({ ...state, users, athletes }));
    }, [feedback, feedbackInquiries, parentsWithDetails]);
    return [state, dispatch];
}

const initial: ReviewsState = { athletes: [], users: [] };
const ReviewsContext: Context<[ReviewsState, () => void]> = createContext([initial, () => {}]);

function lastSentInquiry(adminState: AdminState, parent: User): FeedbackInquiry | undefined {
    return adminState.feedbackInquiries
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
        .find(i => i.targetUserId === parent.id);
}

function batchFormHistory(adminState: AdminState, parent: User): string {
    const lastSent = lastSentInquiry(adminState, parent);
    const responsesToLastSent = lastSent ? feedbackForInquiry(adminState, lastSent) : [];
    const lastSentText = lastSent ? format(lastSent.createdAt, "MMMM dd, yyyy") : "never";
    let respondedText = "";
    if (lastSent) {
        const responseDate = _.first(responsesToLastSent)?.createdAt;
        if (responseDate) respondedText = `; Responded ${format(responseDate, "MM-dd-yyyy")}`;
        else respondedText = `; No response`;
    }
    return `Last Sent: ${lastSentText}${respondedText}`;
}

function activeAthletes(
    parent: User,
    details: ParentWithDetails[],
    athletes: AdminAthleteResponse[],
) {
    const d = details.find(p => p.parent.id === parent.id);
    const athleteIds = d?.athletes.map(a => a.id);
    return athletes
        .filter(a => athleteIds?.includes(a.user?.id ?? ""))
        .filter(
            a =>
                a.subscriptionEvents
                    .filter(e => e.type === "churned")
                    .filter(e => a.coachingInterests.includes(e.discipline)).length === 0,
        )
        .filter(a => a.user?.status !== "suspended");
}

export function AdminReviews() {
    const [{ feedback, feedbackInquiries }, adminDispatch] = useContext(AdminContext);
    const context = useReviews();
    const [, dispatch] = context;
    useEffect(() => {
        adminDispatch({ type: "refresh", objects: "feedback" });
        adminDispatch({ type: "refresh", objects: "parents" });
        adminDispatch({ type: "refresh", objects: "feedback_inquiries" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        dispatch();
    }, [dispatch, feedback, feedbackInquiries]);
    return (
        <div className="admin-sessions">
            <div className="d-flex flex-no-wrap justify-content-center" id="list-container">
                <ReviewsContext.Provider value={context}>
                    <IncompleteProductReviews />
                </ReviewsContext.Provider>
            </div>
        </div>
    );
}

function TriggerBatchFeedbackModal({ close }: { close: () => void }) {
    const [adminState, adminDispatch] = useContext(AdminContext);
    const [{ athletes }] = useContext(ReviewsContext);
    const [selected, setSelected] = useState(new Set<string>());
    const selectDefaults = useCallback(async () => {
        const ids = adminState.parents
            .filter(p => !p.adminTestUser)
            .filter(p => dt.add(p.createdAt, { months: 1 }) < new Date())
            .filter(p => activeAthletes(p, adminState.parentsWithDetails, athletes).length > 0)
            .filter(p => {
                const feedback = adminState.feedback.filter(
                    f =>
                        f.userId === p.id &&
                        f.type === "nps" &&
                        dt.add(f.createdAt, { months: 1 }) > new Date(),
                );
                return feedback.length === 0;
            })
            .filter(p => {
                const inquiries = adminState.feedbackInquiries.filter(
                    i => dt.add(i.createdAt, { days: 7 }) > new Date() && i.targetUserId === p.id,
                );
                return inquiries.length === 0;
            })
            .map(p => p.id);
        setSelected(new Set(ids));
    }, [adminState, athletes]);
    useEffect(() => {
        selectDefaults();
    }, [selectDefaults]);
    const submit = () => {
        adminDispatch({ type: "create_feedback_inquiry", userIds: [...selected] });
        close();
    };
    const ParentRow = ({ p }: { p: User }) => {
        const [{ parentsWithDetails }] = useContext(AdminContext);
        const [{ athletes }] = useContext(ReviewsContext);
        const active = activeAthletes(p, parentsWithDetails, athletes);
        const status =
            active.length > 0 ? `${active.length} active athletes` : "No active athletes";
        return (
            <div className="send-feedback-parent-row" key={p.id}>
                <div className="d-flex flex-col" style={{ width: "100%" }}>
                    <div className="d-flex flex-row">
                        <div className="primary">
                            {fullName(p)}
                            <span className="secondary">
                                {` (Created ${format(p.createdAt, "MM-dd-yy")})`}
                            </span>
                        </div>
                    </div>
                    <div className="secondary">{batchFormHistory(adminState, p)}</div>
                    <div className="secondary">{status}</div>
                </div>
                <input
                    className="float-right"
                    type="checkbox"
                    checked={selected.has(p.id)}
                    onChange={() => {
                        const newSelected = new Set(selected);
                        if (selected.has(p.id)) newSelected.delete(p.id);
                        else newSelected.add(p.id);
                        setSelected(newSelected);
                    }}
                />
            </div>
        );
    };
    return (
        <div className="admin-send-feedback-modal">
            <h1>Trigger Product Review Send</h1>
            <br />
            <div>
                Default parents are selected who:
                <li>Have an account older than 1 month</li>
                <li>Have at least 1 active athlete</li>
                <li>Have not submitted any reviews in the last month</li>
                <li>Have not been asked to review in the last week</li>
            </div>
            <br />
            <button
                onClick={() => setSelected(new Set(adminState.parents.map(p => p.id)))}
                className="p-1"
            >
                Select All
            </button>
            <button onClick={() => setSelected(new Set())} className="p-1">
                Clear
            </button>
            <button onClick={() => selectDefaults()} className="p-1">
                Select Default
            </button>
            <br />
            <br />
            <ul className="no-li-style" style={{ maxHeight: 600, overflow: "auto" }}>
                {adminState.parents.sort(byFirstName).map(p => (
                    <ParentRow p={p} key={p.id} />
                ))}
            </ul>
            <br />
            <button onClick={submit} className="p-1">
                Submit
            </button>
            <div className="float-right">{selected.size} selected</div>
        </div>
    );
}

function feedbackForInquiry(adminState: AdminState, inquiry: FeedbackInquiry): Feedback[] {
    return adminState.feedback.filter(f => f.inquiryId === inquiry.id);
}

function incompleteInquiries(adminState: AdminState): FeedbackInquiry[] {
    return adminState.feedbackInquiries
        .filter(i => feedbackForInquiry(adminState, i).length === 0)
        .filter(i => i.createdAt > subDays(new Date(), 30))
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
}

function PendingInquiryRow({ inquiry }: { inquiry: FeedbackInquiry }) {
    const [{ users }] = useContext(ReviewsContext);
    const user = users.find(u => u.id === inquiry.targetUserId);
    return (
        <div className="feedback-row">
            <div className="primary">
                {fullName(user)} ({user?.type})
            </div>
            <div className="secondary">{format(inquiry.createdAt, "EEE MMM do, h:mm bb")}</div>
        </div>
    );
}

function groupByDate(inquiries: FeedbackInquiry[]): { [date: string]: FeedbackInquiry[] } {
    return inquiries.reduce<{ [date: string]: FeedbackInquiry[] }>((result, inquiry) => {
        const key = inquiry.createdAt.toDateString();
        if (result[key]) {
            result[key]!.push(inquiry);
        } else {
            result[key] = [inquiry];
        }
        return result;
    }, {});
}

function InquirySummaries() {
    const [adminState] = useContext(AdminContext);
    const byDate = groupByDate(adminState.feedbackInquiries);
    return (
        <>
            {Object.keys(byDate)
                .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
                .map(dateString => {
                    const inquiries = byDate[dateString];
                    const respondedCount = inquiries?.filter(
                        i => feedbackForInquiry(adminState, i).length > 0,
                    ).length;
                    return (
                        <div className="feedback-inquiry-summary primary" key={dateString}>
                            {dateString}:{" "}
                            <span className="secondary">
                                {inquiries?.length} sent ({respondedCount} responses)
                            </span>
                        </div>
                    );
                })}
        </>
    );
}

function IncompleteProductReviews() {
    const [adminState] = useContext(AdminContext);
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Modal show={showModal} hide={() => setShowModal(false)}>
                <TriggerBatchFeedbackModal close={() => setShowModal(false)} />
            </Modal>
            <div className="admin-list-container">
                <h2 className="section-header float-left">Triggered Reviews by Date</h2>
                <div className="d-flex flex-col align-items-end float-right">
                    <button onClick={() => setShowModal(true)}>Trigger Batch</button>
                </div>
                <br />
                <br />
                <InquirySummaries />
                <br />
                <br />
                <h2 className="section-header">All Pending Reviews (no response, last 30d)</h2>
                <ul className="no-li-style">
                    {incompleteInquiries(adminState).map(i => {
                        return <PendingInquiryRow inquiry={i} key={i.id} />;
                    })}
                </ul>
            </div>
        </>
    );
}
