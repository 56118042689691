import { RelationshipInvitation } from "./invitation";
import { User } from "./user";
import { run } from "./api";

type OnboardingCreatedAccountEvent = {
    type: "created_account";
    date: Date;
};

type OnboardingSentInvitationEvent = {
    type: "sent_invitation";
    date: Date;
    invitation: RelationshipInvitation;
};

type OnboardingSentInvitationAcceptedEvent = {
    type: "sent_invitation_accepted";
    date: Date;
    invitation: RelationshipInvitation & {
        accepter: User;
    };
};

type OnboardingReceivedInvitationEvent = {
    type: "received_invitation";
    date: Date;
    invitation: RelationshipInvitation & {
        inviter: User;
    };
};

type OnboardingAcceptedInvitationEvent = {
    type: "accepted_invitation";
    date: Date;
    invitation: RelationshipInvitation & {
        inviter: User;
    };
};

export type OnboardingEvent =
    | OnboardingCreatedAccountEvent
    | OnboardingSentInvitationEvent
    | OnboardingSentInvitationAcceptedEvent
    | OnboardingReceivedInvitationEvent
    | OnboardingAcceptedInvitationEvent;

export async function getOnboardingHistory(userId: string): Promise<OnboardingEvent[]> {
    return await run({ path: `/admin/users/${userId}/onboarding-history`, method: "GET" });
}
