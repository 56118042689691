import { markTestUser } from "data/user";

export function AdminMarkAsTestUser({
    userId,
    onComplete,
    isTestUser,
}: {
    userId: string;
    isTestUser: boolean;
    onComplete: () => void;
}) {
    async function onClick() {
        await markTestUser(userId, !isTestUser);
        onComplete();
    }
    return (
        <button className="pill-outline" onClick={onClick}>
            {isTestUser ? "Undo mark" : "Mark"} as test user
        </button>
    );
}
