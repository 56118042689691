import React, { useContext } from "react";
import { AdminAthleteContext } from "../../../data/admin-athlete";

export function AdminAthleteDetailsAssessments() {
    const [athleteState] = useContext(AdminAthleteContext);
    return (
        <div className="admin-athlete-content-container">
            <h2 className="section-header">Assessment Submissions</h2>
            <ul className="no-li-style">
                {athleteState.assessmentSubmissions.map(submission => (
                    <li key={submission.id}>
                        {submission.template.title} - {submission.createdAt.toDateString()}
                    </li>
                ))}
            </ul>
        </div>
    );
}
