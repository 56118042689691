import { AdminContext } from "data/admin";
import { useContext, useState } from "react";
import styles from "./AdminActivationCodeForm.module.css";

const PLANS = ["mvp", "privateCoachingNutrition", "privateCoachingMentalPerformance"] as const;
export type Plan = typeof PLANS[number];
export type SaleSource = "direct" | "imgAcademy" | "ncsa";

export function planTitle(plan: Plan): string {
    switch (plan) {
        case "mvp":
            return "MVP";
        case "privateCoachingMentalPerformance":
            return "1:1 Mental Performance";
        case "privateCoachingNutrition":
            return "1:1 Nutrition";
    }
}

type FormState = {
    plans: Plan[];
    source: SaleSource;
    maxAthletes: number;
    description: string;
    ncsaClientId?: string;
};

const initialState: FormState = {
    plans: [],
    source: "direct",
    maxAthletes: 1,
    description: "",
    ncsaClientId: undefined,
};

export function ActivationCodeForm() {
    const [, dispatch] = useContext(AdminContext);
    const [state, setState] = useState<FormState>(initialState);

    function onToggleProduct(plan: Plan) {
        if (state.plans.includes(plan)) {
            setState(state => ({ ...state, plans: state.plans.filter(p => p !== plan) }));
        } else {
            setState(state => ({ ...state, plans: [...state.plans, plan] }));
        }
    }

    async function onCreate() {
        if (!isValid()) {
            alert("Your entry is invalid. Please try again.");
            return;
        }
        try {
            await dispatch({
                type: "create_activation_code",
                ...state,
            });
            setState(initialState);
        } catch (e) {
            alert("There was a problem creating this activation code");
        }
    }

    function isValid() {
        return state.plans.length > 0 && state.maxAthletes !== null && state.maxAthletes > 0;
    }

    const productField = (
        <div className={styles["form-group"]}>
            <label>Products</label>
            {PLANS.map(plan => (
                <div key={`${plan}`} className={styles["form-group"]}>
                    <input
                        type="checkbox"
                        id={`checkbox-${plan}`}
                        checked={state.plans.includes(plan)}
                        onChange={() => onToggleProduct(plan)}
                    />
                    <label htmlFor={`checkbox-${plan}`}>{planTitle(plan)}</label>
                </div>
            ))}
        </div>
    );

    const sourceField = (
        <div className={styles["form-group"]}>
            <label>Source</label>
            <br />
            <select
                value={state.source}
                onChange={e => {
                    setState(state => ({
                        ...state,
                        source: e.target.value as SaleSource,
                        ncsaClientId: undefined,
                    }));
                }}
            >
                <option value="direct">Direct</option>
                <option value="ncsa">NCSA</option>
                <option value="imgAcademy">IMG Academy</option>
            </select>
        </div>
    );
    const ncsaClientIdField = (
        <div className={styles["form-group"]}>
            <label>NCSA Client ID</label>
            <br />
            <input
                value={state.ncsaClientId ?? ""}
                disabled={state.source !== "ncsa"}
                onChange={e => setState(state => ({ ...state, ncsaClientId: e.target.value }))}
            />
        </div>
    );

    const maxAthletesField = (
        <div className={styles["form-group"]}>
            <label>Max Athletes</label>
            <br />
            <input
                value={state.maxAthletes}
                type="number"
                onChange={e => {
                    setState(state => ({ ...state, maxAthletes: parseInt(e.target.value) }));
                }}
            ></input>
        </div>
    );

    const descriptionField = (
        <div className={styles["form-group"]}>
            <label>Description</label>
            <br />
            <textarea
                style={{ width: "100%" }}
                rows={5}
                value={state.description}
                onChange={e => setState(state => ({ ...state, description: e.target.value }))}
            />
        </div>
    );

    return (
        <div style={{ width: "500px" }}>
            <form className={styles.form}>
                <h2>Create Activation Code</h2>
                {productField}
                {sourceField}
                {ncsaClientIdField}
                {maxAthletesField}
                {descriptionField}
                <button className="pill filled" type="button" onClick={onCreate}>
                    Create Activation Code
                </button>
            </form>
        </div>
    );
}
