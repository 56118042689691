import { Plan, planTitle } from "components/admin/activation_code/form/AdminActivationCodeForm";
import { useActivationCodeUsages } from "data/activation-code";
import { ActivationCode, ActivationCodeUsage } from "data/admin";

const plansFromCode = (activationCode: ActivationCode): string =>
    (activationCode.plansForCode ?? []).map(p => planTitle(p.planId as Plan)).join(", ");

export function Activations({ athleteUserId }: { athleteUserId: string }) {
    const { data: usages = [] } = useActivationCodeUsages(athleteUserId);
    return (
        <div>
            {usages.length > 0 ? (
                usages.map(usage => <ActivationDetails key={usage.code} usage={usage} />)
            ) : (
                <>Currently nothing to show.</>
            )}
        </div>
    );
}

function ActivationDetails({ usage }: { usage: ActivationCodeUsage }) {
    return usage.activationCode ? (
        <div>
            <h4>{plansFromCode(usage.activationCode)}</h4>
            <small>Source: {usage.activationCode.source}</small>
            <br />
            <small>Start Date: {usage.createdAt.toLocaleDateString()}</small>
            <br />
            <small>Description: {usage.activationCode.description}</small>
        </div>
    ) : (
        <></>
    );
}
