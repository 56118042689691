import { addDays, addHours, nextMonday, nextWednesday } from "date-fns";

export enum MessageSendTime {
    Instant,
    OneHour,
    Tomorrow,
    Monday,
    Wednesday,
}

export function getSendTime(sendTime: MessageSendTime): Date {
    const now = new Date();
    switch (sendTime) {
        case MessageSendTime.Instant:
            return now;
        case MessageSendTime.OneHour:
            return addHours(now, 1);
        case MessageSendTime.Tomorrow:
            const tomorrow = addDays(now, 1);
            tomorrow.setHours(8, 0, 0, 0);
            return tomorrow;
        case MessageSendTime.Monday:
            const monday = nextMonday(now);
            monday.setHours(8, 0, 0, 0);
            return monday;
        case MessageSendTime.Wednesday:
            const wednesday = nextWednesday(now);
            wednesday.setHours(8, 0, 0, 0);
            return wednesday;
    }
}
