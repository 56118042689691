import "./Avatar.css";
import { useUserAvatar } from "../../data/avatar";

type AvatarSize = "small" | "large";

type AvatarProps = {
    size: AvatarSize;
};
export function Avatar({ url, size }: AvatarProps & { url?: string }) {
    const className = size === "small" ? "avatar-small" : "avatar-large";
    return <img className={className} alt="avatar" src={url ?? "/avatar.png"} />;
}

export function UserAvatar({ userId, ...props }: AvatarProps & { userId: string }) {
    const { data: avatarUrl } = useUserAvatar(userId);
    return <Avatar url={avatarUrl} {...props} />;
}
