import React, { useContext } from "react";
import { AdminContext, useAdminState } from "data/admin";
import { Outlet, useSearchParams } from "react-router-dom";
import { UserContext } from "../../data/user";
import { Unauthorized } from "../Unauthorized";
import "./AdminHome.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AdminReviews } from "./AdminReviews";
import { AdminOneOnOneTab } from "./one_on_one_tab/AdminOneOnOneTab";
import { AdminGroupCourses } from "./group_courses/AdminGroupCourses";
import { AdminActivationCodePage } from "./activation_code/AdminActivationCodePage";
import { AdminSearch } from "./AdminSearch";
import { ReportingPage } from "components/admin/ReportingPage";

export function AdminHome() {
    const [searchParams, setSearchParams] = useSearchParams();
    const tabIndex = searchParams.get("tabIndex");
    const defaultIndex = tabIndex ? Number.parseInt(tabIndex) : 0;
    return (
        <Tabs
            defaultIndex={Number.isNaN(defaultIndex) ? 0 : defaultIndex}
            onSelect={index => setSearchParams({ tabIndex: index.toString() })}
            className="tabs"
            selectedTabClassName="tab-selected"
            selectedTabPanelClassName="tab-panel-selected"
        >
            <TabList className="no-li-style tab-list justify-content-center">
                <Tab className="tab">Users</Tab>
                <Tab className="tab">Search</Tab>
                <Tab className="tab">Reviews</Tab>
                <Tab className="tab">Group Courses</Tab>
                <Tab className="tab">Activation Codes</Tab>
                <Tab className="tab">Reporting</Tab>
            </TabList>
            <TabPanel className="tab-panel justify-content-center">
                <AdminOneOnOneTab />
            </TabPanel>
            <TabPanel className="tab-panel justify-content-center">
                <AdminSearch />
            </TabPanel>
            <TabPanel className="tab-panel justify-content-center">
                <AdminReviews />
            </TabPanel>
            <TabPanel className="tab-panel justify-content-center">
                <AdminGroupCourses />
            </TabPanel>
            <TabPanel className="tab-panel justify-content-center">
                <AdminActivationCodePage />
            </TabPanel>
            <TabPanel className="tab-panel justify-content-center">
                <ReportingPage />
            </TabPanel>
        </Tabs>
    );
}

export function AdminWrapper() {
    const [userState] = useContext(UserContext);
    const adminContext = useAdminState();
    return userState.user?.type === "admin" ? (
        <AdminContext.Provider value={adminContext}>
            <Outlet />
        </AdminContext.Provider>
    ) : (
        <Unauthorized />
    );
}
