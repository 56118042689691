import { CoachingMatchPreferences } from "data/coach";
import { run } from "./api";
export async function upsertMatchPreferences(preferences: CoachingMatchPreferences) {
    const { coachId } = preferences;
    return await run({
        path: `/coaches/${coachId}/coaching-match-preferences`,
        method: "PUT",
        body: preferences,
    });
}
