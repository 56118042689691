import { User, getUser, AthleteDetails } from "data/user";
import { run } from "data/api";
import { Context, createContext, useCallback, useState } from "react";
import {
    CoachingSession,
    CoachingSessionSeries,
    getAllSessionsForAssignment,
    getSeries,
} from "data/session";
import { CoachingAssignment, CoachingType } from "./assignment";
import { AthleteSubscriptionEvent } from "./subscription-event";

export type AthleteState = {
    user?: User;
    athlete?: AthleteDetails;
    coaches: Coach[];
    parents: User[];
    assessmentSubmissions: AssessmentSubmission[];
    invited: boolean;
    subscriptionEvents: AthleteSubscriptionEvent[];
};

export type Coach = {
    coachId: string;
    userId: string;
    assignmentId: string;
    coachingType: CoachingType;
    user: User;
    series: CoachingSessionSeries[];
    upcomingSessions: CoachingSession[];
    pastSessions: CoachingSession[];
};

export type AssessmentTemplate = {
    id: string;
    title: string;
};

export type AssessmentSubmission = {
    id: string;
    template: AssessmentTemplate;
    createdAt: Date;
};

export async function getParents(userId: string): Promise<User[]> {
    const parents: { parentId: string }[] = await run({
        path: `/users/${userId}/parents`,
        method: "GET",
    });
    return await Promise.all(parents.map(async parent => await getUser(parent.parentId)));
}

async function getCoaches(athleteId: string): Promise<Coach[]> {
    const assignments: CoachingAssignment[] = await run({
        path: `/athletes/${athleteId}/coaching-assignments`,
        method: "GET",
    });
    return await Promise.all(
        assignments.map(async assignment => {
            const { userId } = await run<Coach>({
                path: `/coaches/${assignment.coachId}`,
                method: "GET",
            });
            const user = await getUser(userId);
            const series = await getSeries(assignment.id);
            const allSessions = await getAllSessionsForAssignment(assignment.id);
            const now = new Date();
            const upcomingSessions = allSessions.filter(session => session.date >= now);
            const pastSessions = allSessions.filter(session => session.date < now);
            return {
                coachId: assignment.coachId,
                assignmentId: assignment.id,
                coachingType: assignment.coachingType,
                series,
                user,
                upcomingSessions,
                pastSessions,
                userId,
            };
        }),
    );
}

async function getAssessmentSubmissions(athleteId: string): Promise<AssessmentSubmission[]> {
    return run({
        path: `/athletes/${athleteId}/assessment-submissions`,
        method: "GET",
    });
}

async function getAthlete(athleteId: string): Promise<AthleteDetails> {
    return await run({ path: `/athletes/${athleteId}`, method: "GET" });
}

export async function getSubscriptionEvents(
    athleteId: string,
): Promise<AthleteSubscriptionEvent[]> {
    return run({ path: `/athletes/${athleteId}/subscription-events`, method: "GET" });
}

export type AthleteRefreshAction = {
    type: "refresh";
};

export type AthleteAction = AthleteRefreshAction;

const initialState: AthleteState = {
    coaches: [],
    parents: [],
    assessmentSubmissions: [],
    invited: false,
    subscriptionEvents: [],
};

export function useAthlete(athleteId: string): [AthleteState, (action: AthleteAction) => void] {
    const [state, setState] = useState<AthleteState>(initialState);
    const dispatch = useCallback(
        async (action: AthleteAction) => {
            switch (action.type) {
                case "refresh":
                    const athlete = await getAthlete(athleteId);
                    const coaches = await getCoaches(athleteId);
                    const assessmentSubmissions = await getAssessmentSubmissions(athleteId);
                    let user: User | undefined = undefined;
                    let parents: User[] = [];
                    if (athlete.userId) {
                        user = await getUser(athlete.userId);
                        parents = (await getParents(user.id)) ?? [];
                    }
                    let subscriptionEvents = await getSubscriptionEvents(athleteId);
                    setState({
                        athlete,
                        user,
                        coaches,
                        parents: parents,
                        assessmentSubmissions,
                        invited: !user,
                        subscriptionEvents,
                    });
                    break;
            }
        },
        [athleteId],
    );
    return [state, dispatch];
}

export const AthleteContext: Context<[AthleteState, (action: AthleteAction) => void]> =
    createContext([initialState, _ => {}]);
