import * as _ from "lodash";
import { run } from "./api";
import { addDays, subHours, subMonths } from "date-fns";
import { byCreatedAt } from "../util";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "./query";

export type SessionType = "athleteCoaching" | "parentCheckin";

export function sessionTypeDescription(sessionType: SessionType): string {
    switch (sessionType) {
        case "athleteCoaching":
            return "Coaching Session";
        case "parentCheckin":
            return "Parent Check-in";
    }
}

export type PostSessionConfirmation = {
    occurred: boolean;
    reason?: string;
    seriesDate?: Date;
    createdAt: Date;
    cancellationPolicyViolation: boolean;
};

export type SoftDeleteStatus = "active" | "deleted";
export type CoachingSession = {
    id: string;
    date: Date;
    sessionType: SessionType;
    assignmentId: string;
    duration?: number;
    link?: string;
    confirmations?: PostSessionConfirmation[];
    status: SoftDeleteStatus;
};

export type CoachingSessionSeries = {
    id: string;
    start: Date;
    sessionType: SessionType;
    assignmentId: string;
    duration?: number;
    link?: string;
    rrule: string;
    confirmations?: PostSessionConfirmation[];
    exceptions?: CoachingSessionSeriesException[];
    status: SoftDeleteStatus;
    canceledAt?: Date;
};

export type CoachingSessionSeriesInstance = {
    status: SoftDeleteStatus;
    date: Date;
    seriesId: string;
} & CoachingSessionSeries;

export type SessionOrSeriesInstance = CoachingSession | CoachingSessionSeriesInstance;

export function isSeriesInstance(
    session: SessionOrSeriesInstance,
): session is CoachingSessionSeriesInstance {
    return "rrule" in session;
}

export function lastConfirmation(
    session: SessionOrSeriesInstance,
): PostSessionConfirmation | undefined {
    return _.last(session.confirmations?.sort(byCreatedAt));
}

async function expandSeries(
    series: CoachingSessionSeries,
    start: Date,
    end: Date,
): Promise<CoachingSessionSeriesInstance[]> {
    const {
        dates,
        confirmations,
        exceptions,
    }: {
        dates: Date[];
        confirmations: PostSessionConfirmation[];
        exceptions: CoachingSessionSeriesException[];
    } = await run({
        path: `/coaching-session-series/${series.id}/expanded`,
        method: "GET",
        params: {
            start: start.toISOString(),
            end: end.toISOString(),
        },
    });
    const instances = dates.map(date => ({
        ...series,
        id: series.id + ":" + date.toISOString(),
        seriesId: series.id,
        date,
        status: "active" as SoftDeleteStatus,
        confirmations: confirmations?.filter(c => c.seriesDate?.getTime() === date.getTime()),
    }));
    const canceled = exceptions.map(exception => ({
        ...series,
        id: exception.id,
        seriesId: exception.seriesId,
        date: exception.date,
        status: "deleted" as SoftDeleteStatus,
        confirmations: confirmations?.filter(
            c => c.seriesDate?.getTime() === exception.date.getTime(),
        ),
    }));
    return [...instances, ...canceled];
}

// TODO: convert this to smaller hooks, and use seriesId instead of passing in object.
export function useExpandedSeries(series: CoachingSessionSeries, start: Date, end: Date) {
    return useQuery({
        queryKey: queryKeys.series.expanded(series.id, start, end),
        queryFn: () => expandSeries(series, start, end),
        staleTime: 10000,
    });
}

export async function getSeries(assignmentId: string): Promise<CoachingSessionSeries[]> {
    return await run({
        path: `/coaching-assignments/${assignmentId}/coaching-session-series`,
        method: "GET",
    });
}

export function useSeriesByAssignment(assignmentId: string) {
    return useQuery({
        queryKey: queryKeys.assignments.series(assignmentId),
        queryFn: () => getSeries(assignmentId),
        staleTime: 10000,
    });
}

async function getSessions(
    assignmentId: string,
    start: Date,
    end: Date,
): Promise<SessionOrSeriesInstance[]> {
    const sessions: CoachingSession[] = await run({
        path: `/coaching-assignments/${assignmentId}/coaching-sessions`,
        method: "GET",
        params: {
            includeCancelled: true,
        },
    });
    const expandedSeries: CoachingSessionSeriesInstance[][] = await Promise.all(
        (await getSeries(assignmentId)).map(series => expandSeries(series, start, end)),
    );
    return sessions
        .filter(session => start < session.date && session.date < end)
        .concat(expandedSeries.flat());
}

export interface SessionsByDate {
    [date: string]: SessionOrSeriesInstance[];
}

interface CoachingSessionSeriesException {
    id: string;
    seriesId: string;
    date: Date;
}

export async function getCoachSessions(
    coachId: string,
    start: Date,
    end: Date,
): Promise<SessionsByDate> {
    const params = {
        start: start.toUTCString(),
        end: end.toUTCString(),
    };
    const sessions: CoachingSession[] = await run({
        path: `/coach/${coachId}/coaching-sessions`,
        method: "GET",
        params: {
            includeCancelled: true,
        },
    });
    const series: {
        series: CoachingSessionSeries;
        dates: Date[];
        exceptions: CoachingSessionSeriesException[];
    }[] = await run({
        path: `/coach/${coachId}/coaching-session-series/expanded`,
        method: "GET",
        params,
    });
    const seriesInstances: CoachingSessionSeriesInstance[] = series
        .map(s => {
            const instances = s.dates.map(date => ({
                ...s.series,
                id: s.series.id + ":" + date.toISOString(),
                seriesId: s.series.id,
                status: "active" as SoftDeleteStatus,
                date,
                confirmations: s.series.confirmations?.filter(
                    c => c.seriesDate?.getTime() === date.getTime(),
                ),
            }));
            const exceptions: CoachingSessionSeriesInstance[] =
                s.series.exceptions?.map(exception => ({
                    ...s.series,
                    id: s.series.id + "." + exception.date.toISOString(),
                    seriesId: s.series.id,
                    date: exception.date,
                    status: "deleted" as SoftDeleteStatus,
                    confirmations: s.series.confirmations?.filter(
                        c => c.seriesDate?.getTime() === exception.date.getTime(),
                    ),
                })) ?? [];
            return [...instances, ...exceptions];
        })
        .flat();
    return groupByDate(sessions.concat(seriesInstances));
}

export function groupByDate(sessions: SessionOrSeriesInstance[]): SessionsByDate {
    return sessions.reduce<SessionsByDate>((result, session) => {
        const date = session.date.toDateString();
        if (result[date]) {
            result[date]!.push(session);
        } else {
            result[date] = [session];
        }
        // Not performant but n is small here
        result[date]!.sort((a, b) => a.date.getTime() - b.date.getTime());
        return result;
    }, {});
}

export async function getUpcomingAssignmentSessions(
    assignmentId: string,
): Promise<CoachingSession[]> {
    const now = new Date();
    const start = subHours(now, 1);
    const end = addDays(now, 14);
    return getSessions(assignmentId, start, end);
}

export function useUpcomingSessionsByAssignment(assignmentId: string) {
    return useQuery({
        queryKey: ["useUpcomingSessionsByAssignment", assignmentId],
        queryFn: () => getUpcomingAssignmentSessions(assignmentId),
        staleTime: 10000,
    });
}

export async function getAllSessionsForAssignment(
    assignmentId: string,
    from?: Date,
): Promise<SessionOrSeriesInstance[]> {
    const now = new Date();
    const start = from ?? subMonths(now, 11);
    const end = addDays(now, 14);
    return getSessions(assignmentId, start, end);
}

export function seriesDescription(series: CoachingSessionSeries): string {
    const freq = series.rrule.toLowerCase().includes("interval=2") ? "Biweekly" : "Weekly";
    const date = series.start.toLocaleTimeString("en-us", {
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    const [dayOfWeek, time, amPM] = date.split(" ");
    const type = _.startCase(series.sessionType).split(" ")[0];
    return `${freq} on ${dayOfWeek} at ${time} ${amPM}: ${type} (${sessionDuration(series)})`;
}

export function confirmationText(session: SessionOrSeriesInstance): string {
    const confirmation = _.last(session.confirmations?.sort(byCreatedAt));
    if (confirmation === undefined) {
        return "Not Confirmed";
    } else if (confirmation.occurred) {
        return "Occurred";
    } else {
        return `Didn't happen: ${confirmation.reason} (${
            confirmation.cancellationPolicyViolation ? "Violation" : "No Penalty"
        })`;
    }
}

export function sessionDescription(session: SessionOrSeriesInstance): string {
    const dateString = session.date.toLocaleDateString("en-us", {
        dateStyle: "full",
    });
    const timeString = session.date.toLocaleTimeString("en-us", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    const type = _.startCase(session.sessionType).split(" ")[0];
    return `${dateString} ${timeString}: ${type} (${sessionDuration(session)} min)`;
}

export function sessionDuration(session: SessionOrSeriesInstance | CoachingSessionSeries): number {
    if (session.duration) return session.duration;
    return session.sessionType === "athleteCoaching" ? 40 : 20;
}
