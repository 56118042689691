import { RefObject, useEffect, useRef } from "react";
import "./Modal.css";

export function useClickOutside(ref: RefObject<HTMLDivElement>, cb: () => void) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                ref.current &&
                event.target instanceof Node &&
                !ref.current.contains(event.target)
            ) {
                cb();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, cb]);
}

type ModalProps = {
    show: boolean;
    hide: () => void;
    children: JSX.Element;
};

export function Modal({ show, hide, children }: ModalProps) {
    const ref = useRef(null);
    useClickOutside(ref, hide);
    return (
        <div
            className="modal-container"
            style={{
                display: show ? "flex" : "none",
            }}
        >
            <div className="modal" ref={ref}>
                <button className="modal-close" onClick={hide}>
                    <img src="/x.svg" alt="close" />
                </button>
                {children}
            </div>
        </div>
    );
}
