import Confirmation, { ConfirmationContext } from "components/shared/Confirmation";
import { useContext } from "react";
import "./NotePreview.css";

export function NotePreview() {
    const [, setIsVisible] = useContext(ConfirmationContext);
    return (
        <Confirmation title="Shared Notes Preview">
            <Confirmation.Body>
                <div className="note-preview">
                    <p>
                        Once shared, we’ll push your note to the IMG+ app which can be accessed
                        anytime by those you share it with.
                    </p>
                    <img src="/note-preview.gif" alt="note preview" />
                    <span>Preview of what a note looks like in the app.</span>
                </div>
            </Confirmation.Body>
            <Confirmation.Button label="Got it" isPrimary onClick={() => setIsVisible(false)} />
        </Confirmation>
    );
}
