import { Toolbar } from "./Toolbar";
import React from "react";
import "./Maintenance.css";

export function Maintenance() {
    return (
        <>
            <Toolbar />
            <div className={"maintenance"}>
                <h2>
                    The IMG+ app is currently unavailable for scheduled maintenance.
                    <br />
                    We're sorry for the inconvenience. We should have the app back up and running
                    for you shortly.
                </h2>
            </div>
        </>
    );
}
