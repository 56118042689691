import { Channel } from "vendor/sendbird";
import { Avatar, UserAvatar } from "components/shared/Avatar";
import "./ChannelsList.css";
import { Spinner } from "../shared/Spinner";

function ChannelListItem({ channel, isSelected }: { channel: Channel; isSelected: boolean }) {
    return (
        <div
            className="channels-list-item"
            style={{
                backgroundColor: isSelected ? "#F5F6F7" : "white",
            }}
        >
            {/* if it's a group channel, we use a local asset */}
            {channel.otherMembers.length === 1 && channel.otherMembers[0] ? (
                <UserAvatar size={"large"} userId={channel.otherMembers[0].id} />
            ) : (
                <Avatar size={"large"} url={"/avatar-group.svg"} />
            )}
            <div className="column" style={{ overflow: "hidden", width: "100%" }}>
                <div className="row" style={{ justifyContent: "space-between" }}>
                    <h3 className="truncate" style={{ flexShrink: 1 }}>
                        {channel.name}
                    </h3>
                    <p className="secondary" style={{ flexShrink: 0 }}>
                        {channel.lastMessageTimestamp}
                    </p>
                </div>
                <div className="row" style={{ height: "30px", justifyContent: "space-between" }}>
                    {channel.lastMessage ? (
                        <p className="secondary truncate">{channel.lastMessage}</p>
                    ) : (
                        <p className="secondary truncate">No messages</p>
                    )}
                    {channel.unreadCount > 0 && !isSelected ? (
                        <p className="unread-count">{channel.unreadCount}</p>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
}

function ChannelsListHeader() {
    return (
        <div className="channels-list-header">
            <h1>Messages</h1>
        </div>
    );
}

export function ChannelsList({
    channels,
    selectedChannel,
    setChannel,
}: {
    channels: Channel[];
    selectedChannel?: Channel;
    setChannel: (channel: Channel) => void;
}) {
    return (
        <div className="channels-list">
            <ChannelsListHeader />
            {channels.length ? (
                <ul className="no-li-style" style={{ overflowY: "scroll" }}>
                    {channels.map(channel => (
                        <li
                            key={channel.url}
                            onClick={() => setChannel(channel)}
                            style={{ listStyleType: "none", cursor: "pointer" }}
                        >
                            <ChannelListItem
                                channel={channel}
                                isSelected={channel.url === selectedChannel?.url}
                            />
                        </li>
                    ))}
                </ul>
            ) : (
                <Spinner />
            )}
        </div>
    );
}
