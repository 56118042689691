import { format, isToday, isThisYear, isYesterday } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
export type MessageTemplateData = {
    athleteName: string;
    parentName: string;
    coachName: string;
    recepientNames: string;
    discipline: "Mental Performance" | "Nutrition";
    lastSession: Date | null;
    nextSession: Date | null;
};

export const messageTemplates: { [name: string]: (data: MessageTemplateData) => string } = {
    "Welcome to athlete": athleteWelcome,
    "Welcome to parent and athlete": parentAndAthleteWelcome,
    "First session follow-up to athlete": postFirstSessionAthlete,
    "First session follow-up to parent": postFirstSessionParent,
    "Prompt to parent to schedule check-in": parentCheckIn,
    "Missed session": missedSession,
    "Parent Renewal": parentRenewal,
    "After renewal commitment": paymentLink,
    "Renewal commitment followup #1 (3 days after commitment)": followUpRenewal,
    "Renewal commitment followup #2 (6 days after commitment)": secondFollowUpRenewal,
    "48 hour reminder": sessionReminder,
    "60 day inactive": inactive60Days,
};

function athleteWelcome(data: MessageTemplateData): string {
    return data.discipline === "Mental Performance"
        ? `
Hey ${data.athleteName}! 👋 

I'm ${data.coachName} and I’ll be your Mental Performance Coach here at IMG Academy+. Welcome to the IMG Academy+ Coaching app! This program is going to give you a leg up on the competition, and I’m excited to work with you. 🔥 

Below are some pointers to make sure you start out on the right foot before our first call. 
➡️ Make sure to book your first session. 🙂 You can do this by tapping on the “Coaching” tab. We have a scheduling feature that allows you to schedule recurring sessions. If you do this, it’ll be easier to remember when we meet because our coaching session will be at the same time/day every week. You can also book sessions individually each week if you’re not sure what your schedule will be. The most important thing is to schedule our first session ASAP as spots fill up quickly. 
➡️ Turn app notifications on 🔔. We’ll be exchanging messages AND you’ll get reminders about our sessions! This is our primary way of communicating and you’ll be notified each time I send you a message. Not sure how to do that? Let me know in our first session and I’ll help show you how.  
➡️ Check out the “practice” tab and start your first module! This is a great way to start learning and to reinforce what we discuss on your own time outside of our sessions. If you haven’t already, please also complete the Mental Performance Pre-Assessment at the bottom of your profile.  

PLEASE let me know if you have any questions and I’ll be happy to help! 
    `.trim()
        : `
Hey ${data.athleteName}! 

I'm ${data.coachName} and I’ll be your IMG Academy+ Nutrition Coach. 👋 Welcome to IMG Academy+ Coaching! I am a Certified Performance Dietitian and have worked with hundreds of athletes like yourself who are looking to level up their performance. I am so excited to help you achieve your dreams. 🔥 This program is going to give you a leg up on the competition, and I’m excited to work with you. 

I’ll give you a couple pointers before our first call to make sure you start out on the right foot. 
➡️ Make sure to book your first session 🙂 You can do this by tapping on the “Coaching” tab. We have a scheduling feature that allows you to schedule recurring sessions which makes it easy to remember that our coaching session is the same day/time every week.  You can also book sessions individually each week if you’re not sure what your schedule will be. During our first month of coaching we will meet weekly. After month one, we will transition to sessions every other week. The most important thing is to schedule our first session ASAP as spots fill up quickly. 
➡️ Turn app notifications on 🔔. We’ll be exchanging messages AND you’ll get reminders about our sessions! This is our primary way of communicating and you’ll be notified each time I send you a message. Not sure how to do that? Let me know in our first session and I’ll help show you how. 
➡️ If you haven’t already, you can also complete the Nutrition Pre-Assessment at the bottom of your profile. 

PLEASE let me know if you have any questions and I’ll be happy to help! 
    `.trim();
}

function parentAndAthleteWelcome(data: MessageTemplateData): string {
    return `
Hey ${data.athleteName} and ${data.parentName}! This is ${data.coachName}, I'll be ${data.athleteName}'s IMG Academy+ Coach.

To give you a little background about me, ____. I'm looking forward to working with you ${data.athleteName}! 🙂 

${data.athleteName}, you'll be able to book sessions with me directly through your app! You'll be able to select times based on my availability, but if you have certain dates/times that work well for you that aren't listed, you can always let me know and I'll see if I can accommodate. I’d love to get our first session booked this week so we can get started! 

${data.parentName}, Typically it will just be me and ${data.athleteName} but I’d like you to join our first call together so we can all get to know each other and review the coaching journey ahead. We will also schedule parent calls sporadically throughout this process to check-in. I’ll review the best time to redeem those parent sessions in the first meeting as well.  

Let me know if you have any questions or have any problems booking. 🙂 
    `.trim();
}

function postFirstSessionAthlete(data: MessageTemplateData): string {
    return `
${data.athleteName} it was so great to meet you! I know this is just the start, but I'm really looking forward to working with you and getting to know you better over time. It sounds like you're already in a great place mentally & physically in your sport, but there's always room for growth and improvement and I'm excited to help you refine some important skills 🧠🏆

I posted your homework on your profile but remember you can reach out to me through the chat if you have any questions, need anything, or have anything you'd like to share! Excited to be on this journey with you 🤝
    `.trim();
}

function postFirstSessionParent(data: MessageTemplateData): string {
    return data.discipline === "Mental Performance"
        ? `
Hello ${data.parentName}, 

I wanted to let you know that the first session with ${data.athleteName} went well! It was a great opportunity for us to get to know more about each other and to make sure ${data.athleteName} understands what to expect throughout our coaching journey together.  

I want to make sure you’re fully in the know about our sessions, so I thought I’d mention a few things that I spoke with ${data.athleteName} about. 
➡️ You get out of it what you put into it! 🤝 Our sessions are meant to be conversational, and I’ll ask for engagement frequently, sometimes in the form of questions, discussions, or activities! The more invested and engaged, the more growth happens. 👍 
➡️ Homework 📚 Most of the time, I’ll ask ${data.athleteName} to work on something throughout the week. This will not be time intensive but will help reinforce what we’re working on. You could be supportive by asking what they’re working on for the week or how coaching is going. I’ll be posting their homework in the ‘shared notes’ section in the app which you’ll have access to so that you’re in the loop! 
➡️ Practice 🧠 There’s a practice tab on ${data.athleteName}’s profile that contains some great self-paced learning modules for them to work on. These are helpful for ingraining the information that we talk about! 
➡️ Frequency 📆 We are going to complete weekly sessions to maintain consistency. Your athlete can book one off sessions each week but we recommend booking a recurring session whereby you reserve the same day/time every week so that it's easier for ${data.athleteName} to remember.  
➡️ Parent Call ☎️ After a few sessions you’ll have the opportunity to book a parent session. This is a time for us to connect and discuss what we are working on.  It is important for us to spend this time together and I’m more than happy to accommodate your schedule to make this happen. I’ll remind you once ${data.athleteName} and I have completed a few sessions, so you know when it’s time to schedule a parent session. 🙂 

If you have ANY questions at all, please don’t hesitate to reach out. I want to make sure you and ${data.athleteName} both feel good about things moving forward! 
`.trim()
        : `
Hello ${data.parentName}, 

Great session this week, I am so glad you were able to join! If you’re available to continue to join the sessions, I would highly recommend it. I’ve also outlined a few points from the session as reminders for ${data.athleteName} to maximize the coaching experience: 

➡️ You get out of it what you put into it! 🤝 Our sessions are meant to be conversational, and I’ll ask for engagement frequently, sometimes in the form of questions, discussions, or activities! The more invested and engaged, the more growth happens. 👍 
➡️ Homework 📚 Most of the time, I’ll ask ${data.athleteName} to work on something throughout the week. This will not be time intensive but will help reinforce what we’re working on. You could be supportive by asking what they’re working on for the week or how coaching is going. I’ll be posting their homework in the ‘shared notes’ section in the app which you’ll have access to so that you’re in the loop! 
➡️ Practice🧠 Continue practicing and applying all the learning from our sessions. Practice makes progress!  
➡️ Frequency 📆 to start, I recommend weekly sessions in month one to maintain consistency. After month one, we will meet bi-weekly for 30 minutes. You can book one off sessions, or you can book a recurring session whereby you reserve the same day/time every week so that it can’t be booked by others. Again, entirely up to you! 
➡️ Parent Call ☎️ After a few sessions you’ll have the opportunity to book a parent session. This is a time for us to connect and discuss what we are working on.  It is important for us to spend this time together and I’m more than happy to accommodate your schedule to make this happen. I’ll remind you once ${data.athleteName} and I have completed a few sessions, so you know when it’s time to schedule a parent session. 🙂 

If you have ANY questions at all, please don’t hesitate to reach out. I want to make sure you and ${data.athleteName} both feel good about things moving forward! 
`.trim();
}

function parentCheckIn(data: MessageTemplateData): string {
    return `
Hey ${data.parentName}! 👋 

Because we're a couple sessions in with ${data.athleteName}, I’d like to schedule a 20 min parent call with you. The time can be used for us to touch base, answer any questions you may have, and dive into detail about the coaching experience. The parent session is included in the program and an important part of ${data.athleteName}’s coaching journey. Please tap “+ Book” in the coaching section of the app and select a time that works for you. If you don’t see a timeslot that works, just shoot me a message with a good date and time and I'll make sure we get it sorted! 🙂
`.trim();
}

function sessionTimestamp(date: Date): string {
    const day = (() => {
        if (isToday(date)) {
            return "today";
        } else if (isYesterday(date)) {
            return "yesterday";
        } else if (isThisYear(date)) {
            return `on ${format(date, "MMM d")}`;
        } else {
            return `on ${format(date, "M/d/yy")}`;
        }
    })();
    const time = formatToTimeZone(date, "h:mm z", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return `${day} at ${time}`;
}

function missedSession(data: MessageTemplateData): string {
    const date = data.lastSession ? sessionTimestamp(data.lastSession) : "[DATE/TIME/TIMEZONE]";
    return `
Hi ${data.recepientNames},

I didn’t see you log onto our Zoom during our scheduled on ${date}. Attending our sessions is crucial to ensure that we are progressing efficiently in your coaching journey. Just a reminder that we do have a 24-hour cancellation/no show policy in place, I'd hate for you to forfeit a session. Please let me know if we need to adjust our scheduled time 24-48 hours in advance of a session. I'm happy to accommodate with enough warning.    
`.trim();
}

function parentRenewal(data: MessageTemplateData): string {
    return `
Hi ${data.parentName},

Just circling back to see if you've decided whether to continue ${data.athleteName}’s coaching after your initial package ends? I know you were still on the fence when we last talked.  

I'd love to continue working with ${data.athleteName}, but totally understand if it's not the right choice for you.  Let me know if you have any other questions or need help deciding.   

Let me know how you would like to proceed, and if you choose to move forward, I will send you the link for easy payment. 

Whether you choose to continue or not, I hope you and ${data.athleteName} enjoyed your time with IMGA+. Thanks for trusting us with ${data.athleteName}’s development! 

Have a great day,
${data.coachName}
`.trim();
}

function paymentLink(data: MessageTemplateData): string {
    const link = ["mindset", "Mental Performance"].includes(data.discipline)
        ? `https://buy.stripe.com/aEU9Bl3F53M6eDm5kv`
        : `https://buy.stripe.com/8wM6p9cbBfuO3YI8wJ`;
    return `
Great, let's get you subscribed! Here is the payment link: 

${link} 

Please submit payment ASAP so we can schedule our next session together! 

I’m so excited to keep working with ${data.athleteName} on their coaching journey. 
`.trim();
}

function followUpRenewal(data: MessageTemplateData): string {
    return `
Hi ${data.parentName}, 

I haven’t heard from you about continuing coaching but still hope that ${data.athleteName} wants to continue building on their progress. If you do want to proceed, please connect with me so we can get payment sorted and schedule our next coaching session.   

If I don’t hear from you in the next 5 business days your account will go inactive.  Of course, you can return to coaching at any time by reaching out to support@imgacademyplus.com but please note there is no guarantee that you will be matched back with me as your coach upon your return. 

Hope to hear from you soon, 
${data.coachName}
`.trim();
}

function secondFollowUpRenewal(data: MessageTemplateData): string {
    return `
Hi, I have yet to receive your payment to resume coaching.  If I don’t hear from you in the next 5 business days your account will go inactive. Of course, you can return to coaching at any time by reaching out to support@imgacademyplus.com but please note there is no guarantee that you will be matched back with me as your coach upon your return. 

Hope to hear from you soon,
${data.coachName}
`.trim();
}

function sessionReminder(data: MessageTemplateData): string {
    const date = data.nextSession ? sessionTimestamp(data.nextSession) : "[DATE/TIME/TIMEZONE]";
    console.log(data);
    return `
Hi ${data.recepientNames}, 

This is a reminder that we have a coaching session scheduled for ${date}. Please reply to confirm you received this message and are planning to attend our session.  

Just a reminder that we do have a 24-hour cancellation/no show policy in place, I'd hate for you to forfeit a session so please let me know if we need to adjust our scheduled time. I'm happy to accommodate. 

Looking forward to our call! 

${data.coachName}     
`.trim();
}

function inactive60Days(data: MessageTemplateData): string {
    return `Hi, we haven’t heard from you in over two months. You still have sessions left in your coaching package! Please let us know if you’d like to resume your coaching asap. If we don’t hear from you in the next 7 days, your account will go inactive. Not to worry, you won’t lose your remaining sessions. However, we cannot guarantee that when you come back that you will be matched with your current coach. To continue coaching and reactivate your account please contact support@imgacademyplus.com.`;
}
