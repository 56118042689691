import { Athlete, CoachState, useCoachData } from "data/coach";
import { Context, createContext } from "react";
import { format, isThisYear, isToday, subDays, subHours } from "date-fns";
import { isSeriesInstance, SessionOrSeriesInstance, SessionsByDate } from "data/session";
import "./CoachSessions.css";
import { NavLink, useSearchParams } from "react-router-dom";
import { useMe } from "../../data/user";
import { Spinner } from "../shared/Spinner";
import { SessionRowWrapper } from "../coach/SessionRow";
import { Name } from "../../util";
import _ from "lodash";

export const SessionContext: Context<{
    session: SessionOrSeriesInstance;
    athlete: Athlete;
}> = createContext({} as any);

export function athleteName(athlete: Athlete): Name {
    if (athlete.user) {
        return athlete.user;
    }
    return {};
}

function upcomingSessions(sessions: SessionsByDate, dateKey: string): SessionOrSeriesInstance[] {
    return (sessions[dateKey] ?? [])
        .filter(session => session.date > subHours(new Date(), 1))
        .filter(s => s.status === "active");
}

export function upcomingKeys(sessions: SessionsByDate): string[] {
    return Object.keys(sessions)
        .filter(dateKey => new Date(dateKey) > subDays(new Date(), 1))
        .filter(dateKey => upcomingSessions(sessions, dateKey).length > 0);
}

function previousUnclosedSessions(
    sessions: SessionsByDate,
    dateKey: string,
): SessionOrSeriesInstance[] {
    return (sessions[dateKey] ?? []).filter(
        session =>
            session.date <= subHours(new Date(), 1) && // inverse of what an upcoming session is
            session.date > subDays(new Date(), 30) &&
            // As we cut over to the new system, we only want to prompt for sessions after this cutover date,
            // to avoid an overwhelming list of CTAs for coaches
            session.date > new Date("2023-03-01T00:00:00") &&
            (session.confirmations ?? []).length === 0,
    );
}

function previousUnclosedKeys(sessions: SessionsByDate): string[] {
    return Object.keys(sessions)
        .filter(dateKey => new Date(dateKey) < new Date())
        .filter(dateKey => previousUnclosedSessions(sessions, dateKey).length > 0);
}

function previousSessions(sessions: SessionsByDate, dateKey: string): SessionOrSeriesInstance[] {
    return (sessions[dateKey] ?? []).filter(session => session.date < new Date());
}

export function previousKeys(sessions: SessionsByDate): string[] {
    return Object.keys(sessions)
        .filter(dateKey => new Date(dateKey) < new Date())
        .filter(dateKey => previousSessions(sessions, dateKey).length > 0);
}

function filterSessions(coachState: CoachState, assignmentId: string | undefined): SessionsByDate {
    if (assignmentId && coachState.sessionsByAssignment[assignmentId] !== undefined) {
        return coachState.sessionsByAssignment[assignmentId] ?? {};
    }
    return _.mapValues(coachState.sessions, sessionsList =>
        _.filter(
            sessionsList,
            session => assignmentId === undefined || session.assignmentId === assignmentId,
        ),
    );
}
function filterSessionsForAssignment(
    sessionsByAssignment: { [assignmentId: string]: SessionsByDate },
    sessions: SessionsByDate,
    assignmentId: string | undefined,
): SessionsByDate {
    if (assignmentId && sessionsByAssignment[assignmentId] !== undefined) {
        return sessionsByAssignment[assignmentId] ?? {};
    }
    return _.mapValues(sessions, sessionsList =>
        _.filter(
            sessionsList,
            session => assignmentId === undefined || session.assignmentId === assignmentId,
        ),
    );
}

export function SessionDateHeader({ date }: { date: string }) {
    let title: string;
    const timestamp = new Date(date);
    if (isToday(timestamp)) {
        title = "Today";
    } else if (isThisYear(timestamp)) {
        title = format(timestamp, "EEE, MMM do");
    } else {
        title = format(timestamp, "EEE, MMM d, yyyy");
    }
    return <div className="date-header">{title}</div>;
}

function UpcomingEmptyState({
    assignmentId,
    coachUserId,
}: {
    assignmentId: string | undefined;
    coachUserId: string;
}) {
    const coachData = useCoachData(coachUserId);
    const filteredSessions = filterSessionsForAssignment(
        coachData.sessionsByAssignment,
        coachData.sessions,
        assignmentId,
    );
    if (upcomingKeys(filteredSessions).length === 0) {
        return (
            <div className="sessions-empty-state">
                You currently have no live sessions scheduled. Once a session is scheduled through
                the app, it will show here.
            </div>
        );
    } else {
        return <></>;
    }
}

function Footer({
    assignmentId,
    coachUserId,
}: {
    assignmentId: string | undefined;
    coachUserId: string;
}) {
    const coachData = useCoachData(coachUserId);
    const filteredSessions = filterSessionsForAssignment(
        coachData.sessionsByAssignment,
        coachData.sessions,
        assignmentId,
    );
    if (Object.values(filteredSessions).flat().some(isSeriesInstance)) {
        return (
            <>
                <hr />
                <div className="sessions-footer">
                    Showing sessions through{" "}
                    {format(coachData.sessionsPageEndDate!, "EEEE, MMMM do")}
                </div>
            </>
        );
    } else {
        return <></>;
    }
}

function PreviousUnclosed({
    assignmentId,
    coachUserId,
}: {
    assignmentId?: string;
    coachUserId: string;
}) {
    const coachState = useCoachData(coachUserId);
    const assignmentSessions = filterSessions(coachState, assignmentId);
    const allPreviousLink = assignmentId
        ? `/previous-sessions?assignmentId=${assignmentId}`
        : "/previous-sessions";
    if (previousUnclosedKeys(assignmentSessions).length === 0) {
        return (
            <div className="sessions-empty-state">
                <div className="m-2">No unclosed previous sessions!</div>
                {previousKeys(assignmentSessions).length > 0 && (
                    <NavLink className="blue-link m-4" to={allPreviousLink}>
                        All Previous Sessions
                    </NavLink>
                )}
            </div>
        );
    }

    if (coachState.isLoading) return <Spinner />;
    return (
        <div className="coach-home-column">
            <div className="flex-row d-flex justify-content-space-between align-items-center">
                <h2 className="section-header">Previous Sessions (Unclosed)</h2>
                {previousKeys(assignmentSessions).length > 0 && (
                    <NavLink className="blue-link m-4" to={allPreviousLink}>
                        All Previous Sessions
                    </NavLink>
                )}
            </div>
            {previousUnclosedKeys(assignmentSessions)
                .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
                .map(dateKey => {
                    const sessionsForDate = previousUnclosedSessions(assignmentSessions, dateKey);
                    return (
                        <div className="session-section" key={dateKey}>
                            <SessionDateHeader date={dateKey} />
                            {sessionsForDate.map((session, index, array) => {
                                return (
                                    <SessionRowWrapper
                                        session={session}
                                        athletes={coachState.athletes}
                                        showDivider={index !== array.length - 1}
                                        key={session.id}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    );
}

function Upcoming({
    assignmentId = undefined,
    coachUserId,
}: {
    assignmentId?: string;
    coachUserId: string;
}) {
    const coachState = useCoachData(coachUserId);
    const assignmentSessions = filterSessions(coachState, assignmentId);
    return (
        <div className="coach-home-column">
            <h2 className="section-header">Upcoming Live Sessions</h2>

            {coachState.isLoading ? (
                <Spinner />
            ) : (
                upcomingKeys(assignmentSessions)
                    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
                    .map(date => {
                        const sessionsForDate = upcomingSessions(assignmentSessions, date);
                        return (
                            <div className="session-section" key={date}>
                                <SessionDateHeader date={date} />
                                <ul className="no-li-style">
                                    {sessionsForDate.map((session, index, array) => {
                                        return (
                                            <SessionRowWrapper
                                                session={session}
                                                athletes={coachState.athletes}
                                                showDivider={index !== array.length - 1}
                                                key={session.id}
                                            />
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })
            )}
            <UpcomingEmptyState assignmentId={assignmentId} coachUserId={coachUserId} />
            <Footer assignmentId={assignmentId} coachUserId={coachUserId} />
        </div>
    );
}

export function CoachSessions({
    assignmentId,
    coachUserId,
}: {
    assignmentId: string | undefined;
    coachUserId: string;
}) {
    return (
        <div className="d-flex flex-col coach-sessions">
            <PreviousUnclosed assignmentId={assignmentId} coachUserId={coachUserId} />
            <br />
            <Upcoming assignmentId={assignmentId} coachUserId={coachUserId} />
        </div>
    );
}

function PreviousAll({
    coachUserId,
    assignmentId,
}: {
    coachUserId: string;
    assignmentId: string | undefined;
}) {
    const coachData = useCoachData(coachUserId);
    const assignmentSessions = filterSessionsForAssignment(
        coachData.sessionsByAssignment,
        coachData.sessions,
        assignmentId,
    );

    return (
        <div className="d-flex flex-col content" style={{ maxWidth: 900 }}>
            <div className="coach-home-column coach-sessions">
                <div className="flex-row d-flex justify-content-space-between align-items-center">
                    <h2 className="section-header">Previous Sessions</h2>
                </div>
                {previousKeys(assignmentSessions)
                    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
                    .map(dateKey => {
                        const sessionsForDate = previousSessions(assignmentSessions, dateKey);
                        return (
                            <div className="session-section" key={dateKey}>
                                <SessionDateHeader date={dateKey} />
                                {sessionsForDate.map((session, index, array) => {
                                    return (
                                        <SessionRowWrapper
                                            session={session}
                                            athletes={coachData.athletes}
                                            showDivider={index !== array.length - 1}
                                            showConfirmedStatus={true}
                                            key={session.id}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export function AllPreviousSessions(
    { assignmentId }: { assignmentId: string | undefined } = { assignmentId: undefined },
) {
    const [searchParams] = useSearchParams();
    const { data: me } = useMe();
    if (!me) return <Spinner />;

    switch (me.type) {
        case "coach":
            return (
                <PreviousAll
                    coachUserId={me.id}
                    assignmentId={assignmentId ?? searchParams.get("assignmentId") ?? undefined}
                />
            );
        default:
            return <Spinner />;
    }
}
