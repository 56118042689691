import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRef } from "react";
import "./DateTimeInput.css";
import { format, isToday, isTomorrow } from "date-fns";

function dateString(date: Date): string {
    if (isToday(date)) {
        return "Today";
    } else if (isTomorrow(date)) {
        return "Tomorrow";
    } else {
        return format(date, "M/dd/yyy");
    }
}

export function DateInput({ date, setDate }: { date: Date; setDate: (date: Date) => void }) {
    const ref = useRef<DatePicker>(null);
    return (
        <DatePicker
            selected={date}
            minDate={new Date()}
            onChange={(date: Date) => setDate(date)}
            customInput={
                <div
                    className="date-time-input"
                    onClick={() => ref.current?.setOpen(true)}
                    style={{ width: "200px" }}
                >
                    <img className="date-time-input-icon" src="/calendar.svg" alt="calendar" />
                    <span className="date-time-input-text">{dateString(date)}</span>
                    <img className="date-time-input-chevron" src="/down-chevron.svg" alt="down" />
                </div>
            }
        />
    );
}
