import Confirmation, { ConfirmationContext } from "components/shared/Confirmation";
import { Athlete } from "data/coach";
import { focusEditor, noteIsPrivate, NotesContext } from "data/note";
import { useMe, User } from "data/user";
import { useContext, useEffect, useState } from "react";
import { NoteComposer } from "./NoteComposer";
import { PrivateNotesList, SharedNotesList } from "./NoteList";
import "./Notes.css";
import { trackPageView } from "../../vendor/mixpanel";

function SeedNote({ athlete, isPrivate }: { athlete?: User; isPrivate: boolean }) {
    const [, dispatch] = useContext(NotesContext);
    const [, setIsVisible] = useContext(ConfirmationContext);
    return (
        <Confirmation title="Start with the last private note?">
            <Confirmation.Body>
                <p>
                    {`Would you like to use the last private note`}
                    {athlete && ` for ${athlete.firstName}`}
                    {` as a starting point?`}
                    <br />
                    {!isPrivate && (
                        <>
                            <br />
                            {`You will be able to edit the note before sharing.`}
                        </>
                    )}
                </p>
            </Confirmation.Body>
            <Confirmation.Button
                label="No Thanks"
                onClick={() => {
                    dispatch({ type: "compose_note", isPrivate });
                    setIsVisible(false);
                    focusEditor(undefined);
                }}
            />
            <Confirmation.Button
                label="Yes Please"
                onClick={() => {
                    dispatch({ type: "compose_note", isPrivate });
                    dispatch({ type: "seed_note", isPrivate });
                    setIsVisible(false);
                    focusEditor(undefined);
                }}
                isPrimary
            />
        </Confirmation>
    );
}

export function PrivateNotes({
    athlete,
    assignmentId,
}: {
    athlete?: Athlete;
    assignmentId: string;
}) {
    const { data: coach } = useMe();
    const [{ newPrivateNoteDraft, notes }, dispatch] = useContext(NotesContext);
    // const { data: notes = [] } = useQueryNotes(assignmentId);
    const seeding = useState(false);
    const [, setSeedingIsVisible] = seeding;
    const privateNotes = Object.values(notes).filter(n => noteIsPrivate(n));
    useEffect(() => {
        trackPageView("private_notes");
    }, []);
    return (
        <div className="notes">
            <ConfirmationContext.Provider value={seeding}>
                <SeedNote athlete={athlete?.user} isPrivate={true} />
            </ConfirmationContext.Provider>
            <button
                className="new-note"
                onClick={() => {
                    if (privateNotes.length > 0) {
                        setSeedingIsVisible(true);
                    } else {
                        dispatch({
                            type: "compose_note",
                            isPrivate: true,
                        });
                        focusEditor(undefined);
                    }
                }}
                disabled={newPrivateNoteDraft !== undefined}
            >
                Create Private Note
            </button>
            {newPrivateNoteDraft && <NoteComposer discipline={athlete?.discipline} sharees={[]} />}
            {coach && athlete && (
                <PrivateNotesList assignmentId={assignmentId} coachUserId={coach.id} />
            )}
        </div>
    );
}

export function SharedNotes({
    athlete,
    sharees,
    assignmentId,
}: {
    athlete?: Athlete;
    sharees: User[];
    assignmentId: string;
}) {
    const { data: coach } = useMe();
    const [{ notes, newSharedNoteDraft }, dispatch] = useContext(NotesContext);
    // const { data: notes } = useQueryNotes(assignmentId);
    const seeding = useState(false);
    const [, setSeedingIsVisible] = seeding;
    const privateNotes = Object.values(notes ?? []).filter(n => noteIsPrivate(n));
    useEffect(() => {
        trackPageView("shared_notes");
    }, []);
    return (
        <div className="notes">
            <ConfirmationContext.Provider value={seeding}>
                <SeedNote athlete={athlete?.user} isPrivate={false} />
            </ConfirmationContext.Provider>
            <button
                className="new-note"
                onClick={() => {
                    if (privateNotes.length > 0) {
                        setSeedingIsVisible(true);
                    } else {
                        dispatch({
                            type: "compose_note",
                            isPrivate: false,
                        });
                        focusEditor(undefined);
                    }
                }}
                disabled={newSharedNoteDraft !== undefined}
            >
                Create Shared Note
            </button>
            {newSharedNoteDraft !== undefined && (
                <NoteComposer discipline={athlete?.discipline} sharees={sharees} />
            )}
            {coach && <SharedNotesList assignmentId={assignmentId} coachUserId={coach.id} />}
        </div>
    );
}
