import { AdminAthleteContext } from "data/admin-athlete";
import { useContext } from "react";
import { AdminAthleteDetailsSectionHeader } from "./AdminAthleteDetailsHeader";
import "../Admin.css";
import { AdminFeedbackRow } from "../AdminFeedbackRow";

export function AdminAthleteFeedback() {
    const [{ feedback }] = useContext(AdminAthleteContext);
    return (
        <div className="admin-athlete-content-container ">
            <AdminAthleteDetailsSectionHeader text="Feedback" />
            <ul className="no-li-style">
                {feedback
                    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                    .map(feedback => (
                        <AdminFeedbackRow feedback={feedback} key={feedback.id} />
                    ))}
            </ul>
        </div>
    );
}
