import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRef } from "react";
import "./DateTimeInput.css";
import { format } from "date-fns";

function timeString(date: Date): string {
    return format(date, "h:mm a");
}

export function TimeInput({ time, setTime }: { time: Date; setTime: (time: Date) => void }) {
    const ref = useRef<DatePicker>(null);
    return (
        <DatePicker
            selected={time}
            onChange={(date: Date) => setTime(date)}
            showTimeSelect
            showTimeSelectOnly
            customInput={
                <div className="date-time-input" onClick={() => ref.current?.setOpen(true)}>
                    <img className="date-time-input-icon" src="/clock.svg" alt="calendar" />
                    <span className="date-time-input-text">{timeString(time)}</span>
                    <img className="date-time-input-chevron" src="/down-chevron.svg" alt="down" />
                </div>
            }
        />
    );
}
