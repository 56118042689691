import { run } from "./api";
import { Note } from "./note";

export type NoteAttachment = {
    id: string;
    noteId: string;
    mediaId: string;
    name: string;
};

export async function getNoteAttachments(noteId: string): Promise<NoteAttachment[]> {
    return await run<NoteAttachment[]>({
        path: `/coaching-assignment-notes/${noteId}/attachments`,
        method: "GET",
    });
}

export async function attachFilesToNote(
    note: Note,
    attachments: File[],
): Promise<NoteAttachment[]> {
    return await Promise.all(
        attachments.map(async attachment => {
            const upload = await uploadAttachment(attachment);
            return run({
                path: `/coaching-assignment-notes/${note.id}/attachment`,
                method: "POST",
                body: {
                    mediaId: upload.id,
                    name: attachment.name,
                },
            });
        }),
    );
}

type MediaType = "image" | "document" | "image" | "audio";
export const ACCEPTED_MEDIA_EXT = ["pdf", "docx", "jpg", "pptx", "xlsx", "mp3", "m4a"];
function mediaTypeFromExtension(extension: string): MediaType | null {
    switch (extension) {
        case "jpg":
            return "image";
        case "docx":
        case "pdf":
        case "pptx":
        case "xlsx":
            return "document";
        case "mp3":
        case "m4a":
            return "audio";
        default:
            return null;
    }
}

export function isValidAttachment(file: File): boolean {
    const extension = fileExtension(file);
    if (!extension) return false;
    return !!mediaTypeFromExtension(extension);
}

function fileExtension(file: File): string | undefined {
    return file.name.split(".").pop();
}

async function uploadAttachment(file: File): Promise<{ id: string }> {
    const extension = fileExtension(file);
    if (!extension) {
        throw new Error(`Invalid file.`);
    }
    const mediaType = mediaTypeFromExtension(extension);
    if (!mediaType) {
        throw new Error(
            `Invalid media type. Must use one of these valid types: ${ACCEPTED_MEDIA_EXT.join(
                ", ",
            )}`,
        );
    }
    const { mediaItem, uploadUrl }: { mediaItem: { id: string }; uploadUrl: string } = await run({
        path: "/media",
        method: "POST",
        body: {
            type: mediaType,
            extension: extension,
        },
    });
    await fetch(uploadUrl, {
        method: "PUT",
        body: file,
    });
    return mediaItem;
}

export async function deleteNoteAttachment(attachment: NoteAttachment) {
    await run({
        path: `/coaching-assignment-notes/${attachment.noteId}/attachment/${attachment.id}`,
        method: "DELETE",
    });
}
