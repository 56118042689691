import { AdminFeedbackRow } from "../AdminFeedbackRow";
import { Feedback } from "data/feedback";

export function AdminParentFeedback({ feedback }: { feedback: Feedback[] }) {
    return (
        <div className="admin-parent-content-container">
            <h2 className="section-header">Feedback</h2>
            <ul className="no-li-style">
                {feedback
                    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                    .map(feedback => (
                        <AdminFeedbackRow feedback={feedback} key={feedback.id} />
                    ))}
            </ul>
        </div>
    );
}
