import { Modal } from "components/shared/Modal";
import { deleteUser } from "data/admin";
import { User } from "data/user";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fullName } from "../../util";
import "./AdminDeleteUser.css";

export function AdminDeleteUser({
    user,
}: {
    user: Pick<User, "id" | "firstName" | "lastName" | "email">;
}) {
    const navigate = useNavigate();
    const [modal, showModal] = useState(false);
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const onDelete = useCallback(async () => {
        const isValid = email.toLocaleLowerCase() === user.email.toLocaleLowerCase();
        setIsValid(isValid);
        if (!isValid) return;
        deleteUser(user.id)
            .then(() => navigate("/"))
            .catch(e => alert(`Error deleting ${fullName(user)}: ${e.message}`));
    }, [email, navigate, user]);
    if (process.env.REACT_APP_ENV === "production") return null;
    return (
        <div className="d-flex admin-delete-user">
            <button
                type="button"
                className="pill-outline pill-destructive"
                onClick={() => showModal(true)}
            >
                Delete User
            </button>
            <Modal show={modal} hide={() => showModal(false)}>
                <div className="d-flex flex-col">
                    <h1>{`Are you sure you want to delete ${fullName(user)}?`}</h1>
                    <p>
                        <b>WARNING: this action cannot be undone.</b>
                        <br />
                        All data associated with {user.firstName} will also be deleted.
                        <br />
                        Enter {user.firstName}'s email to confirm:
                    </p>
                    <input
                        type="text"
                        className={isValid ? undefined : "error"}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    {isValid || <p className="error-message">Email does not match</p>}
                    <button type="button" className="pill pill-destructive" onClick={onDelete}>
                        DELETE
                    </button>
                </div>
            </Modal>
        </div>
    );
}
