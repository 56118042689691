import {
    createUserWithEmailAndPassword,
    getAuth,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import firebase from "firebase/compat";
import { Context, createContext, useCallback, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./query";

export interface AuthState {
    status:
        | "loading"
        | "creating_account"
        | "error"
        | "signing_in"
        | "signed_in"
        | "signing_out"
        | "signed_out";
    token?: string;
    error?: string;
    malkovichUserId?: string;
}

export const malkovichSessionStorageKey = "malkovichUserId";

const initialState: AuthState = { status: "loading" };

export interface SignInAction {
    type: "sign_in";
    email: string;
    password: string;
}

export interface CreateAccountAction {
    type: "create_account";
    name: string;
    email: string;
    password: string;
}

export interface SignOutAction {
    type: "sign_out";
}

export interface MalkovichAction {
    type: "malkovich";
    userId: string;
}

export type AuthAction = SignInAction | CreateAccountAction | SignOutAction | MalkovichAction;

export function useAuth(): [AuthState, (action: AuthAction) => void] {
    const queryClient = useQueryClient();
    const [state, setState] = useState<AuthState>(initialState);
    useEffect(() => {
        getAuth().onAuthStateChanged(async userOrNull => {
            if (userOrNull != null) {
                const token = await userOrNull.getIdToken();
                setState({
                    status: "signed_in",
                    token,
                    malkovichUserId:
                        sessionStorage.getItem(malkovichSessionStorageKey) ?? undefined,
                });
            } else {
                setState({ status: "signed_out" });
            }
        });
    }, []);
    const invalidateMe = useCallback(async () => {
        await queryClient.invalidateQueries({
            queryKey: queryKeys.users.me,
        });
    }, [queryClient]);
    const dispatch = useCallback(
        async (action: AuthAction) => {
            switch (action.type) {
                case "create_account":
                    setState({ status: "creating_account" });
                    try {
                        const creds = await createUserWithEmailAndPassword(
                            getAuth(),
                            action.email,
                            action.password,
                        );
                        const token = await creds.user.getIdToken();
                        setState({ status: "signed_in", token });
                        await invalidateMe();
                    } catch {
                        setState({ status: "error", error: "Failed to create account" });
                    }
                    break;
                case "sign_in":
                    setState({ status: "signing_in" });
                    try {
                        const user = await signInWithEmailAndPassword(
                            getAuth(),
                            action.email,
                            action.password,
                        );
                        const token = await user.user.getIdToken();
                        setState({ status: "signed_in", token });
                        await invalidateMe();
                    } catch (e) {
                        let message = "Invalid email or password";
                        if (e instanceof Error && e.name === "FirebaseError") {
                            const firebaseError = e as firebase.FirebaseError;
                            switch (firebaseError.code) {
                                case "auth/user-not-found":
                                    message = "Invalid email";
                                    break;
                                case "auth/wrong-password":
                                    message = "Incorrect password";
                                    break;
                                default:
                                    console.error(firebaseError.code);
                                    break;
                            }
                        }
                        setState({ status: "error", error: message });
                    }
                    break;
                case "sign_out":
                    setState(state => {
                        if (sessionStorage.getItem(malkovichSessionStorageKey)) {
                            mixpanel.reset();
                            sessionStorage.setItem(malkovichSessionStorageKey, "");
                            return { status: "signed_in", malkovichUserId: undefined };
                        } else {
                            signOut(getAuth());
                            return { status: "signing_out" };
                        }
                    });
                    await invalidateMe();
                    break;
                case "malkovich":
                    mixpanel.disable();
                    setState({ status: "signed_in", malkovichUserId: action.userId });
                    sessionStorage.setItem(malkovichSessionStorageKey, action.userId);
                    await invalidateMe();
                    break;
            }
        },
        [invalidateMe],
    );
    return [state, dispatch];
}

export const AuthContext: Context<[AuthState, (action: AuthAction) => void]> = createContext([
    initialState,
    _ => {},
]);
