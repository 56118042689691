import { Toolbar } from "./Toolbar";
import React, { useEffect } from "react";
import { trackPageView } from "../vendor/mixpanel";
import { useLocation } from "react-router-dom";

export function NotFound() {
    const location = useLocation();
    useEffect(() => {
        trackPageView("not_found", { path: location.pathname });
    }, [location]);
    return (
        <>
            <Toolbar />
            <div className={"content"}>
                <h1>404</h1>
                <br />
                <h3>The page you’re looking for can’t be found.</h3>
            </div>
        </>
    );
}
