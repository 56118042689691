import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale } from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, ChartDataLabels);

export type BarChartData = { [label: string]: number };

export function BarChart({ data }: { data: BarChartData }) {
    return (
        <Bar
            plugins={[ChartDataLabels]}
            options={{
                indexAxis: "y" as const,
                plugins: {
                    datalabels: {
                        color: "white",
                    },
                },
            }}
            data={{
                labels: Object.keys(data),
                datasets: [
                    {
                        backgroundColor: "#17344F",
                        data: Object.values(data),
                    },
                ],
            }}
        />
    );
}
