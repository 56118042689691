import { useContext, useState } from "react";
import { AssignParent } from "./AssignParent";
import { byFirstName } from "../../../util";
import { Link } from "react-router-dom";
import { UserAvatarRow } from "../../shared/AvatarRow";
import { AdminAthleteDetailsSectionHeader } from "./AdminAthleteDetailsHeader";
import { AdminAthleteContext } from "data/admin-athlete";

export function AdminAthleteDetailsParents() {
    const [athleteState] = useContext(AdminAthleteContext);
    const [showAssignParent, setShowAssignParent] = useState(false);
    return (
        <div className="admin-athlete-content-container ">
            <AdminAthleteDetailsSectionHeader
                text="Parents"
                onPlusClick={() => setShowAssignParent(!showAssignParent)}
            />
            {showAssignParent ? <AssignParent /> : <></>}
            <ul className="no-li-style">
                {athleteState.parents.sort(byFirstName).map(parent => (
                    <li key={parent.id}>
                        <Link to={`/admin/parents/${parent.id}`}>
                            <UserAvatarRow
                                userId={parent.id}
                                title={`${parent.firstName} ${parent.lastName}`}
                                subtitle={parent.email}
                                showChevron
                            />
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}
