import { getAuth } from "firebase/auth";
import { malkovichSessionStorageKey } from "./auth";
import { UseQueryOptions } from "@tanstack/react-query";
export type Request = {
    path: string;
    method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
    body?: any;
    params?: { [key: string]: string | string[] | boolean };
};

const reDate = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;

async function userToken(): Promise<string | undefined> {
    const authToken = await getAuth().currentUser?.getIdToken();
    const malkovichUserId = sessionStorage.getItem(malkovichSessionStorageKey);
    if (malkovichUserId) {
        return JSON.stringify({
            malked_id: malkovichUserId,
            admin_token: authToken,
        });
    } else {
        return authToken;
    }
}

class HTTPError extends Error {
    constructor(public status: number, public statusText: string, public responseText: string) {
        super();
    }
}

export class TeapotError extends Error {
    constructor(public status: number, public statusText: string, public responseText: string) {
        super();
    }
}

export async function run<T>(request: Request): Promise<T> {
    const url = new URL(process.env.REACT_APP_BASE_URL + request.path);
    if (request.params) {
        const params = new URLSearchParams();
        for (const [key, value] of Object.entries(request.params)) {
            if (Array.isArray(value)) {
                for (const item of value) {
                    params.append(key, item);
                }
            } else {
                params.append(key, value.toString());
            }
        }
        url.search = params.toString();
    }
    const headers = new Headers();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("Accept", "application/json; charset=utf-8");
    headers.append("plus-client-id", "plus-web");
    headers.append("plus-client-version", "2.6.0");
    const authToken = await userToken();
    if (authToken) {
        headers.append("plus-user-token", authToken);
    }
    try {
        const response = await fetch(url.toString(), {
            method: request.method,
            headers: headers,
            body: JSON.stringify(request.body),
        });
        if (response.ok && response.status !== 204) {
            if (response.headers.get("content-type")?.includes("text/csv")) {
                return (await response.text()) as unknown as T;
            }
            return JSON.parse(await response.text(), (k, v) => {
                if (typeof v == "string" && reDate.exec(v)) {
                    return new Date(v);
                } else if (v === null) {
                    return undefined;
                } else {
                    return v;
                }
            });
        } else if (response.status === 204) {
            return undefined as unknown as T;
        } else if (response.status === 418) {
            throw new TeapotError(response.status, response.statusText, await response.text());
        } else {
            throw new HTTPError(response.status, response.statusText, await response.text());
        }
    } catch (e) {
        if (e instanceof HTTPError) {
            try {
                const message = JSON.parse(e.responseText)["message"];
                alert([e.status, e.statusText, message].join("\n"));
            } catch {
                alert([e.status, e.statusText].join("\n"));
            }
        } else if (e instanceof TeapotError) {
            console.log("The server is down for maintenance");
        } else {
            alert("Unable to connect to server");
        }
        return Promise.reject(e);
    }
}

export type QueryConfig<T> = Omit<UseQueryOptions<T>, "queryFn" | "queryKey">;
