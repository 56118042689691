import { Editor, EditorContent } from "@tiptap/react";
import { ConfirmationContext } from "components/shared/Confirmation";
import { NotesContext, humanReadable, NoteDraft } from "data/note";
import { User } from "data/user";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import "./NoteComposer.css";
import { ShareNote } from "./ShareNote";
import "../Tabs.css";
import { NoteTopicsInput } from "./NoteTopicsInput";
import { CoachingType } from "data/assignment";
import { NotePreview } from "./NotePreview";
import { trackAction } from "../../vendor/mixpanel";
import { AddLinkModal } from "./AddLinkModal";
import { ACCEPTED_MEDIA_EXT, isValidAttachment } from "data/note_attachment";
import { UploadAttachmentsRow } from "./NoteAttachment";

export function trackStyleToggle(editor: Editor, style: string) {
    if (!editor.isActive(style)) trackAction("user_toggle_rich_text_style", { style });
}

const Toolbar = ({
    editor,
    onAddAttachment,
}: {
    editor: Editor;
    onAddAttachment: (file: File) => void;
}) => {
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [value, setValue] = useState(0);
    const fileInput = useRef<HTMLInputElement>(null);

    const forceUpdate = () => {
        setValue(value => value + 1);
    };

    useEffect(() => {
        const onKeydown = (e: KeyboardEvent) => {
            if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
                setShowLinkModal(true);
                e.preventDefault();
            }
        };
        editor.on("transaction", forceUpdate);
        document.addEventListener("keydown", onKeydown);
        return () => {
            editor.off("transaction", forceUpdate);
            document.removeEventListener("keydown", onKeydown);
        };
    }, [editor]);

    const onChangeAttachment = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e || !e.target.files?.[0] || !isValidAttachment(e.target.files[0])) {
            alert(
                `Invalid filetype. Must use one of these valid types: ${ACCEPTED_MEDIA_EXT.join(
                    ", ",
                )}`,
            );
            return;
        }
        onAddAttachment(e.target.files[0]);
    };

    const attachmentButton: JSX.Element = (
        <>
            <button
                onClick={() => {
                    fileInput.current?.click();
                }}
            >
                <img id="composer-toolbar-paperclip" src="/paperclip.svg" alt="add attachment" />
            </button>
            <input
                style={{ display: "none" }}
                type="file"
                ref={fileInput}
                onChange={onChangeAttachment}
            />
        </>
    );
    return (
        <div className="composer-toolbar">
            <button
                onClick={() => {
                    trackStyleToggle(editor, "bold");
                    editor.chain().focus().toggleBold().run();
                    forceUpdate();
                }}
                className={editor.isActive("bold") ? "is-active" : ""}
            >
                <img src="/bold.svg" alt="bold" />
            </button>
            <button
                onClick={() => {
                    trackStyleToggle(editor, "italic");
                    editor.chain().focus().toggleItalic().run();
                    forceUpdate();
                }}
                className={editor.isActive("italic") ? "is-active" : ""}
            >
                <img src="/italic.svg" alt="italic" />
            </button>
            <button
                onClick={() => {
                    trackStyleToggle(editor, "underline");
                    editor.chain().focus().toggleUnderline().run();
                    forceUpdate();
                }}
                className={editor.isActive("underline") ? "is-active" : ""}
            >
                <img src="/underline.svg" alt="underline" />
            </button>
            <button
                onClick={() => {
                    trackStyleToggle(editor, "strike");
                    editor.chain().focus().toggleStrike().run();
                    forceUpdate();
                }}
                className={editor.isActive("strike") ? "is-active" : ""}
            >
                <img src="/strikethrough.svg" alt="strikethrough" />
            </button>
            <button
                onClick={() => {
                    trackStyleToggle(editor, "bulletList");
                    editor.chain().focus().toggleBulletList().run();
                    forceUpdate();
                }}
                className={editor.isActive("bulletList") ? "is-active" : ""}
            >
                <img src="/bullet-list.svg" alt="bullet list" />
            </button>
            <button
                onClick={() => {
                    trackStyleToggle(editor, "orderedList");
                    editor.chain().focus().toggleOrderedList().run();
                    forceUpdate();
                }}
                className={editor.isActive("orderedList") ? "is-active" : ""}
            >
                <img src="/number-list.svg" alt="number list" />
            </button>
            <AddLinkModal
                show={showLinkModal}
                key={value}
                initialText={editor.state.doc.textBetween(
                    editor.state.selection.from,
                    editor.state.selection.to,
                )}
                cancel={() => {
                    setShowLinkModal(false);
                    editor.commands.focus();
                }}
                submit={(text, link) => {
                    let url = link;
                    if (!/^(?:f|ht)tps?:\/\//.test(url)) {
                        url = "http://" + url;
                    }
                    trackAction("user_insert_link", { text, url });
                    setShowLinkModal(false);
                    const from = editor.state.selection.from;
                    const to = from + text.length;
                    editor.commands.focus();
                    editor.commands.insertContent(text);
                    editor.commands.setTextSelection({ from, to });
                    editor.commands.setLink({ href: url });
                    editor.commands.setTextSelection(to);
                    editor.commands.insertContent(" ");
                    editor.commands.setTextSelection({ from: to, to: to + 1 });
                    editor.commands.unsetLink();
                    editor.commands.setTextSelection(to + 1);
                }}
            />
            <button
                onClick={() => {
                    trackAction("user_open_insert_link_modal");
                    setShowLinkModal(!showLinkModal);
                }}
            >
                <img src="/link.svg" alt="insert link" />
            </button>
            {attachmentButton}
        </div>
    );
};

export function NoteComposer({
    discipline,
    sharees,
    editingNoteId,
}: {
    discipline?: CoachingType;
    sharees: User[];
    editingNoteId?: string;
}) {
    const [{ notes, newSharedNoteDraft, newPrivateNoteDraft, editPrivateNoteDrafts }, dispatch] =
        useContext(NotesContext);
    const showShareNoteFlow = useState(false);
    const [, setShowShareNoteFlow] = showShareNoteFlow;
    const preview = useState(false);
    const isPrivate = sharees.length === 0;
    const [, setPreview] = preview;
    const [attachments, setAttachments] = useState<File[]>([]);

    const draft: NoteDraft | undefined = (() => {
        if (sharees.length > 0) {
            return newSharedNoteDraft;
        } else if (!editingNoteId) {
            return newPrivateNoteDraft;
        } else {
            return editPrivateNoteDrafts[editingNoteId];
        }
    })();

    if (!draft) return null;

    const onSave = () => {
        if (isPrivate) {
            dispatch({
                type: "upsert_private_note",
                id: editingNoteId,
                title: draft.title,
                body: draft.editor.getJSON(),
                topics: draft.topics,
                attachments: attachments,
            });
        } else {
            setShowShareNoteFlow(true);
        }
    };

    const onAddAttachment = (file: File) => {
        setAttachments(state => [...state, file]);
    };

    const onRemoveAttachment = (file: File) => {
        setAttachments(state => state.filter(f => file !== f));
    };

    return (
        <div className="note-composer" id={`note-composer-${editingNoteId}`}>
            <div className="note-menubar">
                <div className="note-inputs">
                    <div className="note-title-container">
                        <label htmlFor="note-title">Title</label>
                        <input
                            id="note-title"
                            type="text"
                            placeholder="Insert title here"
                            value={draft.title}
                            onChange={e =>
                                dispatch({
                                    type: "update_draft",
                                    noteId: editingNoteId,
                                    isPrivate: isPrivate,
                                    title: e.target.value,
                                })
                            }
                        />
                    </div>
                    {isPrivate && (
                        <NoteTopicsInput
                            defaultDiscipline={discipline}
                            selections={[...draft.topics]}
                            onChange={event => {
                                const selections = draft.topics;
                                const value = event.isChecked
                                    ? [...selections, event.topic]
                                    : [...selections].filter(s => s !== event.topic);
                                dispatch({
                                    type: "update_draft",
                                    noteId: editingNoteId,
                                    isPrivate: isPrivate,
                                    topics: new Set(value),
                                });
                            }}
                        />
                    )}
                </div>
                <div>
                    <button
                        className="composer-cancel"
                        onClick={() =>
                            dispatch({ type: "cancel_draft", isPrivate, id: editingNoteId })
                        }
                    >
                        Cancel
                    </button>
                    {!isPrivate && (
                        <button className="composer-preview" onClick={() => setPreview(true)}>
                            Preview
                        </button>
                    )}
                    <button
                        disabled={draft.title.trim().length === 0}
                        className="composer-save"
                        onClick={onSave}
                    >
                        {isPrivate ? "Save" : "Save & Share"}
                    </button>
                </div>
            </div>
            <div className="note-editor">
                <ConfirmationContext.Provider value={preview}>
                    <NotePreview />
                </ConfirmationContext.Provider>
                <ConfirmationContext.Provider value={showShareNoteFlow}>
                    <ShareNote sharees={sharees} attachments={attachments} />
                </ConfirmationContext.Provider>
                <Toolbar editor={draft.editor} onAddAttachment={onAddAttachment} />
                {attachments.length > 0 && (
                    <UploadAttachmentsRow attachments={attachments} onRemove={onRemoveAttachment} />
                )}
                <EditorContent editor={draft.editor} />
            </div>
            <span className="note-timestamp">
                {editingNoteId
                    ? `Last saved ${humanReadable(notes[editingNoteId]!.updatedAt)}`
                    : `Unsaved`}
            </span>
        </div>
    );
}
