import { Modal } from "components/shared/Modal";
import { AdminCoachContext } from "data/admin-coach";
import { CoachingMatchPreferences } from "data/coach";
import { startCase } from "lodash";
import { useContext, useState } from "react";
import { coachingTypeText } from "../../util";
import "./CoachingMatchPreferences.css";

export function CoachingMatchPreferencesView({
    preferences,
}: {
    preferences?: CoachingMatchPreferences;
}) {
    const [showForm, setShowForm] = useState<boolean>(false);

    return !!preferences ? (
        <div className="coach-match-preferences">
            <button className="float-right" onClick={() => setShowForm(true)}>
                Edit
            </button>
            <table className="table">
                <tbody>
                    <tr>
                        <td>Disciplines</td>
                        <td>{preferences.disciplines.map(d => coachingTypeText(d)).join(", ")}</td>
                    </tr>
                    <tr>
                        <td>Maximum Client Load</td>
                        <td>{preferences.maxAssignments}</td>
                    </tr>
                    <tr>
                        <td>Sports (Ranked)</td>
                        <td>
                            <ol>
                                {preferences.sports.map(sport => (
                                    <li key={sport}>{startCase(sport)}</li>
                                ))}
                            </ol>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Modal show={showForm} hide={() => setShowForm(false)}>
                <CoachingMatchPreferencesForm
                    preferences={preferences}
                    onComplete={() => setShowForm(false)}
                />
            </Modal>
        </div>
    ) : (
        <div>Preferences not set.</div>
    );
}

function CoachingMatchPreferencesForm({
    preferences,
    onComplete,
}: {
    preferences: CoachingMatchPreferences;
    onComplete: () => void;
}) {
    const [state, setState] = useState<CoachingMatchPreferences>(preferences);
    const [, adminDispatch] = useContext(AdminCoachContext);

    const onSubmit = async () => {
        await adminDispatch({ type: "set_match_preferences", matchPreferences: state });
        onComplete();
    };

    return (
        <form className="form">
            <h4>Edit Match Preferences</h4>
            <div className="form-group">
                <label htmlFor="maxAssignments">Maximum Client Load</label>
                <input
                    type="text"
                    name="maxAssignments"
                    value={`${isNaN(state.maxAssignments) ? "" : state.maxAssignments}`}
                    onChange={e =>
                        setState(state => ({
                            ...state,
                            maxAssignments: parseInt(e.target.value),
                        }))
                    }
                />
            </div>
            <button
                className="pill"
                type="button"
                disabled={isNaN(state.maxAssignments)}
                onClick={onSubmit}
            >
                Update
            </button>
        </form>
    );
}
