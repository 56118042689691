import { useContext, useEffect, useState } from "react";
import "./App.css";
import { useAuth, AuthContext } from "data/auth";
import { UserContext, useLoggedInState } from "data/user";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { UserHome } from "components/UserHome";
import { SignIn } from "components/SignIn";
import { AdminHome, AdminWrapper } from "components/admin/AdminHome";
import { AdminAthleteDetails } from "./components/admin/athlete/AdminAthleteDetails";
import { AdminCoachDetails, AdminCoachSessions } from "./components/admin/coach/AdminCoachDetails";
import { AdminCoachList } from "./components/admin/one_on_one_tab/AdminCoachList";
import { Toolbar } from "./components/Toolbar";
import { AthleteDetails } from "./components/athlete/AthleteDetails";
import Messaging from "./components/messaging/Messaging";
import { NotFound } from "./components/NotFound";
import { useNotifications, NotificationContext } from "./util/notifications";
import { Footer } from "./components/Footer";
import { Spinner } from "./components/shared/Spinner";
import { useSendbird, SendbirdContext } from "./vendor/sendbird";
import { DeepLink } from "./components/DeepLink";
import { AdminParentDetails } from "./components/admin/parent/AdminParentDetails";
import { AdminDashboard } from "./components/admin/AdminDashboard";
import { AssignmentPage } from "components/assignment/Assignment";
import { Banner, BannerAction, BannerContext } from "components/Banner";
import { FeedbackWrapper } from "./components/FeedbackForm";
import { Version } from "./components/Version";
import { MixpanelRedirector } from "./components/admin/MixpanelRedirector";
import { AdminCreateCoachForm } from "components/admin/coach/AdminCreateCoachForm";
import { AdminSearch } from "components/admin/AdminSearch";
import { AllPreviousSessions } from "./components/sessions/CoachSessions";
import { Maintenance } from "components/Maintenance";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function RequireAuth({ children }: { children: JSX.Element }) {
    const [state] = useContext(AuthContext);
    const [, userDispatch] = useContext(UserContext);
    const location = useLocation();
    useEffect(() => {
        if (state.status === "signed_in") {
            userDispatch("refresh");
        }
    }, [state, userDispatch]);
    switch (state.status) {
        case "signed_out":
            return <Navigate to="/login" state={{ from: location }} replace />;
        case "signed_in":
            return children;
        default:
            return <Spinner />;
    }
}

function MessagingWrapper({ children }: { children: JSX.Element }) {
    const sendbirdContext = useSendbird();
    return <SendbirdContext.Provider value={sendbirdContext}>{children}</SendbirdContext.Provider>;
}

function UserWrapper() {
    const userContext = useLoggedInState();
    const notificationContext = useNotifications();
    const [userState] = userContext;
    const banner = useState<BannerAction>({ type: "dismiss" });
    if (userState.isMaintenance) {
        return <Maintenance />;
    }
    return (
        <UserContext.Provider value={userContext}>
            <NotificationContext.Provider value={notificationContext}>
                <MessagingWrapper>
                    <RequireAuth>
                        <BannerContext.Provider value={banner}>
                            <div className="container">
                                <Toolbar />
                                <Banner />
                                <Outlet />
                                <Footer />
                            </div>
                        </BannerContext.Provider>
                    </RequireAuth>
                </MessagingWrapper>
            </NotificationContext.Provider>
        </UserContext.Provider>
    );
}

function App() {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <AppContent />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

function AppContent() {
    const authContext = useAuth();
    return (
        <AuthContext.Provider value={authContext}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<UserWrapper />}>
                        <Route index element={<UserHome />} />
                        <Route path="athletes">
                            <Route path=":athleteId" element={<AthleteDetails />} />
                        </Route>
                        <Route path="messaging">
                            <Route path=":assignmentId" element={<Messaging />} />
                        </Route>
                        <Route path="assignments">
                            <Route path=":assignmentId" element={<AssignmentPage />} />
                        </Route>
                        <Route
                            path="previous-sessions"
                            element={<AllPreviousSessions assignmentId={undefined} />}
                        />
                        <Route path="admin" element={<AdminWrapper />}>
                            <Route index element={<AdminHome />} />
                            <Route path="search" element={<AdminSearch />} />
                            <Route path="athletes">
                                <Route path=":athleteId" element={<AdminAthleteDetails />} />
                            </Route>
                            <Route path="coaches">
                                <Route index element={<AdminCoachList />} />
                                <Route path=":coachId">
                                    <Route index element={<AdminCoachDetails />} />
                                    <Route path="sessions" element={<AdminCoachSessions />} />
                                </Route>
                            </Route>
                            <Route path="parents">
                                <Route path=":parentId" element={<AdminParentDetails />} />
                            </Route>
                            <Route path="dashboard" element={<AdminDashboard />} />
                            <Route path="mixpanel-redirect">
                                <Route path=":mixpanelId" element={<MixpanelRedirector />} />
                            </Route>
                            <Route path="create-coach" element={<AdminCreateCoachForm />} />
                        </Route>
                    </Route>
                    <Route path="/submit-feedback/:inquiryId" element={<FeedbackWrapper />} />
                    <Route path="version" element={<Version />} />
                    <Route path="/login" element={<SignIn />} />
                    <Route path="/deeplink" element={<DeepLink />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </AuthContext.Provider>
    );
}

export default App;
