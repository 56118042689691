import { GroupCourse, GroupCourseContext, useGroupCourses } from "../../../data/group-courses";
import { useContext, useEffect } from "react";
import { AdminGroupCourseForm } from "./AdminGroupCourseForm";
import "./AdminGroupCourses.css";

function AdminGroupCourseCard({ course }: { course: GroupCourse }) {
    return (
        <div className="group-course-card">
            <h2>{course.title}</h2>
            <h3>Description: {course.description}</h3>
            <p>Price: {course.price / 10}</p>
            <p>Starts: {new Date(course.start).toLocaleDateString()}</p>
            <p>Cadence: {course.cadence}</p>
            <p>Session Count: {course.sessionCount}</p>
            <p>Seat Count: {course.seatCount}</p>
            <p>Enroll By: {new Date(course.enrollBy).toLocaleDateString()}</p>
            <p>URL: {course.url}</p>
        </div>
    );
}

function AdminGroupCoursesList() {
    const [state, dispatch] = useContext(GroupCourseContext);
    useEffect(() => {
        dispatch({ type: "refresh" });
    }, [dispatch]);
    return (
        <div className="admin-list-container">
            <h2 className="section-header">Active Courses</h2>
            <ul>
                {state.map(course => (
                    <div key={course.url}>
                        <AdminGroupCourseCard course={course} />
                        <button
                            onClick={() =>
                                dispatch({ type: "delete_group_course", courseId: course.id ?? "" })
                            }
                        >
                            Delete
                        </button>
                        <hr />
                    </div>
                ))}
            </ul>
        </div>
    );
}

export function AdminGroupCourses() {
    const context = useGroupCourses();
    return (
        <div className="d-flex flex-col">
            <div className="d-flex flex-no-wrap" id="list-container">
                <GroupCourseContext.Provider value={context}>
                    <AdminGroupCoursesList />
                    <AdminGroupCourseForm />
                </GroupCourseContext.Provider>
            </div>
        </div>
    );
}
