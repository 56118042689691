import { useQuery } from "@tanstack/react-query";
import { run } from "./api";
import { queryKeys } from "./query";

export type Avatar = {
    mediaId: string;
    url: string;
};

export async function accessMedia(mediaId: string): Promise<string> {
    const { url } = await run<{ url: string }>({
        path: `/media/${mediaId}/access-url`,
        method: "GET",
    });
    return url;
}

export async function getAvatarUrl(userId: string): Promise<string | undefined> {
    try {
        const avatar: Avatar | undefined = await run({
            path: `/users/${userId}/avatar`,
            method: "GET",
        });
        if (avatar?.mediaId) {
            return accessMedia(avatar.mediaId);
        } else {
            return undefined;
        }
    } catch (e) {
        return undefined;
    }
}

export function useUserAvatar(userId: string) {
    const { data: avatar } = useQuery({
        queryKey: queryKeys.users.avatar(userId),
        queryFn: async () => {
            const avatar = await run<Avatar | undefined>({
                path: `/users/${userId}/avatar`,
                method: "GET",
            });
            if (!avatar) {
                return null;
            }
            return avatar;
        },
        staleTime: 10 * 60 * 1000,
    });
    const mediaId = avatar?.mediaId;
    return useQuery({
        enabled: !!mediaId,
        queryKey: queryKeys.media.access(mediaId ?? "<none>"),
        queryFn: () => accessMedia(mediaId ?? ""),
        staleTime: 10 * 60 * 1000,
    });
}

export async function uploadAvatar(userId: string, file: File): Promise<void> {
    const {
        mediaItem,
        uploadUrl,
    }: {
        mediaItem: { id: string };
        uploadUrl: string;
    } = await run({
        path: "/media",
        method: "POST",
        body: {
            type: "image",
            extension: "jpg",
        },
    });
    await fetch(uploadUrl, {
        method: "PUT",
        body: file,
    });
    const existingAvatar = await getAvatarUrl(userId);
    if (existingAvatar) {
        await run({
            path: `/users/${userId}/avatar`,
            method: "PATCH",
            body: {
                mediaId: mediaItem.id,
            },
        });
    } else {
        await run({
            path: `/avatar`,
            method: "POST",
            body: {
                userId,
                mediaId: mediaItem.id,
            },
        });
    }
}
