import React, { useEffect, useState } from "react";
import { getOnboardingHistory, OnboardingEvent } from "data/onboarding";
import { AdminAthleteDetailsSectionHeader } from "./athlete/AdminAthleteDetailsHeader";
import { formatDuration, intervalToDuration } from "date-fns";

function displayText(event: OnboardingEvent): string {
    switch (event.type) {
        case "created_account":
            return "Created their account";
        case "sent_invitation":
            return `Sent a ${event.invitation.inviteeType} invitation to ${event.invitation.firstName} ${event.invitation.lastName}`;
        case "sent_invitation_accepted":
            const duration = formatDuration(
                intervalToDuration({ start: event.invitation.createdAt, end: event.date }),
            );
            return `Had their invitation accepted by ${event.invitation.accepter.firstName} ${event.invitation.accepter.lastName}\n${duration} after sent`;
        case "received_invitation":
            return `Received a ${event.invitation.inviteeType} invitation from ${event.invitation.inviter.firstName} ${event.invitation.inviter.lastName}`;
        case "accepted_invitation":
            const timeToAccept = formatDuration(
                intervalToDuration({ start: event.invitation.createdAt, end: event.date }),
            );
            return `Accepted an invitation from ${event.invitation.inviter.firstName} ${event.invitation.inviter.lastName}\n${timeToAccept} after sent`;
    }
}

export function AdminOnboardingHistory({ userId }: { userId: string }) {
    let [state, setState] = useState<OnboardingEvent[]>([]);
    useEffect(() => {
        getOnboardingHistory(userId).then(events => setState(events.reverse()));
    }, [userId]);
    return (
        <div className="admin-athlete-content-container">
            <AdminAthleteDetailsSectionHeader text="Invitation Timeline" />
            <ul style={{ listStyle: "none", padding: 0 }}>
                {state.map(event => (
                    <li key={event.date.getTime()}>
                        <p>{event.date.toLocaleString()}</p>
                        <p>{displayText(event)}</p>
                        <hr />
                    </li>
                ))}
            </ul>
        </div>
    );
}
