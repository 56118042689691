import React, { Context, createContext, useContext } from "react";
import "./Confirmation.css";
import { Modal } from "./Modal";

export const ConfirmationContext = createContext([false, (_: any) => {}]) as Context<
    [boolean, (isVisible: boolean) => void]
>;

const ConfirmationBody = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
    <>{children}</>
);
ConfirmationBody.defaultProps = {
    confirmationRole: "ConfirmationBody",
};

function ConfirmationButton({
    label,
    onClick,
    isPrimary = false,
    isDestructive,
    disabled,
}: {
    label: string;
    onClick?: () => void;
    isPrimary?: boolean;
    isDestructive?: boolean;
    disabled?: boolean;
}) {
    let className: string;
    if (isDestructive) {
        className = "destructive";
    } else if (!isPrimary) {
        className = "secondary";
    } else {
        className = "";
    }
    return (
        <button className={className} onClick={onClick} disabled={disabled ?? false}>
            {label}
        </button>
    );
}
ConfirmationButton.defaultProps = {
    confirmationRole: "ConfirmationButton",
};

function Confirmation({
    title,
    onDismiss,
    children,
}: {
    title: string;
    onDismiss?: () => void;
    children: JSX.Element | JSX.Element[];
}) {
    const [isVisible, setVisible] = useContext(ConfirmationContext);
    if (!isVisible) return null;
    const content = React.Children.toArray(children).find(
        c => React.isValidElement(c) && c.props.confirmationRole === "ConfirmationBody",
    );
    const buttons = React.Children.toArray(children).filter(
        c => React.isValidElement(c) && c.props.confirmationRole === "ConfirmationButton",
    );
    return (
        <Modal
            show={isVisible}
            hide={() => {
                if (onDismiss) onDismiss();
                setVisible(false);
            }}
        >
            <>
                <h1 className="confirm-modal-title">{title}</h1>
                <div className="confirm-modal-body">{content}</div>
                <div className="confirm-modal-footer">{buttons}</div>
            </>
        </Modal>
    );
}

export default Object.assign(Confirmation, {
    Body: ConfirmationBody,
    Button: ConfirmationButton,
});
