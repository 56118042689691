import { User } from "./user";
import { QueryConfig, run } from "data/api";
import { Context, createContext, useCallback, useState } from "react";
import { Content, Editor, Extensions, JSONContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { format, isThisYear, isToday, isYesterday } from "date-fns";
import { CoachingType } from "./assignment";
import { Link } from "@tiptap/extension-link";
import { attachFilesToNote, deleteNoteAttachment, NoteAttachment } from "./note_attachment";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./query";

export interface Note {
    id: string;
    title: string;
    body: JSONContent;
    assignmentId: string;
    access: { user: User; createdAt?: Date }[];
    topics: Set<NoteTopic>;
    createdAt: Date;
    updatedAt: Date;
    attachments: NoteAttachment[];
    assignment?: {
        coach: {
            user: {
                firstName: string;
                lastName: string;
            };
        };
    };
}

export type NoteTopic =
    | "coachability"
    | "confidence"
    | "fearOfFailure"
    | "focus"
    | "handlingPressure"
    | "managingEmotions"
    | "managingIntensity"
    | "motivation"
    | "negativeThinking"
    | "optimism"
    | "perfectionism"
    | "resilience"
    | "bodyImage"
    | "hydration"
    | "meals"
    | "mealTiming"
    | "snacks"
    | "supplements"
    | "travel"
    | "weightGain"
    | "weightLoss";

export const noteCategories: Record<CoachingType, NoteTopic[]> = {
    mindset: [
        "coachability",
        "confidence",
        "fearOfFailure",
        "focus",
        "handlingPressure",
        "managingEmotions",
        "managingIntensity",
        "motivation",
        "negativeThinking",
        "optimism",
        "perfectionism",
        "resilience",
    ],
    nutrition: [
        "bodyImage",
        "hydration",
        "meals",
        "mealTiming",
        "snacks",
        "supplements",
        "travel",
        "weightGain",
        "weightLoss",
    ],
};

export interface NoteDraft {
    id?: string;
    title: string;
    editor: Editor;
    topics: Set<NoteTopic>;
}

interface NotesState {
    notes: { [id: string]: Note };
    newPrivateNoteDraft?: NoteDraft;
    editPrivateNoteDrafts: { [id: string]: NoteDraft };
    newSharedNoteDraft?: NoteDraft;
    previousNotes: Note[];
}

async function getNotes(assignmentId: string): Promise<Note[]> {
    return run({
        path: `/coaching-assignments/${assignmentId}/notes`,
        method: "GET",
    });
}

async function getAthleteNotes(athleteId: string, discipline: CoachingType): Promise<Note[]> {
    return run({
        path: `/athletes/${athleteId}/notes`,
        method: "GET",
        params: {
            discipline,
        },
    });
}

async function createNote(
    assignmentId: string,
    title?: string,
    body?: JSONContent,
    topics?: Set<NoteTopic>,
): Promise<Note> {
    return run({
        path: `/coaching-assignments/${assignmentId}/notes`,
        method: "POST",
        body: {
            title,
            body,
            topics: [...(topics ?? [])],
        },
    });
}

async function updateNote(note: Partial<Note>): Promise<Note> {
    if (note.access?.length) {
        return run({
            path: `/coaching-assignment-notes/${note.id}/share`,
            method: "PATCH",
            body: {
                users: note.access?.map(a => a.user.id),
            },
        });
    } else {
        return run({
            path: `/coaching-assignment-notes/${note.id}`,
            method: "PATCH",
            body: {
                title: note.title,
                body: note.body,
                topics: [...(note.topics ?? [])],
            },
        });
    }
}
async function deleteNote(id: string): Promise<Note> {
    return run({
        path: `/coaching-assignment-notes/${id}`,
        method: "DELETE",
    });
}

interface RefreshNotes {
    type: "refresh";
}

interface UpsertPrivateNote {
    type: "upsert_private_note";
    id?: string;
    title: string;
    body: JSONContent;
    attachments: File[];
    topics: Set<NoteTopic>;
}

interface SaveAndShareNote {
    type: "save_and_share_note";
    sharees: User[];
    title: string;
    attachments: File[];
    body: JSONContent;
}

interface EditNote {
    type: "edit_note";
    id: string;
}

interface ComposeNote {
    type: "compose_note";
    isPrivate: boolean;
}

interface UpdateDraft {
    type: "update_draft";
    isPrivate: boolean;
    noteId?: string;
    title?: string;
    topics?: Set<NoteTopic>;
}

interface DeleteNote {
    type: "delete_note";
    id: string;
}

interface SeedNote {
    type: "seed_note";
    isPrivate: boolean;
}

interface CancelDraft {
    type: "cancel_draft";
    isPrivate: boolean;
    id?: string;
}

interface DeleteNoteAttachment {
    type: "delete_note_attachment";
    attachment: NoteAttachment;
}

interface GetPreviousNotes {
    type: "get_previous_notes";
    discipline: CoachingType;
    athleteId: string;
}

type NotesAction =
    | RefreshNotes
    | UpsertPrivateNote
    | EditNote
    | ComposeNote
    | UpdateDraft
    | CancelDraft
    | DeleteNote
    | SeedNote
    | SaveAndShareNote
    | DeleteNoteAttachment
    | GetPreviousNotes;

export const extensions: Extensions = [
    StarterKit,
    Underline,
    Link.configure({
        autolink: true,
        openOnClick: true,
        linkOnPaste: true,
        HTMLAttributes: {
            class: "composer-link",
        },
    }),
];
export const template: Content = `
    <p><strong><u>General Notes</u></strong></p>
    <p></p>
    <p></p>
    <p><strong><u>Goals</u></strong></p>
    <p></p>
    <p></p>
    <p><strong><u>Homework</u></strong></p>
    <p></p>
    <p></p>
    <p><strong><u>Plans for Next Session</u></strong></p>
    <p></p>
    <p></p>
    <p><strong><u>Notes for Parents</u></strong></p>
    <p></p>
    `;
const initialState: NotesState = {
    notes: {},
    editPrivateNoteDrafts: {},
    previousNotes: [],
};

export function useQueryNotes(assignmentId: string, config: QueryConfig<Note[]> = {}) {
    return useQuery({
        ...config,
        queryKey: queryKeys.assignments.notes(assignmentId),
        queryFn: () => getNotes(assignmentId),
        staleTime: 5000,
    });
}

export function useAthleteNotes(
    athleteId: string,
    discipline: CoachingType,
    config: QueryConfig<Note[]> = {},
) {
    return useQuery({
        ...config,
        queryKey: queryKeys.assignments.previousNotes(athleteId, discipline),
        queryFn: () => getAthleteNotes(athleteId, discipline),
        staleTime: 5000,
    });
}

export function useNotes(assignmentId: string): [NotesState, (action: NotesAction) => void] {
    const [states, setState] = useState<NotesState>(initialState);
    const queryClient = useQueryClient();
    const dispatch = useCallback(
        async (action: NotesAction) => {
            switch (action.type) {
                case "refresh": {
                    // TODO: remove this when dependencies are gone
                    const notes = await getNotes(assignmentId);
                    setState(state => ({
                        ...state,
                        notes: notes.reduce<{ [key: string]: Note }>((result, note) => {
                            result[note.id] = note;
                            return result;
                        }, {}),
                    }));
                    await queryClient.invalidateQueries({
                        queryKey: queryKeys.assignments.notes(assignmentId),
                    });
                    break;
                }
                case "upsert_private_note": {
                    const note = await (action.id
                        ? updateNote({
                              id: action.id,
                              title: action.title,
                              body: action.body,
                              topics: action.topics,
                          })
                        : createNote(assignmentId, action.title, action.body, action.topics));
                    await attachFilesToNote(note, action.attachments);
                    setState(state => {
                        const newState = { ...state };
                        if (action.id) {
                            delete newState.editPrivateNoteDrafts[action.id];
                        } else {
                            newState.newPrivateNoteDraft = undefined;
                        }
                        return newState;
                    });
                    await dispatch({ type: "refresh" });
                    break;
                }
                case "edit_note": {
                    setState(state => {
                        if (state.editPrivateNoteDrafts[action.id]) {
                            return state;
                        }
                        const note = state.notes[action.id];
                        if (!note) return state;
                        const newState = { ...state };
                        const newDraft = {
                            id: action.id,
                            title: note.title,
                            topics: note.topics,
                            editor: new Editor({ extensions }),
                        };
                        newDraft.editor.commands.setContent(note.body);
                        newState.editPrivateNoteDrafts[action.id] = newDraft;
                        return newState;
                    });
                    break;
                }
                case "compose_note": {
                    setState(state => {
                        const newState = { ...state };
                        const newDraft = {
                            id: undefined,
                            title: "",
                            topics: new Set<NoteTopic>(),
                            editor: new Editor({ extensions }),
                            attachments: [],
                        };
                        if (action.isPrivate) {
                            newState.newPrivateNoteDraft = newDraft;
                            newDraft.editor.commands.setContent(template);
                        } else {
                            newState.newSharedNoteDraft = newDraft;
                        }
                        return newState;
                    });
                    break;
                }
                case "seed_note": {
                    setState(state => {
                        const note = Object.values(state.notes)
                            .filter(n => noteIsPrivate(n))
                            .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())[0];
                        if (!note) return state;
                        const newState = { ...state };
                        if (action.isPrivate && newState.newPrivateNoteDraft) {
                            newState.newPrivateNoteDraft.editor?.commands.setContent(note.body);
                            newState.newPrivateNoteDraft.title = note.title;
                        } else if (newState.newSharedNoteDraft) {
                            newState.newSharedNoteDraft.editor?.commands.setContent(note.body);
                            newState.newSharedNoteDraft.title = note.title;
                        }
                        return newState;
                    });
                    break;
                }
                case "update_draft": {
                    setState(state => {
                        const newState = { ...state };
                        if (!action.isPrivate) {
                            if (!state.newSharedNoteDraft) {
                                return state;
                            }
                            newState.newSharedNoteDraft = {
                                ...state.newSharedNoteDraft,
                                title: action.title ?? state.newSharedNoteDraft?.title ?? "",
                            };
                        } else if (action.noteId) {
                            const draft = state.editPrivateNoteDrafts[action.noteId];
                            if (!draft) return state;
                            newState.editPrivateNoteDrafts[action.noteId] = {
                                ...draft,
                                title: action.title ?? draft.title ?? "",
                                topics: action.topics ?? draft.topics ?? new Set(),
                            };
                        } else {
                            const draft = state.newPrivateNoteDraft;
                            if (!draft) return state;
                            newState.newPrivateNoteDraft = {
                                ...draft,
                                title: action.title ?? draft.title ?? "",
                                topics: action.topics ?? draft.topics ?? new Set(),
                            };
                        }
                        return newState;
                    });
                    break;
                }
                case "delete_note": {
                    await deleteNote(action.id);
                    setState(state => {
                        const newState = { ...state };
                        delete newState.notes[action.id];
                        return newState;
                    });
                    await dispatch({ type: "refresh" });
                    break;
                }
                case "save_and_share_note": {
                    let note = await createNote(assignmentId, action.title, action.body, new Set());
                    note = await updateNote({
                        id: note.id,
                        access: action.sharees.map(s => ({ user: s })),
                    });
                    await attachFilesToNote(note, action.attachments);
                    await dispatch({ type: "refresh" });
                    break;
                }
                case "cancel_draft": {
                    setState(state => {
                        const newState = { ...state };
                        if (!action.isPrivate) {
                            newState.newSharedNoteDraft = undefined;
                        } else if (action.id) {
                            delete newState.editPrivateNoteDrafts[action.id];
                        } else {
                            newState.newPrivateNoteDraft = undefined;
                        }
                        return newState;
                    });
                    break;
                }
                case "delete_note_attachment":
                    await deleteNoteAttachment(action.attachment);
                    await dispatch({ type: "refresh" });
                    break;
                case "get_previous_notes":
                    const notes = await getAthleteNotes(action.athleteId, action.discipline);
                    setState(state => ({
                        ...state,
                        previousNotes: notes.filter(note => note.assignmentId !== assignmentId),
                    }));
                    await queryClient.invalidateQueries({
                        queryKey: queryKeys.assignments.previousNotes(
                            action.athleteId,
                            action.discipline,
                        ),
                    });
                    break;
            }
        },
        [assignmentId, queryClient],
    );
    return [states, dispatch];
}

export const NotesContext: Context<[NotesState, (action: NotesAction) => void]> = createContext([
    initialState,
    _ => {},
]);

export function focusEditor(id: string | undefined) {
    setTimeout(() => {
        const composers = document.getElementsByClassName("note-composer");
        for (const composer of composers) {
            if (composer.id === `note-composer-${id}`) {
                for (const editor of composer.getElementsByClassName("ProseMirror")) {
                    if (editor.attributes.getNamedItem("contenteditable")?.value) {
                        (editor as HTMLElement).focus();
                        break;
                    }
                }
            }
        }
    }, 100);
}

export function humanReadable(timestamp: Date): string {
    let day: string;
    if (isToday(timestamp)) {
        day = "today";
    } else if (isYesterday(timestamp)) {
        day = "yesterday";
    } else if (isThisYear(timestamp)) {
        day = format(timestamp, "EEE, MMM do");
    } else {
        day = format(timestamp, "M/dd/yyyy");
    }
    return `${day} at ${format(timestamp, "h:mm aa")}`;
}

export function noteIsPrivate(note: Note): boolean {
    return note.access.length === 0;
}
