import * as React from "react";
import { useContext, useRef } from "react";
import { ChannelContext } from "data/channel";
import "./AttachmentButton.css";

export function AttachmentButton() {
    const [, channelDispatch] = useContext(ChannelContext);
    const sendFileMessage = (file: File) => {
        channelDispatch({
            type: "send_file_message",
            file,
        });
    };
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <button
            className="attachment-button"
            type="button"
            onClick={() => inputRef.current?.click()}
        >
            <span className="attachment-button--inner">
                <div className="attachment-button--icon" role="button" tabIndex={0}>
                    <img src={"/paperclip.svg"} alt="attachment" />
                </div>
                <input
                    className="attachment-button--file-input"
                    type="file"
                    ref={inputRef}
                    onChange={e => sendFileMessage(e.target.files![0]!)}
                />
            </span>
        </button>
    );
}
