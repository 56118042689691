import { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserAvatarRow } from "../../shared/AvatarRow";
import { byCreatedAt, byFirstName, fullName } from "../../../util";
import { AdminCoachDetailsBioLink } from "./AdminCoachDetailsBioLink";
import { AdminCoachDetailsHeader } from "./AdminCoachDetailsHeader";
import { AdminCoachAthlete, AdminCoachContext, useAdminCoach } from "data/admin-coach";
import { AuthContext } from "../../../data/auth";
import { AdminMarkAsTestUser } from "../AdminMarkAsTestUser";
import { AdminCoachAvatarForm } from "./AdminCoachAvatar";
import { CoachingMatchPreferencesView } from "components/match/CoachingMatchPreferences";
import { AdminDeleteUser } from "../AdminDeleteUser";
import "./AdminCoachDetails.css";
import styles from "../activation_code/list/AdminActivationCodeList.module.css";
import {
    isSeriesInstance,
    lastConfirmation,
    sessionDuration,
    SessionOrSeriesInstance,
    sessionTypeDescription,
} from "../../../data/session";
import _ from "lodash";
import { format, startOfWeek, subDays, subMonths } from "date-fns";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AdminDisableUser } from "../AdminDisableUser";

export function athleteNameTitle(athlete: AdminCoachAthlete): JSX.Element {
    return (
        <>
            {athlete.firstName} {athlete.lastName}{" "}
            {athlete.invited ? <span className="admin-invited-text">Invited</span> : <></>}
        </>
    );
}

function AdminCoachDetailsAthletes({ athletes }: { athletes: AdminCoachAthlete[] }) {
    return (
        <ul className="no-li-style">
            {athletes.sort(byFirstName).map(athlete =>
                athlete.userId ? (
                    <li key={athlete.athleteDetailsId}>
                        <Link to={`/admin/athletes/${athlete.athleteDetailsId}`}>
                            <UserAvatarRow
                                userId={athlete.userId}
                                title={athleteNameTitle(athlete)}
                                subtitle={athlete.email}
                                showChevron
                            />
                        </Link>
                    </li>
                ) : (
                    <></>
                ),
            )}
        </ul>
    );
}

function isReassigned(athlete: AdminCoachAthlete): boolean {
    const assignment = athlete.assignments.find(a => a.coachId === athlete.coachId);
    if (!assignment) return false;
    return athlete.assignments
        .filter(a => a.coachingType === assignment.coachingType)
        .some(a => a.status === "active");
}

export function AdminCoachDetails() {
    const params = useParams();
    const coachId = params.coachId!;
    const adminCoachContext = useAdminCoach(coachId);
    const [adminCoachState, adminCoachDispatch] = adminCoachContext;
    const [, authDispatch] = useContext(AuthContext);
    useEffect(() => {
        adminCoachDispatch({ type: "refresh" });
    }, [adminCoachDispatch]);
    const navigate = useNavigate();

    function MarkAsTestUserSection(): JSX.Element {
        return adminCoachState.user ? (
            <AdminMarkAsTestUser
                userId={adminCoachState.user.id}
                onComplete={() => adminCoachDispatch({ type: "refresh" })}
                isTestUser={adminCoachState.user.adminTestUser}
            />
        ) : (
            <></>
        );
    }
    function onMalkovitch() {
        authDispatch({ type: "malkovich", userId: adminCoachState.user!.id });
        navigate("/");
    }

    return (
        <AdminCoachContext.Provider value={adminCoachContext}>
            <div
                className="content admin-coach-content-container"
                style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
            >
                <div>
                    <AdminCoachDetailsHeader />
                    <h2 className="section-header">Avatar</h2>
                    <AdminCoachAvatarForm />
                    <button className="pill" onClick={onMalkovitch} style={{ margin: "1rem 0" }}>
                        Malkovich
                    </button>
                </div>
                <Link to={`/admin/coaches/${coachId}/sessions`} className="blue-link">
                    <button>Coach Sessions List</button>
                </Link>
                <div>
                    <h2 className="section-header">Bio Link</h2>
                    <AdminCoachDetailsBioLink />
                </div>
                <div>
                    <h2 className="section-header">Match Preferences</h2>
                    <CoachingMatchPreferencesView
                        preferences={adminCoachState.matchPreferences}
                    ></CoachingMatchPreferencesView>
                </div>
                <div>
                    <h2 className="section-header">Active Athletes</h2>
                    <AdminCoachDetailsAthletes athletes={adminCoachState.activeAthletes} />
                </div>
                <div>
                    <h2 className="section-header">Churned Athletes</h2>
                    <AdminCoachDetailsAthletes
                        athletes={adminCoachState.churnedAthletes.filter(a => !isReassigned(a))}
                    />
                </div>
                <div>
                    <h2 className="section-header">Re-assigned Athletes</h2>
                    <AdminCoachDetailsAthletes
                        athletes={adminCoachState.churnedAthletes.filter(isReassigned)}
                    />
                </div>

                <div className="d-flex flex-col">
                    <MarkAsTestUserSection />
                    {adminCoachState.user && (
                        <>
                            {adminCoachState.user.status === "active" && (
                                <AdminDisableUser user={adminCoachState.user} />
                            )}
                            <AdminDeleteUser user={adminCoachState.user} />
                        </>
                    )}
                </div>
            </div>
        </AdminCoachContext.Provider>
    );
}

function sessionDetails(session: SessionOrSeriesInstance): string {
    return [
        `${sessionDuration(session)}m`,
        isSeriesInstance(session) ? "recurring" : "not recurring",
        session.status === "deleted" ? "canceled" : "",
    ]
        .filter(s => !!s)
        .join(", ");
}

function TableHeader() {
    return (
        <thead>
            <tr>
                <td>
                    <b>Athlete Name</b>
                </td>
                <td>
                    <b>Date</b>
                </td>
                <td>
                    <b>Details</b>
                </td>
                <td>
                    <b>Type</b>
                </td>
                <td>
                    <b>Did it happen?</b>
                </td>
                <td>
                    <b>Why Not?</b>
                </td>
            </tr>
        </thead>
    );
}

function didItHappen(session: SessionOrSeriesInstance): string {
    const confirmation = _.last(session.confirmations?.sort(byCreatedAt));
    if (!confirmation) {
        return "Needs Confirmation";
    }
    return confirmation.occurred ? "Yes" : "No";
}

function whyNot(session: SessionOrSeriesInstance): string {
    const confirmation = _.last(session.confirmations?.sort(byCreatedAt));
    if (!confirmation || confirmation.occurred) return "";
    return `${confirmation.reason}; ${
        confirmation.cancellationPolicyViolation ? "Violation" : "No Penalty"
    }`;
}

function AdminSessionRow({
    session,
    athletes,
    highlight,
}: {
    session: SessionOrSeriesInstance;
    athletes: AdminCoachAthlete[];
    highlight?: boolean;
}) {
    const athlete = athletes.find(athlete =>
        athlete.assignments
            .filter(a => a.status === "active")
            .some(a => a.id === session.assignmentId),
    );
    return (
        <tr key={session.id} className={highlight ? "highlighted-row" : ""}>
            <td>{athlete ? fullName(athlete) : "No Athlete"}</td>
            <td>{format(session.date, "MMMM dd, hh:mm a")}</td>
            <td>{sessionDetails(session)}</td>
            <td>{sessionTypeDescription(session.sessionType)}</td>
            <td>{didItHappen(session)}</td>
            <td>{whyNot(session)}</td>
        </tr>
    );
}

export function AdminCoachSessions() {
    const params = useParams();
    const coachId = params.coachId!;
    const adminCoachContext = useAdminCoach(coachId);
    const [{ user, activeAthletes, churnedAthletes, sessions }, adminCoachDispatch] =
        adminCoachContext;
    const allAthletes = activeAthletes.concat(churnedAthletes);
    useEffect(() => {
        adminCoachDispatch({ type: "refresh" });
    }, [adminCoachDispatch]);
    if (sessions === undefined) {
        return <>Loading...</>;
    }
    const upcoming = _.values(sessions)
        .flat()
        .filter(s => s.date >= new Date())
        .sort((a, b) => a.date.getTime() - b.date.getTime());
    const previous = _.values(sessions)
        .flat()
        .filter(s => s.date < new Date() && s.date > subMonths(new Date(), 2))
        .sort((a, b) => b.date.getTime() - a.date.getTime());
    const payrollWindowEnd = startOfWeek(new Date(), { weekStartsOn: 0 }); // Most recent Sun at midnight (border of Sat & Sun)
    const payrollWindowStart = subDays(payrollWindowEnd, 7);
    const payrollWindowSessions = previous.filter(
        s => s.date >= payrollWindowStart && s.date < payrollWindowEnd,
    );

    const completedInWindow = payrollWindowSessions.filter(
        s => lastConfirmation(s)?.occurred,
    ).length;
    const noShowsInWindow = payrollWindowSessions.filter(
        s => lastConfirmation(s)?.reason === "No Show",
    ).length;
    const additionalNonOccuredInWindow = payrollWindowSessions.filter(
        s => lastConfirmation(s)?.occurred === false && lastConfirmation(s)?.reason !== "No Show",
    ).length;
    const notConfirmedInWindow = payrollWindowSessions.filter(
        s => lastConfirmation(s) === undefined,
    ).length;
    const violationsInWindow = payrollWindowSessions.filter(
        s => lastConfirmation(s)?.cancellationPolicyViolation,
    ).length;

    return (
        <div className={styles.container} style={{ margin: 40 }}>
            <h1>{fullName(user)} Sessions</h1>
            <br />
            <Tabs
                className="tabs"
                defaultIndex={0}
                selectedTabClassName="tab-selected"
                selectedTabPanelClassName="tab-panel-selected"
            >
                <TabList className="no-li-style tab-list">
                    <Tab className="tab">Previous (last 2m)</Tab>
                    <Tab className="tab">Upcoming (next 30d)</Tab>
                </TabList>
                <TabPanel className="tab-panel flex-col">
                    <h3>
                        <b>{completedInWindow}</b> completed between{" "}
                        {format(payrollWindowStart, "EEE MM/dd, hh:mm a")} and{" "}
                        {format(payrollWindowEnd, "EEE MM/dd, hh:mm a")}
                    </h3>
                    <br />
                    <h3>
                        <b>{noShowsInWindow}</b> no shows
                    </h3>
                    <br />

                    <h3>
                        <b>{additionalNonOccuredInWindow}</b> more which didn't happen
                    </h3>
                    <br />

                    <h3>
                        <b>{notConfirmedInWindow}</b> haven't been closed by coach
                    </h3>
                    <br />

                    <h3>
                        <b>{violationsInWindow}</b> claimed violations
                    </h3>
                    <br />

                    <table className={styles.table}>
                        <TableHeader />
                        <tbody>
                            {previous.map(session => (
                                <AdminSessionRow
                                    session={session}
                                    athletes={allAthletes}
                                    highlight={
                                        session.date >= payrollWindowStart &&
                                        session.date < payrollWindowEnd
                                    }
                                    key={session.id}
                                />
                            ))}
                        </tbody>
                    </table>
                </TabPanel>
                <TabPanel className="tab-panel flex-col">
                    <table className={styles.table}>
                        <TableHeader />

                        <tbody>
                            {upcoming.map(session => (
                                <AdminSessionRow
                                    session={session}
                                    athletes={allAthletes}
                                    key={session.id}
                                />
                            ))}
                        </tbody>
                    </table>
                </TabPanel>
            </Tabs>
        </div>
    );
}
