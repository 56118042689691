import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { AvatarRow, UserAvatarRow } from "../../shared/AvatarRow";
import { UserAvatar } from "../../shared/Avatar";
import { User } from "../../../data/user";
import { mixpanelProfileUrl } from "../../../vendor/mixpanel";
import "./AdminParentDetails.css";
import "../one_on_one_tab/AdminParentList.css";
import { RelationshipInvitation } from "data/invitation";
import { AdminMarkAsTestUser } from "../AdminMarkAsTestUser";
import { AdminNotesList } from "../AdminNotesList";
import { AdminParentFeedback } from "./AdminParentFeedback";
import { AdminOnboardingHistory } from "../AdminOnboardingHistory";
import { AdminDeleteUser } from "../AdminDeleteUser";
import { ActivationCodeList } from "../activation_code/list/AdminActivationCodeList";
import { useAdminParent } from "data/admin-parent";

function AdminParentDetailsHeader({ user }: { user: User }) {
    return (
        <div
            className="row admin-parent-content-container"
            style={{ gap: "20px", alignItems: "start" }}
        >
            <UserAvatar userId={user.id} size="large" />
            <div>
                <h1>
                    {user.firstName} {user.lastName}
                </h1>
                <p>{user.email}</p>
                <p>{user.id}</p>
                <a href={mixpanelProfileUrl(user.id)}>Mixpanel Profile</a>
            </div>
        </div>
    );
}

export function AdminParentDetails() {
    const params = useParams();
    const parentId = params.parentId!;
    const [parentState, parentDispatch] = useAdminParent(parentId);

    useEffect(() => {
        parentDispatch({ type: "refresh" });
    }, [parentDispatch]);

    function invitedName(invitation: RelationshipInvitation): JSX.Element {
        return (
            <span>
                {invitation.firstName} {invitation.lastName} (
                <span className="admin-parent-invited-text">Invited</span>)
            </span>
        );
    }

    function MarkAsTestUserSection(): JSX.Element {
        return parentState ? (
            <AdminMarkAsTestUser
                userId={parentId}
                onComplete={() => parentDispatch({ type: "refresh" })}
                isTestUser={parentState.adminTestUser}
            />
        ) : (
            <></>
        );
    }

    return (
        <div
            className="content d-flex flex-col admin-parent-view-container"
            style={{ gap: "20px" }}
        >
            {parentState && <AdminParentDetailsHeader user={parentState} />}
            {parentState && <AdminOnboardingHistory userId={parentState.id} />}
            <div className="admin-parent-content-container">
                <h2 className="section-header">Children</h2>
                <ul className="no-li-style">
                    {parentState?.children
                        .map(c => c.child)
                        .map(child => (
                            <li key={child.id}>
                                <Link to={`/admin/athletes/${child.athleteDetails?.id}`}>
                                    <UserAvatarRow
                                        userId={child.id}
                                        title={`${child.firstName} ${child.lastName}`}
                                        subtitle={child.email}
                                        showChevron
                                    />
                                </Link>
                            </li>
                        ))}
                    {parentState?.createdInvitations
                        ?.filter(invitation => !invitation.accepterId)
                        .map((invitation: RelationshipInvitation) => (
                            <li key={invitation.code}>
                                <Link to={`/admin/athletes/${invitation.athleteId}`}>
                                    <AvatarRow
                                        title={invitedName(invitation)}
                                        subtitle={invitation?.inviteeEmail ?? "(no email address)"}
                                        subtitle2={
                                            "Created " + invitation.createdAt.toLocaleString()
                                        }
                                        showChevron
                                    />
                                </Link>
                            </li>
                        ))}
                </ul>
            </div>
            <AdminNotesList notes={parentState?.noteAccess.map(access => access.note) ?? []} />
            <AdminParentFeedback feedback={parentState?.feedback ?? []} />
            <div>
                <h4>Activation Codes Used</h4>
                <ActivationCodeList
                    activationCodes={
                        parentState?.activationCodeUsages?.map(usage => usage.activationCode!) ?? []
                    }
                />
            </div>
            <MarkAsTestUserSection />
            {parentState && <AdminDeleteUser user={parentState} />}
        </div>
    );
}
