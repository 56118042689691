import { GroupChannel } from "@sendbird/chat/groupChannel";
import { BannerContext } from "components/Banner";
import { Athlete, useCoachData, useUser } from "data/coach";
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AthleteDetails } from "../../data/user";
import { byFullName, formatEnumValues, fullName } from "../../util";

import { SendbirdContext, getAssignmentIds } from "../../vendor/sendbird";
import { MessageBroadcastModal } from "../messaging/MessageBroadcastModal";
import { CoachSessions } from "../sessions/CoachSessions";
import { UserAvatarRow } from "../shared/AvatarRow";
import { ConfirmationContext } from "../shared/Confirmation";
import { Spinner } from "../shared/Spinner";
import "./CoachHome.css";
import { trackPageView } from "vendor/mixpanel";
import { isWithinInterval, subMinutes } from "date-fns";

function athleteSubtitle(details: AthleteDetails | undefined): string {
    let sports: string;
    if (details?.sports.length) {
        sports = formatEnumValues(details.sports);
    } else {
        sports = "Sport: N/A";
    }
    let grad: string;
    if (details?.graduationYear) {
        grad = `Class of ${details.graduationYear}`;
    } else {
        grad = "Graduation class: N/A";
    }
    return `${sports} | ${grad}`;
}

function AthleteRow({ unreadCount, athlete }: { athlete: Athlete; unreadCount: number }) {
    const upcomingSessionCount = athlete.sessions.length;
    const subtitle2 = upcomingSessionCount
        ? `${upcomingSessionCount} sessions scheduled (next two weeks)`
        : "No sessions scheduled (next two weeks)";
    return (
        <li>
            {athlete && athlete.user && (
                <NavLink to={`/assignments/${athlete.assignmentId}`}>
                    <UserAvatarRow
                        userId={athlete.user.id}
                        title={fullName(athlete.user)}
                        subtitle={athleteSubtitle(athlete.details)}
                        subtitle2={subtitle2}
                        showChevron
                        badge={unreadCount}
                    />
                </NavLink>
            )}
        </li>
    );
}

function unreadCountsByAssignmentId(channels: GroupChannel[]): { [assignmentId: string]: number } {
    const ret: { [assignmentId: string]: number } = {};
    for (const channel of channels) {
        for (const assignmentId of getAssignmentIds(channel)) {
            ret[assignmentId] = (ret[assignmentId] ?? 0) + channel.unreadMessageCount;
        }
    }
    return ret;
}

export function CoachHome({ id }: { id: string }) {
    const [sendbirdState] = useContext(SendbirdContext);
    const unreadCounts = unreadCountsByAssignmentId(sendbirdState.channels);
    const [, setBanner] = useContext(BannerContext);
    const broadcastModalContext = useState(false);
    const [, setShowBroadcastModal] = broadcastModalContext;

    const { data: coachUser } = useUser(id);
    const coachState = useCoachData(id);
    const sessionData = coachState.sessions ?? [];

    useEffect(() => {
        const assignments = coachState.assignments ?? [];
        const sessions = sessionData?.sessions ?? [];
        function callback() {
            const session = Object.values(sessions)
                .flat()
                .filter(s => s.status === "active")
                .find(session =>
                    isWithinInterval(new Date(), {
                        start: subMinutes(session.date, 5),
                        end: session.date,
                    }),
                );
            if (!session) return;
            const athleteId = assignments.find(a => a.id === session.assignmentId)?.athleteId;
            if (!athleteId) return;
            setBanner({
                type: "show_upcoming_session",
                link: session.link,
                athleteId: athleteId,
            });
            const delay = session.date.getTime() - Date.now();
            setTimeout(() => setBanner({ type: "dismiss" }), delay);
        }
        const intervalMs = 60 * 1000;
        const id = setInterval(callback, intervalMs);
        callback(); // Execute immediately, and then once every intervalMs
        return () => clearInterval(id);
    }, [sessionData?.sessions, setBanner, coachState.assignments]);

    useEffect(() => {
        trackPageView("coach_home");
    }, []);

    if (coachState.isLoading) return <Spinner />;
    return (
        <div className="content coach-home">
            {coachUser && (
                <>
                    <h1>Welcome, {coachUser.firstName}</h1>
                    <div className="d-flex">
                        <div className="coach-home-column">
                            <div className="coach-clients">
                                <div className="flex-row">
                                    <h2 className="float-left section-header">Clients</h2>
                                    <button
                                        className="broadcast-button d-flex flex-col align-items-end float-right"
                                        onClick={() => setShowBroadcastModal(true)}
                                    >
                                        Broadcast Message
                                    </button>
                                </div>
                                <ul className="no-li-style">
                                    {coachState.athletes
                                        .sort((a, b) => byFullName(a.user, b.user))
                                        .map(athlete =>
                                            athlete.user ? (
                                                <AthleteRow
                                                    key={athlete.assignmentId}
                                                    athlete={athlete}
                                                    unreadCount={
                                                        unreadCounts[athlete.assignmentId] ?? 0
                                                    }
                                                />
                                            ) : (
                                                <></>
                                            ),
                                        )}
                                </ul>
                            </div>
                        </div>
                        <CoachSessions assignmentId={undefined} coachUserId={id} />
                        <ConfirmationContext.Provider value={broadcastModalContext}>
                            <MessageBroadcastModal coachUserId={id} />
                        </ConfirmationContext.Provider>
                    </div>
                </>
            )}
        </div>
    );
}
