import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import "./FeedbackForm.css";
import { FeedbackCreateInput, FeedbackFormContext, useFeedbackFormState } from "../data/feedback";
import { trackAction, trackPageView } from "../vendor/mixpanel";

export function FeedbackWrapper() {
    const feedbackContext = useFeedbackFormState();
    return (
        <FeedbackFormContext.Provider value={feedbackContext}>
            <div className="toolbar">
                <div className="logo">
                    <img alt="IMGA+ Logo" src="/logo.svg" />
                </div>
            </div>
            <FeedbackFlow />
        </FeedbackFormContext.Provider>
    );
}

function FeedbackFlow() {
    const [state] = useContext(FeedbackFormContext);
    if (state.submitted) {
        return <FeedbackSubmitted />;
    } else {
        return <FeedbackForm />;
    }
}

function FeedbackSubmitted() {
    const params = useParams();
    const inquiryId = params.inquiryId!;
    useEffect(() => {
        trackPageView("feedback_submitted", { inquiryId });
    }, [inquiryId]);
    return (
        <div className="content feedback-submitted">
            <h2>Thank you for your feedback! </h2>
            <br />
            <br />
            <img src="/checkmark-circle.svg" alt="complete" />
            <br />
            <br />
            <br />
            <div className="primary">
                We appreciate your feedback! It helps us deliver the best IMG+ experience.
            </div>
        </div>
    );
}

function FeedbackForm() {
    const [, feedbackDispatch] = useContext(FeedbackFormContext);
    const [nps, setNps] = useState<number | undefined>(undefined);
    const [npsLongformAnswer, setNpsLongformAnswer] = useState("");
    const [experience, setExperience] = useState<Experience | undefined>(undefined);
    const [experienceLongformAnswer, setExperienceLongformAnswer] = useState("");

    const params = useParams();
    const inquiryId = params.inquiryId!;
    useEffect(() => {
        trackPageView("feedback_form", { inquiryId });
    }, [inquiryId]);
    const experienceRating = () => {
        if (experience === undefined) return undefined;
        return experienceOptions.indexOf(experience) + 1;
    };
    const feedback = () => {
        let npsFeedback: FeedbackCreateInput | undefined = undefined;
        if (nps !== undefined || npsLongformAnswer.trim().length > 0) {
            npsFeedback = {
                type: "nps",
                rating: nps,
                maxRating: 10,
                description: npsLongformAnswer.trim(),
            };
        }
        let experienceFeedback: FeedbackCreateInput | undefined = undefined;
        if (experience !== undefined || experienceLongformAnswer.trim().length > 0) {
            experienceFeedback = {
                type: "experience",
                rating: experienceRating(),
                maxRating: 5,
                description: experienceLongformAnswer.trim(),
            };
        }
        return [npsFeedback, experienceFeedback].filter(
            (f): f is FeedbackCreateInput => f !== undefined,
        );
    };
    const submit = () => {
        feedbackDispatch({
            type: "submit",
            inquiryId,
            feedback: feedback(),
        });
    };
    return (
        <form className="feedback-form">
            <div className="primary">
                How likely are you to recommend IMG+ to a friend or family member?
            </div>
            <br />
            <br />
            <br />
            <NPSFullWidthButtons nps={nps} setNps={setNps} />
            <NPSSmallScreenButtons nps={nps} setNps={setNps} />
            <br />
            <br />
            <div className="primary">{tellUsMoreText(nps)}</div>
            <textarea
                value={npsLongformAnswer}
                onChange={event => setNpsLongformAnswer(event.target.value)}
                placeholder="Tell us a bit more..."
            />
            <hr />
            <div className="primary">
                Overall, how satisfied are you with your IMG+ experience so far?
            </div>
            <ExperienceSelectionRow experience={experience} setExperience={setExperience} />
            <br />
            <br />
            <div className="primary">{tellUsMoreText(experience?.text)}</div>
            <textarea
                value={experienceLongformAnswer}
                onChange={event => setExperienceLongformAnswer(event.target.value)}
                placeholder="Tell us a bit more..."
            />
            <br />
            <br />
            <button
                onClick={submit}
                type="button"
                className="pill"
                disabled={feedback().length === 0}
            >
                Submit
            </button>
        </form>
    );
}

function NPSSmallScreenButtons({
    nps,
    setNps,
}: {
    nps: number | undefined;
    setNps: (n: number) => void;
}) {
    return (
        <div className="nps-section">
            <div className="nps-row small-screen-only">
                {[...Array(6).keys()].map(n => (
                    <button
                        onClick={() => {
                            trackAction("user_tap_feedback_rating", {
                                feedback_rating: n,
                                type: "nps",
                            });
                            setNps(n);
                        }}
                        className={nps === n ? "selected" : ""}
                        type="button"
                        key={n}
                    >
                        {n}
                    </button>
                ))}
            </div>
            <div className="nps-row small-screen-only" style={{ width: "80%" }}>
                {[...Array(5).keys()]
                    .map(n => n + 6)
                    .map(n => (
                        <button
                            onClick={() => {
                                trackAction("user_tap_feedback_rating", {
                                    feedback_rating: n,
                                    type: "nps",
                                });
                                setNps(n);
                            }}
                            className={nps === n ? "selected" : ""}
                            type="button"
                            key={n}
                        >
                            {n}
                        </button>
                    ))}
            </div>
            <div className="nps-row small-screen-only">
                <div className="secondary">0 - Not likely</div>
                <div className="secondary">10 - Very likely</div>
            </div>
        </div>
    );
}

function NPSFullWidthButtons({
    nps,
    setNps,
}: {
    nps: number | undefined;
    setNps: (n: number) => void;
}) {
    return (
        <>
            <div className="nps-row large-screen-only">
                {[...Array(11).keys()].map(n => (
                    <button
                        onClick={() => {
                            trackAction("user_tap_feedback_rating", {
                                feedback_rating: n,
                                type: "nps",
                            });
                            setNps(n);
                        }}
                        className={nps === n ? "selected" : ""}
                        type="button"
                        key={n}
                    >
                        {n}
                    </button>
                ))}
            </div>
            <div className="nps-row large-screen-only">
                <div className="secondary">Not likely</div>
                <div className="secondary">Very likely</div>
            </div>
        </>
    );
}

const experienceOptions = [
    {
        text: "Extremely dissatisfied",
        src: "/experience-1.svg",
    },
    {
        text: "Somewhat dissatisfied",
        src: "/experience-2.svg",
    },
    {
        text: "Neither dissatisfied nor satisfied",
        src: "/experience-3.svg",
    },
    {
        text: "Somewhat satisfied",
        src: "/experience-4.svg",
    },
    {
        text: "Extremely satisfied",
        src: "/experience-5.svg",
    },
];
type Experience = typeof experienceOptions[number];

function ExperienceSelectionRow({
    experience,
    setExperience,
}: {
    experience: Experience | undefined;
    setExperience: (e: Experience) => void;
}) {
    return (
        <div className="experience-row">
            {experienceOptions.map(e => (
                <button
                    onClick={() => {
                        trackAction("user_tap_feedback_rating", {
                            feedback_rating: experienceOptions.indexOf(e) + 1,
                            type: "experience",
                        });
                        setExperience(e);
                    }}
                    className={experience === e ? "selected" : ""}
                    type="button"
                    key={e.text}
                >
                    <img src={e.src} alt={e.text} />
                    {e.text}
                </button>
            ))}
        </div>
    );
}

function tellUsMorePrefix(selection: number | string): string {
    switch (selection) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case experienceOptions[0]?.text:
        case experienceOptions[1]?.text:
        case experienceOptions[2]?.text:
            return "We're sorry to hear that. ";
        case 9:
        case 10:
        case experienceOptions[4]?.text:
            return "Glad to hear it! ";
    }
    return "";
}

function tellUsMoreText(selection: number | string | undefined): string {
    if (selection === undefined) return `Tell us a bit more about why you chose that answer.`;
    return `${tellUsMorePrefix(selection)} Tell us a bit more about why you chose "${selection}".`;
}
