enum Prefix {
    ASSIGNMENTS = "assignments",
    USERS = "users",
    COACHES = "coaches",
    ATHLETES = "athletes",
    SESSIONS = "sessions",
    SERIES = "series",
    NOTES = "notes",
    MEDIA = "media",
}

export const queryKeys = {
    assignments: {
        coach: (coachId: string) => [Prefix.ASSIGNMENTS, coachId],
        series: (assignmentId: string) => [Prefix.ASSIGNMENTS, assignmentId, Prefix.SERIES],
        sessions: (assignmentId: string, ...args: any) => [
            Prefix.ASSIGNMENTS,
            assignmentId,
            Prefix.SESSIONS,
            ...args,
        ],
        notes: (assignmentId: string) => [Prefix.ASSIGNMENTS, assignmentId, Prefix.NOTES],
        previousNotes: (athleteId: string, discipline: string) => [
            Prefix.NOTES,
            "previous",
            athleteId,
            discipline,
        ],
    },
    media: {
        access: (mediaId: string) => [Prefix.MEDIA, mediaId],
    },
    users: {
        detail: (userId: string) => [Prefix.USERS, userId],
        me: [Prefix.USERS, "me"],
        activationCodeUsages: (userId: string) => [Prefix.USERS, userId, "activation-code-usages"],
        parents: (userId: string) => [Prefix.USERS, userId, "parents"],
        calendarAvailabilitySettings: (userId: string) => [
            Prefix.USERS,
            userId,
            "calendar-availability-settings",
        ],
        avatar: (userId: string) => [Prefix.USERS, userId, "avatar"],
    },
    athletes: {
        detail: (athleteId: string) => [Prefix.ATHLETES, athleteId],
        invitations: (athleteId: string) => [Prefix.ATHLETES, athleteId, "invitations"],
    },
    coach: {
        athletes: (coachId: string) => [Prefix.COACHES, coachId, Prefix.ATHLETES],
    },
    sessions: {
        upcoming: (coachId: string) => [Prefix.SESSIONS, coachId, "upcoming"],
        assignment: (assignmentId: string) => [Prefix.SESSIONS, assignmentId],
    },
    series: {
        expanded: (seriesId: string, start: Date, end: Date) => [
            Prefix.SERIES,
            seriesId,
            "expanded",
            start.toLocaleDateString(),
            end.toLocaleDateString(),
        ],
    },
};
