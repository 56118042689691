import { useContext, useEffect } from "react";
import { AdminContext } from "../../../data/admin";
import { Link } from "react-router-dom";
import { UserAvatarRow } from "../../shared/AvatarRow";
import { byFirstName } from "../../../util";
import "./AdminCoachList.css";
import { User } from "data/user";

export function AdminCoachList() {
    const [adminState, adminDispatch] = useContext(AdminContext);
    useEffect(() => {
        adminDispatch({ type: "refresh", objects: "coaches" });
    }, [adminDispatch]);

    const activeCoaches = adminState.coaches.filter(c => c.status === "active");
    const churnedCoaches = adminState.coaches.filter(c => c.status === "suspended");

    const coachList = (coaches: User[]) => (
        <ul className="no-li-style">
            {coaches.sort(byFirstName).map(coach => (
                <li key={coach.id}>
                    <Link to={`/admin/coaches/${coach.coachDetails?.id}`}>
                        <UserAvatarRow
                            userId={coach.id}
                            title={`${coach.firstName} ${coach.lastName}`}
                            subtitle={coach.email}
                            showChevron
                            hideAvatar
                        />
                    </Link>
                </li>
            ))}
        </ul>
    );

    return (
        <div className="admin-list-container">
            <h2 className="section-header float-left">Coaches ({activeCoaches.length})</h2>
            <div className="d-flex flex-col align-items-end float-right">
                <Link to={`/admin/create-coach`} className="admin-add-coach-button">
                    + Add New Coach
                </Link>
            </div>
            {coachList(activeCoaches)}
            {churnedCoaches.length > 0 && (
                <>
                    <h2 className="section-header float-left" style={{ marginTop: "2rem" }}>
                        Churned Coaches ({churnedCoaches.length})
                    </h2>
                    {coachList(churnedCoaches)}
                </>
            )}
        </div>
    );
}
