import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAthlete } from "data/athlete";
import { User } from "data/user";
import { UserAvatar } from "../shared/Avatar";
import { trackPageView } from "../../vendor/mixpanel";

function NoUpcomingSessions({ athlete }: { athlete: User }) {
    return (
        <div className={"card"}>
            <UserAvatar userId={athlete.id} size={"large"} />
            <h2 style={{ padding: "10px 0" }}>
                {athlete.firstName} {athlete.lastName}
            </h2>
            <p className={"secondary"}>
                You currently have no sessions booked with {athlete.firstName}. Add one here.
            </p>
        </div>
    );
}

export function AthleteDetails() {
    const params = useParams();
    const athleteId = params.athleteId!;
    const [athleteState, athleteDispatch] = useAthlete(athleteId);
    useEffect(() => {
        athleteDispatch({ type: "refresh" });
    }, [athleteDispatch]);
    useEffect(() => {
        trackPageView("athlete_details");
    }, []);
    const athlete = athleteState?.user?.athleteDetails;
    return (
        <div className={"content"}>
            {athleteState.user ? <NoUpcomingSessions athlete={athleteState.user} /> : <></>}
            <h2 className="section-header">Athlete Details</h2>
            <div className="card" style={{ textAlign: "left" }}>
                <h5>Sports</h5>
                <h6>{athlete?.sports.join(", ")}</h6>
                <h5>Hometown</h5>
                <h6>
                    {athlete?.hometown}, {athlete?.state}
                </h6>
                <h5>Grad Year</h5>
                <h6>{athlete?.graduationYear}</h6>
                <h5>Goals</h5>
                <h6>{athlete?.goals.join(", ")}</h6>
            </div>
        </div>
    );
}
